const cinematicIcon = ({
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        width={width ?? "36"}
        height={height ?? "36"}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.46155 5.25L9.08651 10.5H13.5865L10.9615 5.25H13.9615L16.5865 10.5H21.0865L18.4615 5.25H21.4616L24.0865 10.5H28.5865L25.9616 5.25H29.5384C30.2961 5.25 30.9374 5.5125 31.4624 6.0375C31.9874 6.5625 32.2499 7.20385 32.2499 7.96155V28.0384C32.2499 28.7961 31.9874 29.4374 31.4624 29.9624C30.9374 30.4874 30.2961 30.7499 29.5384 30.7499H6.46155C5.70385 30.7499 5.0625 30.4874 4.5375 29.9624C4.0125 29.4374 3.75 28.7961 3.75 28.0384V7.96155C3.75 7.20385 4.0125 6.5625 4.5375 6.0375C5.0625 5.5125 5.70385 5.25 6.46155 5.25ZM5.99996 12.7499V28.0384C5.99996 28.173 6.04324 28.2836 6.12979 28.3701C6.21634 28.4567 6.32693 28.5 6.46155 28.5H29.5384C29.673 28.5 29.7836 28.4567 29.8701 28.3701C29.9567 28.2836 30 28.173 30 28.0384V12.7499H5.99996ZM12.5192 26.3364H23.4807V25.7999C23.4807 24.8538 22.9716 24.0817 21.9533 23.4836C20.935 22.8856 19.6173 22.5865 18 22.5865C16.3827 22.5865 15.0649 22.8856 14.0466 23.4836C13.0284 24.0817 12.5192 24.8538 12.5192 25.7999V26.3364ZM18 20.6249C18.7288 20.6249 19.3485 20.3696 19.8591 19.8591C20.3696 19.3485 20.6249 18.7288 20.6249 18C20.6249 17.2711 20.3696 16.6514 19.8591 16.1409C19.3485 15.6303 18.7288 15.375 18 15.375C17.2711 15.375 16.6514 15.6303 16.1409 16.1409C15.6303 16.6514 15.375 17.2711 15.375 18C15.375 18.7288 15.6303 19.3485 16.1409 19.8591C16.6514 20.3696 17.2711 20.6249 18 20.6249Z"
          fill="white"
          fill-opacity="0.8"
        />
      </svg>
    </div>
  );
};

export default cinematicIcon;
