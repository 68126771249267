import { Resource } from "../../../types/stateTypes";
import { RootState } from "../../../app/store";
import { ResourceTabList } from "../../../types/propTypes";
import { InitialResourcesState } from "../../../assets/data/InitialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ResourcesSliceType = {
  resources: Resource[];
  selectedType?: ResourceTabList;
  count?: number;
};

export const resourcesSlice = createSlice({
  name: "resources",
  initialState: InitialResourcesState,
  reducers: {
    clearResources: (state) => {
      state.resources = [];
      state.count = 0;
    },
    setInitialResources: (state, action: PayloadAction<ResourcesSliceType>) => {
      state.resources = action.payload.resources;
      state.count = action.payload.count;
    },
    setResources: (state, action: PayloadAction<ResourcesSliceType>) => {
      const { resources: storedResources } = state;
      const { resources: newResources } = action.payload;
      const resourceIds = storedResources.map((resource) => resource.id);
      const filteredDuplicates = newResources.filter(
        (resource) => !resourceIds.includes(resource.id)
      );
      filteredDuplicates.map((resource) => state.resources.push(resource));
      state.count = action.payload.count;
    },
    addResource: (state, action: PayloadAction<Resource>) => {
      state.resources.unshift(action.payload);
    },
    updateResourceInfo: (state, action: PayloadAction<Resource>) => {
      const filteredResources = state.resources.filter(
        (resource) => resource.id !== action.payload.id
      );
      state.resources = [action.payload, ...filteredResources];
    },
    deleteResourceWithId: (state, action: PayloadAction<string>) => {
      const filteredResources = state.resources.filter(
        (resource) => resource.id !== action.payload
      );
      state.resources = filteredResources;
    },
    selectResourceType: (state, action: PayloadAction<ResourceTabList>) => {
      state.selectedType = action.payload;
    },
  },
});

export const resourcesSelector = (state: RootState) =>
  state.resources.resources;
export const resourceTypeSelector = (state: RootState) =>
  state.resources.selectedType;
export const resourcesCountSelector = (state: RootState) =>
  state.resources.count;
export const {
  clearResources,
  setInitialResources,
  setResources,
  addResource,
  updateResourceInfo,
  deleteResourceWithId,
  selectResourceType,
} = resourcesSlice.actions;
export default resourcesSlice.reducer;
