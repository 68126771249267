import { RFC } from '../../../types/propTypes'
import { Resource } from '../../../types/stateTypes'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { resourceIdSelector, storeResource } from '../../../features/resources/Resource/resourceSlice'
import { TitleCard } from '../atoms'

type LinkCardProps = {
  resource: Resource
}

const LinkCard:RFC<LinkCardProps> = ({ resource }) => {
  const { id, title, link, isPinned } = resource
  const resourceId = useMatesSelector(resourceIdSelector)
  const dispatch = useMatesDispatch()

  return (
    <div className='relative w-full h-full'>
      <TitleCard
        isSelected={resourceId === id}
        isAnimationOn={true}
        onClick={() => dispatch(storeResource(resource))}
      >
        <div className='flex items-center justify-between'>
          <div className='flex flex-col justify-center'>
            <p className='uppercase text-2xl line-clamp-2 font-condensed font-medium'>
              {title}
            </p>
            <p className='text-MatesWhite/80 font-condensed text-lg'>
              {link?.url}
            </p>
          </div>
          {isPinned && (
            <div 
              className={`absolute right-0 top-2 bottom-2 w-[8px] rounded-r-[3px]
              ${resourceId === id ? 'bg-MatesWhite' : 'bg-MatesRed'}`}
            ></div>
          )}
        </div>
      </TitleCard>
    </div>
  )
}

export default LinkCard