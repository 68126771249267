import React from "react";
import { Challenge } from "../../../types/stateTypes";
import moment from "moment";

type Props = { challenge: Challenge; className?: string };

const PastChallengeCard = ({ challenge, className = "" }: Props) => {
  return (
    <div
      className={`card flex flex-col gap-2 p-3 bg-white bg-opacity-10 text-MatesWhite 
      rounded-[5px] ${className}`}
    >
      <h3 className="text-[32px] font-bold uppercase leading-8">{challenge.title}</h3>
      <h4 className="text-[22px] font-bold uppercase leading-6 text-MatesRed">{challenge.hashtag}</h4>
      <p className="text-lg font-normal font-condensed leading-6">
        <span className="opacity-80">Submissions closed: </span>
        <span className="font-bold">{moment(challenge.submissionCloseDate).format("D MMMM YYYY")}</span>
      </p>
      <p className="text-lg font-normal font-condensed leading-6">
        <span className="opacity-80">Voting closed: </span>
        <span className="font-bold">{moment(challenge.winnerAnnouncementDate).format("D MMMM YYYY")}</span>
      </p>
    </div>
  );
};

export default PastChallengeCard;
