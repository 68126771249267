import { Dialog } from '@headlessui/react'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { closeModal, modalSelector } from '../../../features/cores/modalSlice'
import { MatesButton } from '../../atoms/atoms'

const DeleteModal = () => {
  const { itemId, deleteFn } = useMatesSelector(modalSelector)
  const dispatch = useMatesDispatch()

  return (
    <div className='w-[400px] py-3 px-6'>
      <Dialog.Title className='flex items-center justify-between'>
        <span className='font-Title font-extrabold text-3xl'>
          CONFIRM DELETION
        </span>
      </Dialog.Title>
      <hr className='mates-border my-2' />
      <div>
        <p className='text-MatesBlack/80 text-xl'>
          Are you sure you want to delete this item from the Mates database? This action is unrecoverable.
        </p>
        <div className='w-full flex items-center mt-3'>
          <MatesButton
            text='Confirm Deletion'
            className='main-detail-button'
            onSubmit={() => deleteFn && deleteFn(itemId ?? '')}
          />
          <MatesButton
            text='Go Back'
            className='delete-goback-button'
            onSubmit={() => dispatch(closeModal())}
          />
        </div>
      </div>
    </div>
  )
}

export default DeleteModal