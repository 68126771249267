const inventoryIcon = ({
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "22.729"}
        height={height ?? "22.729"}
        viewBox="0 0 22.729 22.729"
      >
        <path
          id="inventory_FILL0_wght400_GRAD0_opsz48_2_"
          data-name="inventory_FILL0_wght400_GRAD0_opsz48 (2)"
          d="M15.039,24.929h-7.3A1.788,1.788,0,0,1,6,23.186V6.269a1.813,1.813,0,0,1,.451-1.221q.451-.523.77-.523h5.871a2.915,2.915,0,0,1,4.694-1.671,2.809,2.809,0,0,1,1,1.671H24.66a1.673,1.673,0,0,1,1.221.523A1.673,1.673,0,0,1,26.4,6.269v5.9H24.66v-5.9H21.579v3.779H10.825V6.269H7.744V23.186h7.3Zm5.493-.727-4.651-4.651,1.25-1.25,3.4,3.4,6.947-6.947,1.25,1.25ZM16.464,6.153A1.142,1.142,0,0,0,17.626,4.99a1.163,1.163,0,1,0-2.325,0,1.142,1.142,0,0,0,1.163,1.163Z"
          transform="translate(-6 -2.2)"
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default inventoryIcon;
