import { useCallback, useEffect, useMemo, useState } from "react";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { CloseIcon } from "../../../assets/icons/icons";
import { closeModal, modalSelector } from "../../../features/cores/modalSlice";
import { CardError, CardLoading, CardLogo, TitleCard } from "../../atoms/atoms";
import { useLazyGetResourceCommentsQuery } from "../../../services/SurveyService";
import { resourceCommentCountSelector } from "../../../features/surveys/surveySlice";
import {
  cardDateFormatter,
  formatFullNameToUpperCase,
} from "../../../utils/formatter";
import InfiniteScroll from "react-infinite-scroller";

function CommentsRatingResults() {
  const take = 5;
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { userCount, guestCount } = useMatesSelector(
    resourceCommentCountSelector
  );
  const { itemId } = useMatesSelector(modalSelector);
  const dispatch = useMatesDispatch();

  const [getResourceComments, { data: commentsData, isLoading, isError }] =
    useLazyGetResourceCommentsQuery();

  const totalCommentCount = useMemo(() => {
    if (typeof userCount !== "number" || typeof guestCount !== "number")
      return 0;
    const totalCount = userCount + guestCount;
    return totalCount ?? 0;
  }, [userCount, guestCount]);

  const handleSetPage = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  useEffect(() => {
    handleSetPage(1);
    loadMoreItems(take, 1);
  }, [itemId]);

  const loadMoreItems = async (take: number, page: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const { count, data } = await getResourceComments({
        take,
        page,
        resourceId: itemId ?? "",
      }).unwrap();

      if (!data || !data.length || data.length >= count) {
        setHasMore(false);
        return;
      } else {
        setHasMore(true);
        setPage(++page);
        return;
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    }
  };

  if (isLoading) return <CardLogo />;
  if (isError) return <CardError />;
  if (!commentsData?.count)
    return (
      <div className="empty-list tracking-wide text-3xl py-10">
        There is no comment
      </div>
    );

  return (
    <div className="w-[600px] px-6 py-3 h-[700px] bg-MatesLightBlack">
      <>
        <div className="flex items-center justify-between py-1">
          <div className="flex items-center">
            <p className="text-5xl text-MatesWhite font-semibold tracking-wide font-compressed">
              COMMENTS
            </p>
            <div className="flex items-center mt-6 gap-x-3 ml-4 pb-3">
              <p className="text-MatesWhite font-condensed font-medium text-base tracking-wider">{`Users ${
                userCount ?? 0
              }`}</p>
              <p className="text-MatesWhite font-condensed font-medium text-base tracking-wider">{`Guests ${
                guestCount ?? 0
              }`}</p>
              <p className="text-MatesWhite font-condensed font-medium text-base tracking-wider">{`Total ${totalCommentCount}`}</p>
            </div>
          </div>
          <CloseIcon
            className="mt-2 cursor-pointer"
            onClick={() => dispatch(closeModal())}
          />
        </div>
        <div className="border-b divide-MatesBorder" />

        <div className="h-[calc(100vh-23rem)] overflow-y-auto">
          <InfiniteScroll
            loadMore={() => (!isLoadMore ? loadMoreItems(take, page) : {})}
            hasMore={hasMore}
            useWindow={false}
            loader={<CardLoading shape="rounded" key={0} />}
            threshold={150}
          >
            {commentsData.data?.map((comment, index) => (
              <TitleCard
                isAnimationOn={false}
                isSelected={false}
                onClick={() => null}
                className="bg-MatesLightBlack hover:bg-MatesLightBlack cursor-default"
              >
                <>
                  <div className="items-center">
                    <div className="flex flex-row items-center">
                      <p className="text-MatesWhite font-condensed font-medium text-base">
                        {comment.user ? (
                          <>
                            {formatFullNameToUpperCase(
                              comment.user?.firstName,
                              comment.user?.lastName
                            )}
                            <span className="mx-2">●</span>
                          </>
                        ) : (
                          "Guest"
                        )}
                      </p>
                      <p className="text-MatesWhite/80 font-condensed text-base">
                        {comment.user?.role || ""}
                      </p>
                      <span className="mx-2">●</span>
                      <p className="text-MatesWhite/80 font-condensed text-base">
                        {cardDateFormatter(comment.createdAt)}
                      </p>
                    </div>

                    <div className="grid grid-rows-2 grid-flow-col">
                      <p className="text-MatesWhite font-condensed font-medium text-base leading-5 tracking-wide row-span-2 col-span-2 text-left">
                        {comment.comment}
                      </p>
                    </div>
                  </div>
                </>
              </TitleCard>
            ))}
          </InfiniteScroll>
        </div>
      </>
    </div>
  );
}
export default CommentsRatingResults;
