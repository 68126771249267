import { RFC } from "../../../types/propTypes";
import { SummaryData } from "../../../types/serviceTypes";

type FeedbackCardProps = {
  data: SummaryData[] | string;
  title: string;
  color: string;
};

const FeedbackCard: RFC<FeedbackCardProps> = ({ data, title, color }) => {
  const renderFeedbackResult = (summary: SummaryData[]) => {
    return (
      <>
        {summary.map((item, index) => (
          <div
            className={`flex items-center justify-between 
            text-MatesWhite rounded-[3px] 
            px-4 pt-[4px] pb-[2px] text-lg font-condensed font-medium
            ${index % 2 === 0 ? "bg-[#FFFFFF1A]" : "bg-MatesLightBlack"}`}
          >
            <p>{item.name}</p>
            <p>{item.count}</p>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="my-4">
      <p className={`font-condensed font-medium text-lg uppercase ${color}`}>
        {title}
      </p>
      {typeof data === "string" ? (
        <div
          className="flex items-center justify-between 
        bg-[#FFFFFF1A] text-MatesWhite rounded-[3px] 
          px-4 pt-[4px] pb-[2px] text-lg font-condensed font-medium"
        >
          <p>TOTAL</p>
          <p>{data}</p>
        </div>
      ) : (
        <>{renderFeedbackResult(data)}</>
      )}
    </div>
  );
};

export default FeedbackCard;
