import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMatesDispatch } from "../../app/hooks";
import { useFormik } from "formik";
import { MODAL_TYPES } from "../../assets/data/enums";
import { InitialLoginState } from "../../assets/data/InitialState";
import { MatesLogo } from "../../assets/images/images";
import { MatesButton, MatesLoginTextInput } from "../../components/atoms/atoms";
import { storeAdmin } from "../../features/cores/adminSlice";
import { openModal } from "../../features/cores/modalSlice";
import { useAdminLoginMutation } from "../../services/AuthService";
import asyncTimeout from "../../utils/asyncTimeout";
import { LoginSchema } from "../../utils/validationSchema";
import { MATES_ADMIN_TOKEN } from "../../assets/data/constants";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useMatesDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [adminLogin] = useAdminLoginMutation();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: InitialLoginState,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        const [data] = await Promise.all([
          adminLogin({
            email: values.email,
            password: values.password,
          }).unwrap(),
          asyncTimeout(2000),
        ]);

        if (!data.jwtToken) throw Error;
        dispatch(storeAdmin({ admin: data.adminDetails }));
        localStorage.setItem(MATES_ADMIN_TOKEN, data.jwtToken);
        navigate("/users");
      } catch (error) {
        dispatch(
          openModal({
            modalType: MODAL_TYPES.FAIL,
            title: "Failed to login",
            body: "Please check your input details and try again.",
          })
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  let isValid = values.email && values.password;

  return (
    <div className="min-h-screen w-full flex justify-center items-center bg-MatesBlack">
      <div className="w-[400px] mx-auto flex flex-col justify-start">
        <img
          src={MatesLogo}
          alt="card-logo"
          className="w-[67px] h-[67px] mb-8"
        />
        <p className="font-bold text-white text-[50px] mb-2 tracking-wider">
          SIGN IN
        </p>
        <p className="text-white text-[18px] tracking-wider">
          Welcome back to the Mates Hub. Sign in using your specific Email and
          Password to access the admin panel.
        </p>
        <form onSubmit={handleSubmit}>
          <MatesLoginTextInput
            name="loginEmail"
            label="EMAIL"
            type="text"
            value={values.email}
            onChange={(text) => setFieldValue("email", text)}
            isTouched={touched.email}
            error={errors.email}
          />
          <MatesLoginTextInput
            name="loginPassword"
            label="PASSWORD"
            type={isPasswordVisible ? "text" : "password"}
            value={values.password}
            onChange={(text) => setFieldValue("password", text)}
            isTouched={touched.password}
            error={errors.password}
            isVisible={isPasswordVisible}
            onIconPress={setIsPasswordVisible}
            onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
          />
          <MatesButton
            text="SIGN IN"
            className={`base-button mt-7 ${
              isValid ? "active-button" : "disabled-login-button"
            }`}
            disabled={!isValid || isSubmitting}
            onSubmit={handleSubmit}
            isLoading={isSubmitting}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
