import React from 'react'
import { RFC } from '../../../types/propTypes'
import CheckIcon from '../../../assets/icons/svg/checkIcon'

type Props = {
	isChecked: boolean
	onClick: () => void
}

const MatesCheckboxModal: RFC<Props> = ({ isChecked, onClick }) => {
	return (
		<div className='inline-flex items-center'>
			<label className='relative flex cursor-pointer items-center rounded-full p-3' htmlFor='checkbox' data-ripple-dark='true'>
				<input
					type='checkbox'
					className="before:content[''] peer relative h-5 w-5 
               cursor-pointer appearance-none rounded-md border 
               border-MatesRed transition-all before:absolute 
               before:top-2/4 before:left-2/4 before:block before:h-8 
               before:w-8 before:-translate-y-2/4 before:-translate-x-2/4 
               before:rounded-full before:bg-blue-gray-500 before:opacity-0 
               before:transition-opacity checked:border-MatesDarkRed 
               checked:bg-MatesDarkRed checked:before:bg-MatesDarkRed 
               hover:before:opacity-10"
					id='checkbox'
					checked={isChecked}
					onChange={onClick}
				/>
				<CheckIcon
					className='pointer-events-none absolute 
                top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 
                text-white opacity-0 transition-opacity peer-checked:opacity-100'
				/>
			</label>
		</div>
	)
}

export default MatesCheckboxModal
