import { cardAnimations } from "../../../assets/data/InitialState";
import { RFC } from "../../../types/propTypes";
import { WeeklyFeedback } from "../../../types/serviceTypes";
import { WeeklyFeedbackSummary } from "../organisms";
import { AnimatePresence, motion } from "framer-motion";

type UserFeedbackDetailProps = {
  weeklyFeedback: WeeklyFeedback;
};

const UserFeedbackDetail: RFC<UserFeedbackDetailProps> = ({
  weeklyFeedback,
}) => {
  return (
    <AnimatePresence>
      <motion.div key="weekly-feedback-card" {...cardAnimations}>
        <div className="pt-5">
          <div>
            <p className="text-MatesRed font-semibold text-3xl tracking-wide">
              WEEKLY FEEDBACK
            </p>
          </div>
          <div
            className="h-[calc(100vh-24em)] bg-MatesDarkGrey 
        overflow-y-auto rounded-[3px] px-4 py-2"
          >
            <p className="text-MatesWhite font-condensed text-lg pt-2">
              DID YOU HAVE POSITIVE TALKS? IF SO, WHERE?
            </p>
            <WeeklyFeedbackSummary
              summaries={weeklyFeedback.summary.positiveTalksLocationCountList}
            />

            <p className="text-MatesWhite font-condensed text-lg pt-2">
              HOW MANY PEOPLE HAVE YOU HELPED OR SUPPORTED?
            </p>
            <div
              className="flex flex-col p-3 text-MatesWhite rounded-[3px] mb-2 
          text-lg cursor-pointer transition-all duration-300 shadow-sm
          bg-MatesOffGrey border-MatesWhite border-[1px] border-solid"
            >
              <div className="flex items-center justify-between text-xl font-condensed">
                <p>{`TOTAL`}</p>
                <p className="pr-3">{`${weeklyFeedback.summary.peopleSupportedSum}`}</p>
              </div>
            </div>

            <p className="text-MatesWhite font-condensed text-lg pt-2">
              WHAT WERE THE KEY CONCERNS RAISED INDIVIDUALS RAISED?
            </p>
            <WeeklyFeedbackSummary
              summaries={weeklyFeedback.summary.keyConcernCountList}
            />

            <p className="text-MatesWhite font-condensed text-lg pt-2">
              ANY SAFE PLAN'S CREATED?
            </p>
            <WeeklyFeedbackSummary
              summaries={weeklyFeedback.summary.safePlanCreatedCountList}
            />

            <p className="text-MatesWhite font-condensed text-lg pt-2">
              MATES TEAM MEMBER CALL BACK REQUEST
            </p>
            <div
              className="flex flex-col p-3 text-MatesWhite rounded-[3px] mb-2 
          text-lg cursor-pointer transition-all duration-300 shadow-sm
        bg-MatesOffGrey border-MatesWhite border-[1px] border-solid"
            >
              <div className="flex flex-row justify-between text-xl font-condensed">
                <p>{`TOTAL`}</p>
                <p className="pr-3">{`${weeklyFeedback.summary.callbackRequestSum}`}</p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UserFeedbackDetail;
