import axios from "axios";
import { useState } from "react";
import {
  CinematicIcon,
  TiktokSVGIcon,
  UploadIcon,
} from "../../../assets/icons/icons";
import { formatChallengeRanking } from "../../../utils/formatter";
import { convertChallengeWinnerIndex } from "../../../utils/converter";
import { useLazyGetPresignedUploadUrlQuery } from "../../../services/FileService";
import {
  InputChangeEventHandler,
  IntRange,
  RFC,
} from "../../../types/propTypes";
import {
  ChallengeSampleCard,
  ChallengeWinnerCard,
  MatesButton,
} from "../atoms";

type MatesTikTokUploadInputProps = {
  source?: string;
  cardType: "sample" | "winner";
  permissionType: "readonly" | "write";
  index?: IntRange<0, 5>;
  onUpload: (file: string | undefined) => void;
};

const MatesTikTokUploadInput: RFC<MatesTikTokUploadInputProps> = ({
  source,
  cardType,
  permissionType,
  index,
  onUpload,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const [isUploadError, setIsUploadError] = useState(false);

  const [getPresignedUpload] = useLazyGetPresignedUploadUrlQuery();

  const handleUpload: InputChangeEventHandler = async ({ target }) => {
    try {
      onUpload("");
      setIsUploadError(false);
      setIsUploading(true);
      const files = target.files;
      if (!files) return;
      const file = files[0];
      if (!file) return;

      const data = await getPresignedUpload({
        fileName: file.name,
        folder: "tiktok_videos",
        rootFolder: "videos",
      }).unwrap();
      if (!data) return;

      const uploading = await axios.put(data.url, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (e) => {
          setLoaded(parseInt(((e.loaded * 100) / (e.total ?? 0)).toFixed()));
          if (e.loaded === e.total) setIsUploading(false);
        },
      });
      if (uploading.status !== 200) throw Error;
      onUpload(data.fileName);
    } catch (error) {
      setIsUploadError(true);
    } finally {
      setIsUploading(false);
    }
  };

  const renderTikTokPlayer = () => {
    if (!source && loaded === 0) {
      return (
        <div
          className="base-tiktok-container aspect-tiktok text-7xl 
        tracking-wide text-white/40 font-compressed font-bold mt-3"
        >
          <CinematicIcon />
        </div>
      );
    } else if (!source) {
      return (
        loaded > 0 &&
        loaded < 100 && (
          <div
            className={
              "base-tiktok-container aspect-tiktok text-4xl tracking-wide mt-3"
            }
          >
            {loaded}%
          </div>
        )
      );
    } else {
      return (
        <>
          {cardType === "winner" ? (
            <ChallengeWinnerCard
              source={source}
              index={convertChallengeWinnerIndex(index ?? 0)}
            />
          ) : (
            <ChallengeSampleCard source={source} />
          )}
        </>
      );
    }
  };

  return (
    <div className="w-[180px]">
      <div className="flex items-center flex-wrap relative">
        <input
          type="file"
          accept="video/*"
          className={`absolute inset-0 opacity-0 z-50 file:cursor-pointer ${
            permissionType !== "readonly"
              ? "cursor-pointer"
              : "cursor-not-allowed"
          }`}
          disabled={permissionType !== "readonly" ? isUploading : true}
          onChange={handleUpload}
        />
        <MatesButton
          icon={
            <UploadIcon width={16} height={16} color="#FFF" className="mr-1" />
          }
          text={"Upload File"}
          className={`base-button w-full text-base font-normal text-MatesWhite font-condensed h-10 
          ${
            permissionType !== "readonly"
              ? "upload-light-button"
              : "upload-disabled-button"
          }`}
          onSubmit={() => {}}
        />
      </div>
      {isUploadError && (
        <div className="text-MatesRed text-xl tracking-wider">
          Failed to upload the file
        </div>
      )}
      {renderTikTokPlayer()}
    </div>
  );
};

export default MatesTikTokUploadInput;
