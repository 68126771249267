import { MatesApi } from "../app/api";
import {
  ChallengePaginationPayload,
  ChallengesData,
  CreateChallengeArgs,
  PublishWinnersArgs,
  UpdateChallengeArgs,
} from "../types/serviceTypes";
import { Challenge } from "../types/stateTypes";

export const challengeApi = MatesApi.injectEndpoints({
  endpoints: (builder) => ({
    getChallenges: builder.query<ChallengesData, ChallengePaginationPayload>({
      query: ({ page, take, filter }) => ({
        url: `/admin/challenges`,
        params: { page, take, filter },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        currentCache.data.splice(
          arg.page === 1 ? 0 : arg.page * arg.take,
          currentCache.data.length
        );
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["Challenge"],
    }),
    createChallenge: builder.mutation<Challenge, CreateChallengeArgs>({
      query: ({ ...createArgs }) => ({
        url: "/admin/challenges/",
        method: "POST",
        body: { ...createArgs },
      }),
      invalidatesTags: [{ type: "Challenge", id: "LIST" }],
    }),
    updateChallenge: builder.mutation<Challenge, UpdateChallengeArgs>({
      query: ({ challengeId, ...updateArgs }) => ({
        url: `/admin/challenges/${challengeId}`,
        method: "PATCH",
        body: { ...updateArgs },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Challenge", id: arg.challengeId },
      ],
    }),
    publishWinners: builder.mutation<string, PublishWinnersArgs>({
      query: ({ challengeId, winners }) => ({
        url: `/admin/challenges/${challengeId}/winners`,
        method: "POST",
        body: { winners },
        responseHandler: "text",
      }),
      invalidatesTags: [{ type: "Challenge", id: "LIST" }],
    }),
  }),
});

export const {
  useLazyGetChallengesQuery,
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
  usePublishWinnersMutation,
} = challengeApi;
