import { Category, Resource } from '../../../types/stateTypes'
import { RootState } from '../../../app/store'
import { InitialSelectedResourcesState } from '../../../assets/data/InitialState'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SelectedResourcesSliceType = {
	selectedResources: Resource[]
	selectedCategories: Category[]
}

export const duplicateResourceSlice = createSlice({
	name: 'duplicateResources',
	initialState: InitialSelectedResourcesState,
	reducers: {
		clearSelectedResources: (state) => {
			state.selectedResources = []
		},
		clearSelectedCategories: (state) => {
			state.selectedCategories = []
		},
		addOrRemoveResource: (state, action: PayloadAction<Resource>) => {
			if (state.selectedResources.some((resource) => resource.id === action.payload.id)) {
				const filteredResources = state.selectedResources.filter((resource) => resource.id !== action.payload.id)
				state.selectedResources = [...filteredResources]
			} else {
				state.selectedResources.unshift(action.payload)
			}
		},
		addOrRemoveCategory: (state, action: PayloadAction<Category>) => {
			if (state.selectedCategories.some((category) => category.id === action.payload.id)) {
				const filteredCategories = state.selectedCategories.filter((cat) => cat.id !== action.payload.id)
				state.selectedCategories = [...filteredCategories]
			} else {
				state.selectedCategories.unshift(action.payload)
			}
		},
	},
})

export const selectedResourcesSelector = (state: RootState) => state.selectedResources.selectedResources
export const selectedCategoriesSelector = (state: RootState) => state.selectedResources.selectedCategories
export const { clearSelectedResources, addOrRemoveResource, addOrRemoveCategory, clearSelectedCategories } = duplicateResourceSlice.actions
export default duplicateResourceSlice.reducer
