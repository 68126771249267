import { useRef } from "react";
import { RFC } from "../../../types/propTypes";
import useAutosizeTextArea from "../../../utils/useAutosizeTextArea";

type MatesChallengeTextAreaProps = {
  permissionType: "readonly" | "write";
  value: string;
  className: string;
  onChange: (text: string) => void;
};

const MatesChallengeTextArea: RFC<MatesChallengeTextAreaProps> = ({
  permissionType,
  value,
  className,
  onChange,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value ?? "");

  return (
    <div className="w-full">
      {permissionType === "write" ? (
        <textarea
          ref={textAreaRef}
          rows={1}
          value={value ?? ""}
          required={true}
          onChange={(e) => onChange(e.target.value)}
          className={`bg-MatesDarkGrey w-full outline-none input-text-sm 
          translate-y-0 max-h-[5em] border-solid tracking-wide
          border-[1px] border-MatesWhite rounded-[5px] px-2`}
        />
      ) : (
        <div className={`max-w-[30rem] ${className}`}>
          {!value ? "Empty" : value}
        </div>
      )}
    </div>
  );
};

export default MatesChallengeTextArea;
