import { useMutation } from "react-query";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { closeModal, modalSelector } from "../../../features/cores/modalSlice";
import ChallengeService from "../../../services/react-query/ChallengeService";
import MatesFlexiButton from "../../atoms/buttons/MatesFlexiButton";

const AnnounceWinnerModal = () => {
  const { itemId = "", callbackFn } = useMatesSelector(modalSelector);
  const dispatch = useMatesDispatch();

  const onSubmit = () => {
    callbackFn && callbackFn(itemId);
    dispatch(closeModal());
  };

  return (
    <div className="w-[350px] px-8 py-3 flex flex-col items-center gap-3">
      <div className="text-MatesBlack">
        <h1 className="font-compressed text-3xl font-bold">ANNOUNCE THE WINNER!</h1>
        <p className="text-md font-normal leading-5">
          You are about to announce the winner and end this challenge. Do you want to continue?
        </p>
      </div>
      <MatesFlexiButton
        text="ANNOUNCE WINNER"
        className="red-button w-full"
        onSubmit={() => onSubmit()}
      />
      <MatesFlexiButton
        text="CANCEL"
        className="cancel-button w-full"
        onSubmit={() => dispatch(closeModal())}
      />
    </div>
  );
};

export default AnnounceWinnerModal;
