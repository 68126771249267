import { useMemo } from "react";
import { RFC } from "../../../types/propTypes";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { challengeSelector, permissionTypeSelector, updateChallengeDetail } from "../../../features/challenges/challengeSlice";
import { MatesButton, MatesChallengeTitleBlock, MatesTikTokUploadInput } from "../../atoms/atoms";

type UploadChallengeFieldProps = {
  type: "link" | "featuredChallengeLink";
  source?: string;
  isLoading?: boolean;
  onSubmit?: () => void;
};

const UploadChallengeField: RFC<UploadChallengeFieldProps> = ({ type, source, isLoading, onSubmit }) => {
  const { id } = useMatesSelector(challengeSelector);
  const permissionType = useMatesSelector(permissionTypeSelector);
  const dispatch = useMatesDispatch();

  const isDisabled = useMemo(() => {
    if (type === "link" && !source) return true;
    return false;
  }, [source]);

  return (
    <MatesTikTokUploadInput
      source={source}
      cardType="sample"
      permissionType={"write"}
      onUpload={(link) =>
        dispatch(
          updateChallengeDetail({
            key: type,
            value: link ?? "",
          })
        )
      }
    />
  );
};

export default UploadChallengeField;
