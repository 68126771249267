import axios from "axios";
import { MATES_ADMIN_TOKEN } from "../../assets/data/constants";
import { Challenge } from "../../types/stateTypes";
import { MatesPaginatedResponse } from "../../utils/query/query";

const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_HEADER = {
  Authorization: `Bearer ${localStorage.getItem(MATES_ADMIN_TOKEN)}`,
};

export type TopPick = {
  id: string;
  challengeId: string;
  link: string;
  _count: {
    likes: number;
  };
};

namespace ChallengeService {
  export const getChallenges = (params: {
    page: number;
    take: number;
    filter?: "ACTIVE" | "PAST" | undefined;
  }) => {
    return axios<MatesPaginatedResponse<Challenge>>(BASE_URL + "admin/challenges", {
      method: "GET",
      params: params,
      headers: BASE_HEADER,
    });
  };

  export const patchChallenges = (values: {
    id: string;
    data: {
      winnerAnnouncementDate?: string;
      completedDate?: string;
    };
  }) => {
    return axios<Challenge>(BASE_URL + "admin/challenges/" + values.id, {
      method: "PATCH",
      data: values.data,
      headers: BASE_HEADER,
    });
  };

  export const setChallengeWinners = (values: {
    id: string;
    data: {
      winners: { link: string; ranking: number }[];
    };
  }) => {
    return axios<Challenge>(BASE_URL + `admin/challenges/${values.id}/winners`, {
      method: "POST",
      data: values.data,
      headers: BASE_HEADER,
    });
  };

  export const getTopPicks = (params: { page: number; take: number; challengeId: string }) => {
    return axios<MatesPaginatedResponse<TopPick>>(BASE_URL + "challenge-top-picks", {
      method: "GET",
      params: params,
    });
  };

  export const uploadTopPick = (values: {
    data: {
      challengeId: string;
      link: string;
    };
  }) => {
    return axios<TopPick>(BASE_URL + "challenge-top-picks", {
      method: "POST",
      data: values.data,
      headers: BASE_HEADER,
    });
  };

  export const updateTopPick = (values: {
    id: string;
    data: {
      link: string;
    };
  }) => {
    return axios<TopPick>(BASE_URL + `challenge-top-picks/${values.id}`, {
      method: "PATCH",
      data: values.data,
      headers: BASE_HEADER,
    });
  };

  export const likeTopPick = (values: { id: string }) => {
    return axios<TopPick>(BASE_URL + `challenge-top-picks/${values.id}/like`, {
      method: "POST",
      headers: BASE_HEADER,
    });
  };

  export const unlikeTopPick = (values: { id: string }) => {
    return axios<TopPick>(BASE_URL + `challenge-likes/${values.id}`, {
      method: "DELETE",
      headers: BASE_HEADER,
    });
  };
}

export default ChallengeService;
