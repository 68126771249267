import { RFC } from "../../../types/propTypes";
import { ErrorIcon, TiktokSVGIcon } from "../../../assets/icons/icons";
import { MatesLoading } from "../atoms";
import { useGetPresignedDownloadUrlQuery } from "../../../services/FileService";

type MatesAsyncVideoProps = {
  fileName: string | undefined;
  isVideoError: boolean;
  setIsVideoError: (isError: boolean) => void;
};

const MatesAsyncVideo: RFC<MatesAsyncVideoProps> = ({ fileName, isVideoError, setIsVideoError }) => {
  const { data, isLoading, isError } = useGetPresignedDownloadUrlQuery({ fileName: fileName ?? "" }, { skip: !fileName });

  if (isLoading)
    return (
      <div className="h-full w-full flex justify-center items-center rounded-lg">
        <MatesLoading shape="rounded" />
      </div>
    );

  if (isError || isVideoError)
    return (
      <div className="flex items-center justify-center">
        <ErrorIcon width="30" height="30" />
        <p className="text-MatesRed font-condensed text-xl ml-2 tracking-wide">Video unavailable</p>
      </div>
    );

  if (!data || !data.url) return <TiktokSVGIcon />;

  return (
    <video className="w-full h-full" controls>
      <source src={data.url} onError={() => setIsVideoError(true)} />
    </video>
  );
};

export default MatesAsyncVideo;
