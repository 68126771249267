import { useMatesSelector } from '../../../app/hooks'
import { matesResourceTabs } from '../../../assets/data/arrays'
import { categoryIdSelector } from '../../../features/resources/categoriesSlice'
import { resourcesCountSelector } from '../../../features/resources/Resource/resourcesSlice'
import { ResourceTabList } from '../../../types/propTypes'
import { convertResourceType } from '../../../utils/converter'
import { MatesButton } from '../../atoms/atoms'
import { useState } from 'react'
import InfiniteResourceListSelectable from '../../molecules/lists/InfiniteResourceListSelectable'

const DuplicateResourcesList = () => {
	const resourcesCount = useMatesSelector(resourcesCountSelector)
	const categoryIdState = useMatesSelector(categoryIdSelector)

	const [selectedResourceTab, setSelectedResourceTab] = useState<ResourceTabList>('PODCASTS')

	return (
		<div className='px-6 h-1/2 grow flex flex-col'>
			<div className='flex items-center justify-between gap-1 mb-1'>
				{matesResourceTabs.map((tab, index) => (
					<MatesButton
						key={index}
						text={tab}
						className={`small-base-button 
            ${selectedResourceTab === tab ? 'small-active-button hover:bg-MatesRed' : 'small-black-button '} tracking-wide text-xl`}
						onSubmit={() => setSelectedResourceTab(tab)}
					/>
				))}
			</div>
			<InfiniteResourceListSelectable categoryId={categoryIdState} resourceType={convertResourceType(selectedResourceTab)} />
		</div>
	)
}

export default DuplicateResourcesList
