const ArrowBackIcon = ({ height, width, color, className, onClick }: React.SVGProps<SVGSVGElement>) => {
	return (
		<div className={className}>
			<svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' onClick={onClick}>
				<path d='M16.648 29.3L4 16.648L16.648 4L18.308 5.66L8.508 15.46H29.3V17.831H8.506L18.306 27.631L16.648 29.3Z' fill='#2B2B2B' />
			</svg>
		</div>
	)
}

export default ArrowBackIcon
