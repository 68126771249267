import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Challenge } from "../../types/stateTypes";
import { RootState } from "../../app/store";
import { IntRange, Tuple } from "../../types/propTypes";
import { InitialChallenge } from "../../assets/data/InitialState";
import { formatInitialWinners } from "../../utils/formatter";
import { convertISOToDateString } from "../../utils/converter";

export type ChallengeSliceType = {
  challenge: Challenge;
  isDetailOpen: boolean;
  winnersLinkErrors: Tuple<boolean, 5>;
  selectedTikTokURL: string;
  permissionType: "readonly" | "write";
};

type UpdateChallengeActionArgs = {
  key: keyof Pick<
    Challenge,
    | "title"
    | "hashtag"
    | "submissionCloseDate"
    | "description"
    | "link"
    | "featuredChallengeLink"
  >;
  value: string;
};

type UpdateChallengeWinnerActionArgs = {
  index: IntRange<0, 5>;
  link: string;
};

type UpdateChallengeWinnerLinkErrorArgs = Pick<
  UpdateChallengeWinnerActionArgs,
  "index"
> & {
  error: boolean;
};

export const challengeSlice = createSlice({
  name: "challenge",
  initialState: InitialChallenge,
  reducers: {
    selectChallenge: (state, action: PayloadAction<Challenge>) => {
      const winners = formatInitialWinners(action.payload);
      state.challenge = {
        ...action.payload,
        winners,
        submissionCloseDate: convertISOToDateString(
          action.payload.submissionCloseDate
        ),
      };
      state.isDetailOpen = true;
    },
    selectNewChallenge: (state) => {
      state.challenge = InitialChallenge.challenge;
      state.isDetailOpen = true;
    },
    closeDetail: (state) => {
      state.isDetailOpen = false;
    },
    updateChallengeDetail: (
      state,
      action: PayloadAction<UpdateChallengeActionArgs>
    ) => {
      state.challenge[action.payload.key] = action.payload.value;
    },
    updateChallengeWinner: (
      state,
      action: PayloadAction<UpdateChallengeWinnerActionArgs>
    ) => {
      if (action.payload.link === "")
        state.winnersLinkErrors[action.payload.index] = false;
      state.challenge.winners[action.payload.index].link = action.payload.link;
      state.challenge.winners[action.payload.index].ranking =
        action.payload.index + 1;
    },
    updateChallengeLinkError: (
      state,
      action: PayloadAction<UpdateChallengeWinnerLinkErrorArgs>
    ) => {
      if (!action.payload.error)
        state.winnersLinkErrors[action.payload.index] = false;
      else state.winnersLinkErrors[action.payload.index] = true;
    },
    playTikTokVideo: (state, action: PayloadAction<string>) => {
      state.selectedTikTokURL = action.payload;
    },
    changePermission: (state, action: PayloadAction<"readonly" | "write">) => {
      state.permissionType = action.payload;
    },
  },
});

export const challengeIdSelector = (state: RootState) =>
  state.challenge.challenge.id;
export const challengeSelector = (state: RootState) =>
  state.challenge.challenge;
export const isDetailOpenSelector = (state: RootState) =>
  state.challenge.isDetailOpen;
export const challengeErrorsSelector = (state: RootState) =>
  state.challenge.winnersLinkErrors;
export const tikTokURLSelector = (state: RootState) =>
  state.challenge.selectedTikTokURL;
export const permissionTypeSelector = (state: RootState) =>
  state.challenge.permissionType;
export const {
  selectChallenge,
  selectNewChallenge,
  closeDetail,
  updateChallengeDetail,
  updateChallengeWinner,
  updateChallengeLinkError,
  playTikTokVideo,
  changePermission,
} = challengeSlice.actions;
export default challengeSlice.reducer;
