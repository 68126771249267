import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialOverlayState } from '../../assets/data/InitialState'
import { Overlay } from '../../types/propTypes'

type OverlaySliceType = Pick<Overlay, 'text'>

export const overlaySlice = createSlice({
    name: 'overlay',
    initialState: InitialOverlayState,
    reducers: {
        openOverlay: (state, action:PayloadAction<OverlaySliceType>) => {
            state.isLoading = true
            state.text      = action.payload.text
        },
        closeOverlay: (state) => {
            state.isLoading = false
        }
    }
})

export const overlaySelector = (state: RootState) => state.overlay
export const { openOverlay, closeOverlay } = overlaySlice.actions
export default overlaySlice.reducer