import { useMatesSelector } from '../../../app/hooks'
import { overlaySelector } from '../../../features/cores/overlaySlice'
import { MatesLoading } from '../atoms'

const MatesOverlay = () => {
  const overlayState = useMatesSelector(overlaySelector)

  if (!overlayState.isLoading) return null

  return (
    <div className='absolute inset-0 bg-MatesBlack/80 backdrop-blur-sm z-50'>
      <div 
        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
        h-[8em] flex flex-col justify-between text-center'
      >
        <MatesLoading shape='overlay'/>
        <p className='text-MatesWhite text-3xl font-semibold'>
          {overlayState.text}
        </p>
      </div>
    </div>
  )
}

export default MatesOverlay