import { PinIcon, VisibilityIcon } from '../../../assets/icons/icons'
import { RFC } from '../../../types/propTypes'
import { MatesToggleButton } from '../../atoms/atoms'

type IsActiveFieldProps = {
	isActive: boolean
	onChange: () => void
}

const IsActiveField: RFC<IsActiveFieldProps> = ({ isActive, onChange }) => {
	return (
		<div className='flex items-center justify-between pb-2'>
			<div className='flex items-center'>
				<p className='text-MatesWhite text-lg font-condensed'>Show Resource</p>
			</div>
			<MatesToggleButton isActive={isActive} onChangeStatus={onChange} />
		</div>
	)
}

export default IsActiveField
