import asyncTimeout from "../../../utils/asyncTimeout";
import { openModal } from "../../../features/cores/modalSlice";
import { ArrowLeft } from "../../../assets/icons/icons";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { tiktokDetailAnimation } from "../../../assets/data/InitialState";
import { formatTopPicksAsWinners, formatWinnersWithoutId } from "../../../utils/formatter";
import { convertDateStringToISO } from "../../../utils/converter";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { challengeSelector, closeDetail } from "../../../features/challenges/challengeSlice";
import { AnimatePresence, LazyMotion, domAnimation, motion } from "framer-motion";
import { ChallengeDetailField, UploadChallengeField } from "../../molecules/molecules";
import { useCreateChallengeMutation, usePublishWinnersMutation } from "../../../services/Challenge";
import { closeOverlay, openOverlay } from "../../../features/cores/overlaySlice";
import { MatesChallengeTitleBlock } from "../../atoms/atoms";
import MatesFlexiButton from "../../atoms/buttons/MatesFlexiButton";
import moment from "moment";
import ChallengeTopPicksField from "../../molecules/fields/ChallengeTopPicksField";
import { useMutation, useQuery } from "react-query";
import ChallengeService from "../../../services/react-query/ChallengeService";
import ChallengeTopPicksVotingField from "../../molecules/fields/ChallengeTopPicksVotingField";
import { DEFAULT_PAGE_SIZE, TOP_PICK_KEY } from "../../../assets/data/constants";

const TikTokDetail = () => {
  const {
    id,
    title,
    hashtag,
    submissionCloseDate,
    winnerAnnouncementDate,
    description,
    link,
    featuredChallengeLink,
    winners,
  } = useMatesSelector(challengeSelector);
  const dispatch = useMatesDispatch();

  const challengeState: "NEW" | "CURRENT" | "IN_VOTING" = (() => {
    if (!id) return "NEW";
    if (!!winnerAnnouncementDate) return "IN_VOTING";
    return "CURRENT";
  })();

  const [createChallenge, { isLoading: challengeLoading }] = useCreateChallengeMutation();
  const [publishWinners, { isLoading: winnerLoading }] = usePublishWinnersMutation();

  const createNewChallenge = async () => {
    try {
      dispatch(openOverlay({ text: "Creating Challenge" }));

      const [challenge] = await Promise.all([
        createChallenge({
          title,
          hashtag,
          submissionCloseDate: convertDateStringToISO(submissionCloseDate),
          description,
          link,
          featuredChallengeLink,
        }).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!challenge) throw Error;
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: `CHALLENGE CREATED`,
          body: "You have successfully created the challenge!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to create the challenge",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
      dispatch(closeDetail());
    }
  };

  const publishChallengeWinners = async () => {
    try {
      dispatch(openOverlay({ text: "Publishing Winners" }));
      const [publishedWinners] = await Promise.all([
        publishWinners({
          challengeId: id,
          winners: formatTopPicksAsWinners(topPicks),
        }).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!publishedWinners) throw Error;
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: `CHALLENGE WINNERS PUBLISHED`,
          body: "You have successfully published the winners!",
        })
      );
      dispatch(closeDetail());
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to publish the winners",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const { data: topPickData } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      ChallengeService.getTopPicks({
        page: pageParam,
        take: DEFAULT_PAGE_SIZE,
        challengeId: id,
      }),
    queryKey: [TOP_PICK_KEY, id],
    enabled: !!id,
  });
  const topPicks = topPickData ? topPickData.data.data : [];

  const onClickCreateChallenge = () => {
    dispatch(
      openModal({
        modalType: MODAL_TYPES.PUBLISH_CHALLENGE,
        itemId: id,
        callbackFn: () => createNewChallenge(),
      })
    );
  };

  const onClickCloseSubmissions = () => {
    dispatch(
      openModal({
        itemId: id,
        modalType: MODAL_TYPES.CLOSE_SUBMISSION,
      })
    );
  };

  const onClickAnnounceWinner = () => {
    dispatch(
      openModal({
        modalType: MODAL_TYPES.ANNOUNCE_WINNER,
        itemId: id,
        callbackFn: () => publishChallengeWinners(),
      })
    );
  };

  const renderSubmitButton = () => {
    if (challengeState === "IN_VOTING") {
      const date = moment(convertDateStringToISO(winnerAnnouncementDate));
      return (
        <>
          <MatesChallengeTitleBlock
            title={`CLOSE VOTING & ANNOUNCE WINNER - ${date.format("D MMMM YYYY")}`}
            description={
              "You are about to announce the winner of this challenge. Click the button below to end challenge."
            }
          />
          <MatesFlexiButton
            text="ANNOUNCE WINNER"
            className={`active-button w-[250px] h-[43px]`}
            isLoading={challengeLoading}
            disabled={challengeLoading}
            onSubmit={onClickAnnounceWinner}
          />
        </>
      );
    }
    if (challengeState === "CURRENT") {
      const date = moment(convertDateStringToISO(submissionCloseDate));
      return (
        <>
          <MatesChallengeTitleBlock
            title={`CLOSE CHALLENGE SUBMISSIONS - ${date.format("D MMMM YYYY")}`}
            description={
              "Before closing submissions, please check there is no active challenge in In For Voting."
            }
          />
          <MatesFlexiButton
            text="CLOSE SUBMISSIONS"
            className={`active-button w-[250px] h-[43px]`}
            isLoading={challengeLoading}
            disabled={challengeLoading}
            onSubmit={onClickCloseSubmissions}
          />
        </>
      );
    }
    return (
      <>
        <MatesChallengeTitleBlock
          title={"PUBLISH CHALLENGE"}
          description={"When you are ready to publish this challenge, click the button below."}
        />
        <MatesFlexiButton
          text="PUBLISH CHALLENGE"
          className={`active-button w-[250px] h-[43px]`}
          isLoading={challengeLoading}
          disabled={challengeLoading}
          onSubmit={onClickCreateChallenge}
        />
      </>
    );
  };

  return (
    <>
      <AnimatePresence>
        <LazyMotion key="tiktok-detail-container" features={domAnimation}>
          <motion.div
            key="tiktok-detail"
            {...tiktokDetailAnimation}
            className="xl:w-[75%] w-[80%] h-[calc(100vh-6rem)] 
              mx-auto overflow-y-auto flex pt-5"
          >
            <ArrowLeft className="cursor-pointer mt-4" onClick={() => dispatch(closeDetail())} />
            <div className="w-full h-full pl-5 pr-2">
              <ChallengeDetailField />
              <hr className="border-MatesWhite/10 my-8" />

              <div className="grid grid-cols-[auto_20px_repeat(5,_auto)] gap-3 w-fit">
                <MatesChallengeTitleBlock
                  className=""
                  title={"IT'S LIKE THIS"}
                  description={"Upload the MATES explanation video file below."}
                />
                <div className="row-span-2" />

                {challengeState === "CURRENT" && (
                  <MatesChallengeTitleBlock
                    className="col-span-5"
                    title={"TOP PICKS"}
                    description={
                      "Select the best video submission and upload the video in the spaces below."
                    }
                  />
                )}
                {challengeState !== "CURRENT" && <div className="col-span-5"></div>}
                <UploadChallengeField
                  type="link"
                  source={link}
                  isLoading={challengeLoading}
                  onSubmit={onClickCreateChallenge}
                />
                {challengeState === "CURRENT" && <ChallengeTopPicksField challengeId={id} />}
                {challengeState === "IN_VOTING" && (
                  <ChallengeTopPicksVotingField challengeId={id} />
                )}
              </div>

              <div className="flex flex-col gap-6 pb-8">
                <hr className="border-MatesWhite/10 mt-8" />
                <div className="flex flex-col gap-3">{renderSubmitButton()}</div>
              </div>
            </div>
          </motion.div>
        </LazyMotion>
      </AnimatePresence>
    </>
  );
};

export default TikTokDetail;
