import { NavbarType, RFC } from "../../types/propTypes";
import { UserGroup } from "../../types/stateTypes";
import {
  Navbar,
  ResourceSubNavbar,
  SurveySubNavbar,
  UserSubNavbar,
} from "./templates";

type DashboardLayoutProps = Pick<NavbarType, "page"> & {
  userGroups?: UserGroup[];
  isLoading?: boolean;
  children: JSX.Element;
  className?: string;
};

const DashboardLayout: RFC<DashboardLayoutProps> = ({
  page,
  userGroups,
  isLoading,
  children,
  className,
}) => {
  const renderSubNavbar = () => {
    switch (page) {
      case "RESOURCES":
        return <ResourceSubNavbar subTabs={userGroups} isLoading={isLoading} />;
      case "USERS":
        return <UserSubNavbar subTabs={userGroups} isLoading={isLoading} />;
      case "SURVEYS":
        return <SurveySubNavbar />;
      default:
        return null;
    }
  };

  return (
    <div className={`dashboard-layout ${className}`}>
      <Navbar page={page} />
      {renderSubNavbar()}
      <div className={`dashboard-layout-div ${className}`}>{children}</div>
    </div>
  );
};

export default DashboardLayout;
