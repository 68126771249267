const InstallIcon = ({
  height,
  width,
  className,
  color,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        width={width ?? "18"}
        height={height ?? "18"}
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.636 17.446C1.20538 17.4356 0.795275 17.2599 0.490692 16.9553C0.186109 16.6507 0.0104054 16.2406 0 15.81V11.91H1.636V15.81H15.81V11.91H17.446V15.81C17.4356 16.2406 17.2599 16.6507 16.9553 16.9553C16.6507 17.2599 16.2406 17.4356 15.81 17.446H1.636ZM8.723 13.275L3.462 8.014L4.634 6.842L7.905 10.113V0H9.541V10.113L12.812 6.842L13.984 8.014L8.723 13.275Z"
          fill={color ?? "#FFFFFF"}
        />
      </svg>
    </div>
  );
};

export default InstallIcon;
