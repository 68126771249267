import { useMemo } from "react";
import { RFC } from "../../../types/propTypes";
import { User } from "../../../types/stateTypes";
import { useMatesSelector } from "../../../app/hooks";
import { userGroupSelector } from "../../../features/users/userSlice";

type UserProfileDetailProps = {
  user: User;
};

const UserProfileDetail: RFC<UserProfileDetailProps> = ({ user }) => {
  const userGroup = useMatesSelector(userGroupSelector);
  const userRoleUpdated = useMemo(() => {
    if (!user.role) return "";
    if (user.role.length < 30) return user.role;
    else return user.role.slice(0, 30).concat("...");
  }, [user.role]);

  return (
    <>
      <div className="text-MatesRed font-semibold text-3xl">DETAILS</div>

      <div
        className="bg-MatesDarkGrey rounded-[3px] 
        text-MatesWhite px-4 py-3"
      >
        <div className="flex items-center text-xl font-condensed font-medium">
          <p className="line-clamp-1 pt-[2px]">{user?.email}</p>
          <span className="mx-2">●</span>
          <p>{user.workState}</p>
        </div>
        <div className="w-full h-[1px] bg-MatesBorder my-3"></div>
        <div className="flex items-center text-xl font-condensed font-medium">
          <p className="line-clamp-1 pt-[2px]">
            {userGroup === "OTHER" && (
              <span className="text-MatesRed mr-3">ROLE</span>
            )}
            {userRoleUpdated}
            {user.isConnectorAsistVolunteer && (
              <span> & CONNECTOR / ASIST VOLUNTEER</span>
            )}
          </p>
          <span className="mx-2">●</span>
          <p>{user.workIndustry}</p>
        </div>
      </div>
    </>
  );
};

export default UserProfileDetail;
