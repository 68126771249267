import { useMatesDispatch } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { DataTableIcon } from "../../../assets/icons/icons";
import { openModal } from "../../../features/cores/modalSlice";

const FeedbackDataButton = () => {
  const dispatch = useMatesDispatch()

  return (
    <div className="relative">
      <div
        className="absolute bottom-5 left-5 flex items-center 
        justify-center text-MatesWhite uppercase font-compressed 
        font-medium tracking-wide text-xl bg-MatesDarkGrey 
        rounded-[5px] px-3 py-1 cursor-pointer hover:bg-MatesBorder
        transition-all duration-300"
        onClick={() => dispatch(openModal({
          modalType: MODAL_TYPES.ALL_FEED_BACK
        }))}
      >
        <DataTableIcon />
        <p className="pt-[2px] ml-2">ALL FEEDBACK DATA</p>
      </div>
    </div>
  );
};

export default FeedbackDataButton;
