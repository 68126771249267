import { RFC } from '../../../types/propTypes'
import { Switch } from '@headlessui/react'

type MatesToggleButtonProps = {
    isActive:       boolean
    className?:     string
    onChangeStatus: (isOpen: boolean) => void
}

const MatesToggleButton:RFC<MatesToggleButtonProps> = ({ isActive, onChangeStatus, className }) => {
    return (
        <Switch
            checked={isActive}
            onChange={onChangeStatus}
            className={`${isActive ? 'bg-MatesGreen' : 'bg-MatesDisabled'}
            relative inline-flex h-[28px] w-12 items-center rounded-full
            border-[1px] border-solid border-MatesDisabled ${className}`}
        >
            <span className='sr-only'>Use setting</span>
            <span
                aria-hidden='true'
                className={`${isActive ? 'translate-x-5' : '-translate-x-[0px]'}
                inline-block h-[26px] w-[26px] transform rounded-full 
                bg-MatesWhite transition-all duration-300`}
            />
        </Switch>
    )
}

export default MatesToggleButton