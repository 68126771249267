import { MatesButton } from "../../atoms/atoms";
import { matesResourceTabs } from "../../../assets/data/arrays";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { searchTypesSelector, selectResourceType, selectUserGroup } from "../../../features/surveys/surveySlice";
import {
  ResourceResultsFilterDropdown,
  InfiniteResourceResultsList,
} from "../../molecules/molecules";

const ResourceResultsDetail = () => {
  const { userGroup, resourceType } = useMatesSelector(searchTypesSelector);
  const dispatch = useMatesDispatch()

  return (
    <div className="w-full">
      <div className="flex items-center justify-between pt-3">
        <div className="flex gap-x-3">
          {matesResourceTabs.map((tab, index) => (
            <MatesButton
              key={index}
              text={tab}
              className={`small-base-button tracking-wider text-xl
              ${
                resourceType === tab
                  ? "small-active-button hover:bg-MatesRed"
                  : "small-black-button "
              }`}
              onSubmit={() => dispatch(selectResourceType(tab))}
            />
          ))}
        </div>
        <div className="mt-3 shrink ">
          <ResourceResultsFilterDropdown
            onSelectGroup={(userGroupName) => {
              dispatch(selectUserGroup(userGroupName));
            }}
            value={userGroup}
          />
        </div>
      </div>
      <div className="mx-auto">
        <div className="w-full mx-auto flex items-center">
          <InfiniteResourceResultsList
            resourceType={resourceType}
            userGroupFilter={userGroup}
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceResultsDetail;
