const resourcesIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "18.782" }
                height={height ?? "16.22" }
                viewBox="0 0 18.782 16.22"
            >
                <path 
                    id="folder_copy_FILL0_wght400_GRAD0_opsz48" 
                    d="M3.281,20.22A1.275,1.275,0,0,1,2,18.94V6.775H3.281V18.94h15.58V20.22Zm2.561-2.561a1.275,1.275,0,0,1-1.281-1.281V5.281A1.275,1.275,0,0,1,5.842,4h5.549l1.281,1.281H19.5a1.275,1.275,0,0,1,1.281,1.281v9.818A1.275,1.275,0,0,1,19.5,17.659Zm0-1.281H19.5V6.561H12.138L10.857,5.281H5.842Zm0,0v0Z" 
                    transform="translate(-2 -4)" 
                    fill="#fff" 
                />
            </svg>
        </div>
    )
}

export default resourcesIcon