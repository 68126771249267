import { RFC } from '../../../types/propTypes'
import {
  InventoryIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from '../../../assets/icons/icons'
import { MatesToggleButton } from '../../atoms/atoms'

type IconTextSwitchRowProps = {
  isActive:   boolean
  text:       string
  icon:       'hidden' | 'available' | 'training'
  className?: string
  onChange:   () => void
}

const IconTextSwitchRow:RFC<IconTextSwitchRowProps> = ({
  isActive,
  onChange,
  text,
  icon,
  className,
}) => {
  const getIcon = () => {
    switch (icon) {
      case 'available':
        return <VisibilityIcon color={isActive ? '#ed1941' : '#696969'} />
      case 'hidden':
        return <VisibilityOffIcon color={isActive ? '#ed1941' : '#696969'} />
      case 'training':
        return <InventoryIcon color={isActive ? '#ed1941' : '#696969'} />
      default:
        break
    }
  }

  return (
    <div className={`flex justify-between items-center mb-3 ${className}`}>
      <div className='flex justify-start items-center'>
        {getIcon()}
        <p className='ml-2 text-MatesBlack'>{text}</p>
      </div>
      <MatesToggleButton
        isActive={isActive}
        onChangeStatus={onChange}
        className={'outline-none'}
      />
    </div>
  )
}

export default IconTextSwitchRow