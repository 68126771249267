import { AiOutlineArrowLeft as BackArrow } from "react-icons/ai";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { closeModal, openModal } from "../../../features/cores/modalSlice";
import {
  MatesButton,
  MatesLinkInput,
  MatesTextArea,
  MatesTextInput,
  MatesTitleBlock,
  MatesUploadInput,
} from "../../atoms/atoms";
import {
  updateUserGroup,
  userGroupSelector,
} from "../../../features/resources/userGroupsSlice";
import { useUpdateUserGroupDetailsMutation } from "../../../services/UserGroupService";
import { MODAL_TYPES } from "../../../assets/data/enums";
import asyncTimeout from "../../../utils/asyncTimeout";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/cores/overlaySlice";
import { formatSlug } from "../../../utils/formatter";

const EditUserGroupModal = () => {
  const {
    id,
    title,
    slug,
    discoverForYourselfDescription,
    thumbnail,
    videoLink,
    discoverResourcesDescription,
  } = useMatesSelector(userGroupSelector);
  const dispatch = useMatesDispatch();

  const [updateUserGroupDetails, { isLoading }] =
    useUpdateUserGroupDetailsMutation();

  const updateUserGroupInfo = async () => {
    try {
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Updating Resource" }));
      const [data] = await Promise.all([
        updateUserGroupDetails({
          id,
          discoverForYourselfDescription,
          thumbnail,
          videoLink,
          discoverResourcesDescription,
          slug
        }).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!data) throw Error;
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "USER GROUP UPDATED",
          body: "You have successfully updated the user group!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to update the user group information",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  return (
    <div className="h-fit w-[500px] px-6 py-3">
      <BackArrow
        className="text-[2em] my-3 cursor-pointer"
        onClick={() => dispatch(closeModal())}
      />
      <MatesTitleBlock title={title} className="pb-3" />
      <MatesTextInput
        name='slug'
        label='URL Slug'
        theme='light'
        value={slug}
        onChange={(text) =>
          dispatch(
            updateUserGroup({
              key: "slug",
              value: formatSlug(text),
            })
          )
        }
      />
      <MatesTextArea
        label="DISCOVER FOR YOURSELF DESCRIPTION"
        theme="light"
        value={discoverForYourselfDescription}
        height="h-[80px]"
        size="text-lg"
        onChange={(text) =>
          dispatch(
            updateUserGroup({
              key: "discoverForYourselfDescription",
              value: text,
            })
          )
        }
      />
      <MatesUploadInput
        title="HERO VIDEO THUMBNAIL"
        theme="light"
        type="image"
        source={thumbnail}
        onUpload={(image) =>
          dispatch(updateUserGroup({ key: "thumbnail", value: image ?? "" }))
        }
        folder={"video_thumbnails"}
        rootFolder={"images"}
      />
      <MatesLinkInput
        title="VIDEO LINK"
        theme="light"
        source={videoLink}
        placeholder="Paste link here"
        onChange={(link) =>
          dispatch(updateUserGroup({ key: "videoLink", value: link }))
        }
      />
      <div className="pt-4">
        <MatesTextArea
          label="DISCOVER RESOURCES DESCRIPTION"
          theme="light"
          value={discoverResourcesDescription}
          height="h-[80px]"
          size="text-lg"
          onChange={(text) =>
            dispatch(
              updateUserGroup({
                key: "discoverResourcesDescription",
                value: text,
              })
            )
          }
        />
      </div>
      <MatesButton
        text="Save"
        className={`base-button
            ${thumbnail ? "active-button" : "disabled-light-button"}`}
        disabled={isLoading || !thumbnail}
        isLoading={isLoading}
        onSubmit={updateUserGroupInfo}
      />
    </div>
  );
};

export default EditUserGroupModal;
