import { RFC } from '../../../types/propTypes'
import { ErrorIcon } from '../../../assets/icons/icons'

type MatesTextInputProps = {
    name:         string
    label:        string
    theme:        'light' | 'dark'
    value?:       string
    isTouched?:   boolean
    error?:       string | null
    className?:   string
    onChange:     (text: string) => void
}

const MatesTextInput:RFC<MatesTextInputProps> = ({
    name,
    label,
    theme,
    value,
    isTouched,
    error,
    className,
    onChange
}) => {
    return (
        <>
            <div
                className={`relative w-full mt-6 mb-2 border-solid border-b-[2px]
                ${(isTouched && error) 
                    ? 'border-MatesRed'
                    : (theme === 'light')
                        ? 'border-MatesBlack/60'
                        : 'border-MatesWhite'
                } ${className}`}
            >
                <input 
                    name={name}
                    type={'text'}
                    value={value ?? ''}
                    required={true}
                    onChange={(e) => onChange(e.target.value)}
                    className={`w-full outline-none text-xl 
                    peer h-[40px] translate-y-0 font-condensed
                    ${theme === 'light'
                    ? 'text-MatesBlack/80'
                    : 'text-MatesWhite bg-MatesLightBlack'
                    }`}
                />
                <span
                    className={`absolute text-[1.4em] left-0 font-medium font-compressed
                    transition-all duration-300 pointer-events-none ease-in-out tracking-wide
                    peer-focus:translate-x-0 peer-focus:-translate-y-6 peer-focus:text-[0.9em]
                    peer-valid:translate-x-0 peer-valid:-translate-y-6 peer-valid:text-[0.9em]
                    peer-focus:py-3 peer-focus:tracking-wider 
                    peer-valid:py-3 peer-valid:tracking-wider
                    peer-focus:text-MatesRed peer-valid:text-MatesRed
                    ${theme === 'light'
                    ? 'text-MatesBlack'
                    : 'text-MatesWhite'
                    }`}
                >
                    {label}
                </span>
            </div>
            {(isTouched && error) && (
                <div 
                    className='flex items-center text-MatesRed font-medium'
                    role='alert'
                >
                    <ErrorIcon className='mr-2'  />
                    {error}
                </div>
            )}
        </>
    )
}

export default MatesTextInput