import { RFC } from "../../../types/propTypes";
import { useState } from "react";
import { MatesAsyncVideo } from "../atoms";

type ChallengeSampleCardProps = {
  source: string;
};

const ChallengeSampleCard: RFC<ChallengeSampleCardProps> = ({ source }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoError, setIsVideoError] = useState(false);

  return (
    <div
      className="base-tiktok-container aspect-tiktok mt-3 overflow-hidden"
      onClick={() => setIsPlaying(!isPlaying)}
    >
      <MatesAsyncVideo
        fileName={source}
        isVideoError={isVideoError}
        setIsVideoError={setIsVideoError}
      />
    </div>
  );
};

export default ChallengeSampleCard;
