const ArrowLeft = ({ height, width, color, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width ?? "25.296"}
                height={height ?? "25.296"}
                viewBox="0 0 25.296 25.296"
                onClick={onClick}
            >
                <path
                    id="arrow_back_FILL0_wght400_GRAD0_opsz48_2_"
                    data-name="arrow_back_FILL0_wght400_GRAD0_opsz48 (2)"
                    d="M20.648,33.3,8,20.648,20.648,8l1.66,1.66-9.8,9.8H33.3v2.371H12.506l9.8,9.8Z"
                    transform="translate(-8 -8)"
                    fill="#fff"
                />
            </svg>
        </div>
    )
}

export default ArrowLeft