import { SummaryData } from "../../../types/serviceTypes";

export interface WeeklyFeedbackSummaryProps {
  summaries: SummaryData[];
}

const labels: Record<string, string> = {
  AFTER_HOURS: "AFTER HOURS",
  ON_SITE: "ON SITE",
  DRUG_ALCOHOL_USE: "DRUG ALCOHOL USE",
  SMOKO: "SMOKO",
  TOOLBOX_TALK: "TOOLBOX TALK",
  NOTHING_TO_REPORT: "NOTHING TO REPORT!",
  FINANCIAL: "FINANCIAL",
  MENTAL_WELLBEING: "MENTAL WELLBEING",
  PHYSICAL_WELLBEING: "PHYSICAL WELLBEING",
  RELATIONSHIPS: "RELATIONSHIPS",
  "1-3": "1-3",
  "4+": "4+",
  NONE: "NONE",
};

const WeeklyFeedbackSummary = ({ summaries }: WeeklyFeedbackSummaryProps) => {
  return (
    <div
      className={`p-3 mb-3 text-MatesWhite rounded-[3px] text-lg w-full 
      shadow-sm divide-y hover-not bg-MatesOffGrey border-MatesWhite 
      border-[1px] border-solid`}
    >
      {summaries.map((summary, index) => (
        <div key={index} className="flex items-center justify-between pt-[2px]">
          <p className="text-MatesWhite font-condensed text-xl">
            {labels[summary.name]}
          </p>
          <p className="text-xl font-condensed pr-3">{`${summary.count}`}</p>
        </div>
      ))}
    </div>
  );
};
export default WeeklyFeedbackSummary;
