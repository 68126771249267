import { useMatesDispatch } from "../../../app/hooks";
import { CloseIcon } from "../../../assets/icons/icons";
import { closeModal } from "../../../features/cores/modalSlice";
import { Summary } from "../../../types/serviceTypes";
import {  User } from "../../../types/stateTypes";
import { MatesTitleBlock } from "../../atoms/atoms";

export interface ConnectorAsistDataModalProps {
  count?: number;
  users?: User[];
  connectorAsistData?: Summary[];
}

function ConnectorAsistDataModal({ count, users }: ConnectorAsistDataModalProps) {
  const dispatch = useMatesDispatch();

  return (
    <div className="h-fit w-[500px] px-6 py-3">
      <div className="flex flex-row">
        <div>
          <MatesTitleBlock
            title="CONNECTOR / ASIST VOLUNTEERS"
            className="pb-3 text-MatesWhite"
          />
        </div>
        <CloseIcon
          className="cursor-pointer text-MatesLightWhite"
          onClick={() => dispatch(closeModal())}
        />
      </div>
      <p className="text-MatesGrey text-[15px] ml-3 font-condensed mb-3">{`${count} users`}</p>
      <div className="border-b border-MatesBorder" />

      <div className="mt-4">
        <p className="text-MatesRed font-condensed text-lg">
          DID YOU HAVE POSITIVE TALKS? IF SO, WHERE?
        </p>
        <table className="w-full">
          <tbody>
            <tr className="odd:bg-MatesOffGrey odd:text-MatesWhite even:bg-MatesLightBlack even:text-MatesWhite p-4 text-xl font-condensed w-full">
              <td>{"BARBEQUE"}</td>
              <td>{"202"}</td>
            </tr>
            <tr className="odd:bg-MatesOffGrey odd:text-MatesWhite even:bg-MatesLightBlack even:text-MatesWhite p-4 text-xl font-condensed w-full">
              <td>{"SMOKO"}</td>
              <td>{"202"}</td>
            </tr>
            <tr className="odd:bg-MatesOffGrey odd:text-MatesWhite even:bg-MatesLightBlack even:text-MatesWhite p-4 text-xl font-condensed w-full">
              <td>{"TOOL TALK"}</td>
              <td>{"202"}</td>
            </tr>
            <tr className="odd:bg-MatesOffGrey odd:text-MatesWhite even:bg-MatesLightBlack even:text-MatesWhite p-4 text-xl font-condensed w-full">
              <td>{"ON SITE"}</td>
              <td>{"202"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ConnectorAsistDataModal;
