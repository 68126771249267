import React from "react";
import { Challenge } from "../../../types/stateTypes";
import MatesFlexiButton from "../../atoms/buttons/MatesFlexiButton";
import moment from "moment";

type Props = {
  step: string;
  title: string;
  active?: boolean;
  subtitle: string;
  challenge: Challenge | undefined;
  stepType?: "CURRENT" | "VOTING";
  className?: string;
  onNewChallenge?: () => void;
  onViewChallenge: (challenge: Challenge) => void;
};

const ChallengeStep = ({ onNewChallenge, onViewChallenge, step, stepType, title, subtitle, challenge, className = "" }: Props) => {
  const renderCardDate = () => {
    if (!challenge) return;
    if (stepType === "CURRENT")
      return (
        <>
          Close submissions on
          <span className="font-bold ml-1">{moment(challenge.submissionCloseDate).format("D MMMM YYYY")}</span>
        </>
      );
    if (stepType === "VOTING")
      return (
        <>
          Close voting on
          <span className="font-bold ml-1">{moment(challenge.winnerAnnouncementDate).format("D MMMM YYYY")}</span>
        </>
      );
  };

  const renderStatus = () => {
    if (!challenge) return;
    const color = !challenge.completedDate ? "text-MatesGreen" : "text-MatesRed";
    const text = !challenge.completedDate ? "Active" : "Not yet active";
    return <p className={`${color} text-lg font-normal font-condensed leading-[26px]`}>{text}</p>;
  };

  const renderCard = () => {
    if (challenge)
      return (
        <div className="card flex flex-col gap-2 p-3 bg-white bg-opacity-10 rounded-[5px]">
          {/* Todo: figure out if active comes from backend */}
          {renderStatus()}
          <p className="text-lg font-normal font-condensed leading-6">{renderCardDate()}</p>
          <h3 className="text-[32px] font-bold uppercase leading-[25px]">{challenge.title}</h3>
          <h4 className="text-[22px] font-bold uppercase leading-[25px] text-MatesRed">{challenge.hashtag}</h4>
        </div>
      );
  };
  const renderButton = () => {
    if (challenge)
      return (
        <MatesFlexiButton
          text="View Challenge"
          onSubmit={() => {
            onViewChallenge(challenge);
          }}
          className="red-button w-full "
        />
      );
  };
  const renderNewChallengeButton = () => {
    if (!challenge && onNewChallenge)
      return <MatesFlexiButton text="Start New Challenge" onSubmit={() => onNewChallenge()} className="red-button w-full " />;
  };
  return (
    <div className={`tracking-normal ${className}`}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h2 className="text-MatesRed text-2xl font-bold uppercase leading-[25px]">{step}</h2>
          <h2 className="text-[40px] font-bold uppercase leading-10">{title}</h2>
          <p className="opacity-80 text-lg font-normal font-condensed leading-6">{subtitle}</p>
        </div>
        <div className="flex flex-col gap-2 max-w-[300px]">
          {renderCard()}
          {renderButton()}
          {renderNewChallengeButton()}
        </div>
      </div>
    </div>
  );
};

export default ChallengeStep;
