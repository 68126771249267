import { fetchBaseQuery, retry, createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { MATES_ADMIN_TOKEN } from '../assets/data/constants'

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers) => {
		const token = localStorage.getItem(MATES_ADMIN_TOKEN)
		if (token) {
			headers.set('Authorization', `Bearer ${token}`)
		}
		return headers
	},
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions)
	if (result.error && result.error.status === 401) {
		localStorage.removeItem(MATES_ADMIN_TOKEN)
		window.location.reload()
	}
	return result
}

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 2 })

export const MatesApi = createApi({
	reducerPath: 'MatesApi',
	baseQuery: baseQueryWithRetry,
	tagTypes: ['UserGroup', 'Category', 'Resource', 'ResourceResult', 'User', 'UserResult', 'ResourceSurveyResult', 'Challenge'],
	endpoints: () => ({}),
})
