import { FormikType, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { MatesButton, MatesHorizontalLine, MatesLinkInput, MatesTextInput, MatesUploadInput } from '../../atoms/atoms'

const VideoField: RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
	const { title, slug } = formik.values
	const { thumbnail, url } = formik.values.video
	let isValid = title && url

	return (
		<div>
			<MatesTextInput name='videoTitle' label='TITLE' theme='light' value={title} onChange={(text) => formik.setFieldValue('title', text)} />
			<MatesTextInput name='slug' label='SLUG' theme='light' value={slug} onChange={(text) => formik.setFieldValue('slug', text)} />
			<MatesUploadInput
				title='VIDEO THUMBNAIL'
				theme='light'
				type='image'
				source={thumbnail}
				onUpload={(image) => formik.setFieldValue('video.thumbnail', image)}
				folder={'video_thumbnails'}
				rootFolder={'images'}
			/>
			<MatesHorizontalLine />
			<MatesLinkInput
				title='PASTE LINK'
				theme='light'
				source={url}
				placeholder='Paste link here'
				onChange={(link) => formik.setFieldValue('video.url', link)}
			/>
			<MatesButton
				text='PUBLISH RESOURCE'
				className={`base-button mt-7
        ${isValid ? 'active-button' : 'disabled-light-button'}`}
				disabled={!isValid}
				onSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
			/>
		</div>
	)
}

export default VideoField
