import { useEffect, useRef, useState } from "react";
import { Resource } from "../../../types/stateTypes";
import { RFC } from "../../../types/propTypes";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import {
  resourceIdSelector,
  storeResource,
} from "../../../features/resources/Resource/resourceSlice";
import { PodcastIcon } from "../../../assets/icons/icons";
import { cardDateFormatter, formatDuration } from "../../../utils/formatter";
import { AudioPlayer, TitleCard } from "../atoms";

type PodcastCardProps = {
  resource: Resource;
};

const PodcastCard: RFC<PodcastCardProps> = ({ resource }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const { id, title, podcast, updatedAt, isPinned } = resource;
  const resourceId = useMatesSelector(resourceIdSelector);
  const dispatch = useMatesDispatch();

  return (
    <div className="relative w-full h-full">
      <TitleCard
        isSelected={resourceId === id}
        isAnimationOn={true}
        onClick={() => dispatch(storeResource(resource))}
      >
        <div className="flex flex-col justify-between">
          <div>
            <div className="flex items-center">
              <PodcastIcon
                className="mr-3 hover:scale-110 transition-all duration-200"
                onClick={() => setIsPlaying(!isPlaying)}
              />
              <p className="uppercase text-[20px] line-clamp-2 font-condensed font-medium">
                {title}
              </p>
            </div>
            <div className="flex items-center text-MatesWhite/60 text-base font-condensed py-1">
              <p>{cardDateFormatter(updatedAt)}</p>
              <span className="mx-2">●</span>
              <p>{formatDuration(duration)}</p>
            </div>
            <div className="text-MatesWhite/60 font-condensed leading-5">
              {podcast?.description}
            </div>

            <AudioPlayer
              audioURL={podcast?.file ?? ""}
              showPlayerControls={isPlaying && resourceId === id}
              onLoadedData={(duration) => setDuration(duration)}
            />
          </div>
          {isPinned && (
            <div
              className={`absolute right-0 top-2 bottom-2 w-[8px] rounded-r-[3px]
              ${resourceId === id ? "bg-MatesWhite" : "bg-MatesRed"}`}
            ></div>
          )}
        </div>
      </TitleCard>
    </div>
  );
};

export default PodcastCard;
