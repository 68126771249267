import React from 'react'

const NotFound = () => {
    return (
        <div 
            className='w-screen h-screen bg-MatesBlack
            overflow-hidden flex items-center justify-center
            text-MatesWhite'
        >
            <p className='text-[3em] mr-5'>
                404 
            </p>
            <p className='text-[1.3em]'>
                This page could not be found.
            </p>
        </div>
    )
}

export default NotFound