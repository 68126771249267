import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialCategoriesState } from '../../assets/data/InitialState'
import { Category } from '../../types/stateTypes'

export type CategoriesSliceType = {
    category:   Category
    categories: Category[]
}

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: InitialCategoriesState,
    reducers: {
        clearCategory: (state) => {
            state.category = {
                id:                    '',
                userGroupId:           '',
                title:                 '',
                sequence:              0,
                thumbnail:             '',
                description:           '',
                isHidden:              false,
                isAvailableToEveryone: false,
                isTrainingModule:      false,
                createdAt:             '',
                updatedAt:             '',
            }
        },
        selectCategory: (state, action:PayloadAction<Category>) => {
            state.category = action.payload
        },
        storeCategories: (state, action:PayloadAction<Category[]>) => {
            state.categories = action.payload
        },
        updateCategoryInfo: (state, action:PayloadAction<Category>) => {
            const updatedCategories = state.categories.map(category => {
                if (category.id === action.payload.id)
                    return action.payload
                else 
                    return category
            })
            state.categories = updatedCategories
        },
    }
})

export const categoryIdSelector     = (state: RootState) => state.categories.category.id
export const categorySelector       = (state: RootState) => state.categories.category
export const categoriesSelector     = (state: RootState) => state.categories.categories
export const categoryLengthSelector = (state: RootState) => state.categories.categories.length 
export const {
    clearCategory,
    selectCategory,
    storeCategories,
    updateCategoryInfo,
} = categoriesSlice.actions
export default categoriesSlice.reducer