import { RFC } from '../../types/propTypes'
import { useState } from 'react'
import { UserGroup } from '../../types/stateTypes'
import { openModal } from '../../features/cores/modalSlice'
import { MODAL_TYPES } from '../../assets/data/enums'
import { MatesLoading } from '../atoms/atoms'
import { clearCategory } from '../../features/resources/categoriesSlice'
import { clearResource } from '../../features/resources/Resource/resourceSlice'
import { EditIcon, UploadIcon } from '../../assets/icons/icons'
import { RxCross2 as CrossIcon } from 'react-icons/rx'
import { useMatesDispatch, useMatesSelector } from '../../app/hooks'
import { selectUserGroup, userGroupSelector } from '../../features/resources/userGroupsSlice'

type ResourceSubNavbarProps = {
	subTabs?: UserGroup[]
	isLoading?: boolean
}

const ResourceSubNavbar: RFC<ResourceSubNavbarProps> = ({ subTabs, isLoading }) => {
	const [isEditable, setIsEditable] = useState(false)
	const userGroupState = useMatesSelector(userGroupSelector)
	const dispatch = useMatesDispatch()

	const openUserGroupModal = (userGroup: UserGroup) => {
		dispatch(
			openModal({
				modalType: MODAL_TYPES.EDIT_USER_GROUP,
			})
		)
		dispatch(selectUserGroup(userGroup))
	}

	const openDuplicateResourceModal = () => {
		dispatch(
			openModal({
				modalType: MODAL_TYPES.DUPLICATE_RESOURCE,
			})
		)
	}

	const clickUserGroup = (userGroup: UserGroup) => {
		dispatch(clearCategory())
		dispatch(clearResource())
		dispatch(selectUserGroup(userGroup))
	}

	const renderSubNavbar = () => {
		if (!subTabs || !subTabs.length || isLoading === undefined) return null
		return subTabs.map((userGroup, index) => (
			<div key={index}>
				{isLoading ? (
					<div key={index} className='title-button'>
						<MatesLoading shape='circle' />
					</div>
				) : (
					<div
						key={index}
						className={`title-button text-lg text-MatesWhite 
            ${isEditable && 'shadow-edit'}
            ${userGroup.id === userGroupState.id ? 'bg-MatesRed hover:bg-MatesRed' : 'bg-MatesDarkGrey hover:bg-MatesBorder'}`}
						onClick={() => {
							isEditable ? openUserGroupModal(userGroup) : clickUserGroup(userGroup)
						}}
					>
						{userGroup.title}
					</div>
				)}
			</div>
		))
	}

	return (
		<div className='w-full h-max flex justify-center flex-wrap my-3 gap-3'>
			<div
				className={`title-button text-xl text-MatesRed bg-MatesWhite`}
				onClick={() =>
					dispatch(
						openModal({
							modalType: MODAL_TYPES.CREATE_RESOURCE,
						})
					)
				}
			>
				<UploadIcon className='mr-2 pb-1' />
				UPLOAD
			</div>
			{renderSubNavbar()}
			{isEditable ? (
				<div className='close-icon mx-2' onClick={() => setIsEditable(false)}>
					<CrossIcon color='#ED1941' size={'30px'} />
				</div>
			) : (
				<div className='card-edit-icon mx-2 p-2' onClick={() => setIsEditable(true)}>
					<EditIcon />
				</div>
			)}

			<div
				className={`title-button text-lg text-MatesWhite bg-none border-white border hover:bg-MatesBorder`}
				onClick={openDuplicateResourceModal}
			>
				DUPLICATE RESOURCES
			</div>
		</div>
	)
}

export default ResourceSubNavbar
