import { MatesApi } from "../app/api";
import { FileDownloadArgs, FileDownloadData, FileResizeDownloadArgs, FileUploadArgs, FileUploadData } from "../types/serviceTypes";

export const fileApi = MatesApi.injectEndpoints({
    endpoints: (builder) => ({
        getPresignedUploadUrl: builder.query<FileUploadData, FileUploadArgs>({
            query: ({ fileName, folder, rootFolder }) => ({
                url: `/files/upload/${rootFolder}?fileName=${fileName}&folder=${folder}`,
            })
        }),
        getPresignedDownloadUrl: builder.query<FileDownloadData, FileDownloadArgs>({
            query: ({ fileName }) => ({
                url: `/files/download/`,
                params: { fileName }
            })
        }),
        getPresignedResizeDownloadUrl: builder.query<FileDownloadData, FileResizeDownloadArgs>({
            query: ({ fileName, width, height }) => ({
                url: '/files/download/resize',
                params: { fileName, width, height }
            })
        }),
    })
})

export const {
    useLazyGetPresignedUploadUrlQuery,
    useGetPresignedDownloadUrlQuery,
    useLazyGetPresignedDownloadUrlQuery,
    useGetPresignedResizeDownloadUrlQuery,
    useLazyGetPresignedResizeDownloadUrlQuery,
} = fileApi