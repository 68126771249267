const editIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "18.454"} 
                height={height ?? "18.454"} 
                viewBox="0 0 18.454 18.454"
                onClick={onClick}
            >
                <path 
                    id="edit_FILL0_wght400_GRAD0_opsz48_4_" 
                    data-name="edit_FILL0_wght400_GRAD0_opsz48 (4)" 
                    d="M7.511,22.294H8.618L19.772,11.141l-1.108-1.108L7.511,21.186ZM22.969,10.058,19.746,6.835,20.8,5.778a1.52,1.52,0,0,1,2.115,0l1.108,1.108a1.52,1.52,0,0,1,0,2.115Zm-1.057,1.057L9.223,23.8H6V20.582L18.689,7.893Zm-2.694-.529-.554-.554,1.108,1.108Z" 
                    transform="translate(-6 -5.35)" 
                    fill="#fff" 
                />
            </svg>
        </div>
    )
}

export default editIcon