import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { InitialSurveyState } from '../../assets/data/InitialState';
import { SurveyTabKeys } from '../../components/templates/SurveySubNavbar';
import { ResourceSurveyResults, ResourcesSurveyResultsData, ResourceSurveyPaginationPayload, UserGroupName } from '../../types/serviceTypes';
import { ResourceTabList } from '../../types/propTypes';

export type ResourceResultsSurveyType = {
    searchTypes: SearchTypes
    resourceResultsCount: number
    resourceResults: ResourceSurveyResults[];
    resourceCommentCount: UpdateCommentCountArgs
    hubCommentCount: UpdateCommentCountArgs
}

type SearchTypes = {
    surveyType: SurveyTabKeys
    userGroup: UserGroupName | null
    resourceType: ResourceTabList
}

type UpdateResouceResultsArgs = {
    count: number
    resources: ResourceSurveyResults[];
}

type UpdateCommentCountArgs = {
    userCount: string | number
    guestCount: string | number
}

export type InfiniteSliceType = {
    count?: number;
    resourceResults: ResourceSurveyResults[];
    resourcePaginationPayload?: ResourceSurveyPaginationPayload;
}

export const surveySlice = createSlice({
    name: 'resourceResultsSurvey',
    initialState: InitialSurveyState,
    reducers: {
        selectSurveyType: (state, action:PayloadAction<SurveyTabKeys>) => {
            state.searchTypes.surveyType = action.payload
        },
        selectUserGroup: (state, action:PayloadAction<UserGroupName>) => {
            state.searchTypes.userGroup = action.payload
        },
        selectResourceType: (state, action:PayloadAction<ResourceTabList>) => {
            state.searchTypes.resourceType = action.payload
        },
        setResourceSurveyResults: (state, action: PayloadAction<ResourcesSurveyResultsData>) => {
            state.resourceResultsCount = action.payload.count
            state.resourceResults = action.payload.resources
        },
        clearResourceSurveyResults: (state) => {
            state.resourceResultsCount = 0
            state.resourceResults = []
        },
        initializeResourceSurveyResults: (state, action:PayloadAction<ResourceSurveyResults[]>) => {
            state.resourceResultsCount = 0
            state.resourceResults = action.payload
        },
        setResourcesSurveyResults: (state, action:PayloadAction<UpdateResouceResultsArgs>) => {
            const { resourceResults: storeResourceSurveyResults } = state
            const { resources: newResourceSurveys} = action.payload
            const resourceId = storeResourceSurveyResults.map(resource => resource.id)
            const filteredDuplicates = newResourceSurveys.filter(resource => !resourceId.includes(resource.id))
            filteredDuplicates.map(resource => state.resourceResults.push(resource))
            state.resourceResultsCount = action.payload.count
        },
        updateResourceCommentCount: (state, action:PayloadAction<UpdateCommentCountArgs>) => {
            state.resourceCommentCount.userCount = action.payload.userCount
            state.resourceCommentCount.guestCount = action.payload.guestCount
        },
        updateHubCommentCount: (state, action:PayloadAction<UpdateCommentCountArgs>) => {
            state.hubCommentCount.userCount = action.payload.userCount
            state.hubCommentCount.guestCount = action.payload.guestCount
        },
    }
})

export const searchTypesSelector = (state: RootState) => state.survey.searchTypes
export const resourceResultsSelector = (state: RootState) => state.survey.resourceResults
export const resourceCommentCountSelector = (state: RootState) => state.survey.resourceCommentCount
export const hubFeedbackCommentCountSelector = (state: RootState) => state.survey.hubCommentCount
export const {
    selectSurveyType,
    selectUserGroup,
    selectResourceType,
    clearResourceSurveyResults,
    initializeResourceSurveyResults,
    setResourcesSurveyResults,
    updateResourceCommentCount,
    updateHubCommentCount,
} = surveySlice.actions

export default surveySlice.reducer