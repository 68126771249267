import { useMatesDispatch, useMatesSelector } from '../../app/hooks'
import { useGetUserGroupsQuery } from '../../services/UserGroupService'
import { DashboardLayout } from '../../components/templates/templates'
import { ErrorPage } from '../pages'
import { UserDetails } from '../../components/organisms/organisms'
import { FeedbackDataButton, MatesSplashScreen } from '../../components/atoms/atoms'
import { EmptyDetailBlock, InfiniteUserList } from '../../components/molecules/molecules'
import { userGroupSelector, userIdSelector, userSelector } from '../../features/users/userSlice'
import { InstallIcon } from '../../assets/icons/icons'
import { MODAL_TYPES } from '../../assets/data/enums'
import { openModal } from '../../features/cores/modalSlice'
import { twMerge } from 'tailwind-merge'

const Users = () => {
	const userId = useMatesSelector(userIdSelector)
	const userGroup = useMatesSelector(userGroupSelector)
	const { firstName, lastName } = useMatesSelector(userSelector)
	const dispatch = useMatesDispatch()

	const { data, isLoading, isError } = useGetUserGroupsQuery()

	const renderUserDetails = () => {
		return (
			<div className='w-full h-[calc(100vh-11em)] grid grid-cols-user3cols'>
				{userGroup === 'CONNECTOR / ASIST VOLUNTEERS' ? <FeedbackDataButton /> : <div></div>}
				<InfiniteUserList />
				{!userId ? <EmptyDetailBlock detailText={'Select User to see more details'} /> : <UserDetails userId={userId} />}
			</div>
		)
	}

	if (isLoading) return <MatesSplashScreen />
	if (!data || isError) return <ErrorPage />

	return (
		<DashboardLayout page='USERS' userGroups={data} isLoading={isLoading}>
			<>
				<div className='w-[85%] mx-auto flex items-center justify-between'>
					<div />
					<div className='w-[83%] mx-auto flex items-center justify-between '>
						<p className={twMerge('title-page', 'mx-0')}>USERS</p>
						<div
							className='flex items-center gap-x-2 border-solid 
              border-[1px] border-MatesWhite/20 px-2 rounded-sm
              transition-all duration-300 hover:bg-MatesWhite/30
              cursor-pointer'
							onClick={() =>
								dispatch(
									openModal({
										modalType: MODAL_TYPES.DOWNLOAD_USER_RESULTS,
									})
								)
							}
						>
							<p className='text-MatesWhite font-condensed text-lg pt-1'>Download All Users</p>
							<InstallIcon />
						</div>
					</div>
					{firstName && lastName && <p className='px-4'>{`${firstName}  ${lastName}`.toUpperCase()}</p>}
				</div>
				{renderUserDetails()}
			</>
		</DashboardLayout>
	)
}

export default Users
