import { AnimatePresence } from "framer-motion";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import {
  clearResource,
  updateIsActiveSurvey,
  resourceIdSelector,
  resourceSelector,
} from "../../../features/resources/Resource/resourceSlice";
import {
  deleteResourceWithId,
  resourceTypeSelector,
  updateResourceInfo,
} from "../../../features/resources/Resource/resourcesSlice";
import {
  LinkDetail,
  PDFDetail,
  PodcastDetail,
  VideoDetail,
} from "../organisms";
import { motion } from "framer-motion";
import { detailAnimation } from "../../../assets/data/InitialState";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/cores/overlaySlice";
import asyncTimeout from "../../../utils/asyncTimeout";
import {
  useDeleteResourceMutation,
  useUpdateActiveSurveyMutation,
  useUpdateResourceMutation,
} from "../../../services/ResourceService";
import { formatResource } from "../../../utils/formatter";
import { closeModal, openModal } from "../../../features/cores/modalSlice";
import { MODAL_TYPES } from "../../../assets/data/enums";
import {  MatesToggleButton } from "../../atoms/atoms";
import { ErrorIcon } from "../../../assets/icons/icons";

const EditResource = () => {
  const resourceId = useMatesSelector(resourceIdSelector);
  const resourceState = useMatesSelector(resourceSelector);
  const resourceType = useMatesSelector(resourceTypeSelector);
  const dispatch = useMatesDispatch();

  const [updateResource] = useUpdateResourceMutation();
  const [deleteResource] = useDeleteResourceMutation();
  const [updateActiveSurvey] = useUpdateActiveSurveyMutation();

  const updateResourceContent = async () => {
    const formattedResource = formatResource(resourceState, resourceType);
    try {
      dispatch(openOverlay({ text: "Updating Resource " }));
      
      const [data] = await Promise.all([
        updateResource(formattedResource).unwrap(),
        asyncTimeout(2000),
      ]);

      if (!data) throw Error;
      dispatch(updateResourceInfo(data));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: `${resourceType} UPDATED`,
          body: "You have successfully updated a resource!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to update the resource",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const deleteResourceContent = async (id: string) => {
    try {
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Deleting this resource" }));
      const [data] = await Promise.all([
        deleteResource(id).unwrap(),
        asyncTimeout(2000),
      ]);

      if (!data) throw Error;
      dispatch(clearResource());
      dispatch(deleteResourceWithId(id));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "RESOURCE DELETED",
          body: "You have successfully deleted the resource!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to delete resource",
          body: "Something went wrong. Please try again later.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const renderResourceDetail = () => {
    switch (resourceType) {
      case "PODCASTS":
        return (
          <PodcastDetail
            resource={resourceState}
            onSubmit={updateResourceContent}
            onDelete={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DELETE,
                  itemId: resourceId,
                  deleteFn: deleteResourceContent,
                })
              )
            }
          />
        );
      case "VIDEOS":
        return (
          <VideoDetail
            resource={resourceState}
            onSubmit={updateResourceContent}
            onDelete={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DELETE,
                  itemId: resourceId,
                  deleteFn: deleteResourceContent,
                })
              )
            }
          />
        );
      case "PDFS":
        return (
          <PDFDetail
            resource={resourceState}
            onSubmit={updateResourceContent}
            onDelete={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DELETE,
                  itemId: resourceId,
                  deleteFn: deleteResourceContent,
                })
              )
            }
          />
        );
      case "LINKS":
        return (
          <LinkDetail
            resource={resourceState}
            onSubmit={updateResourceContent}
            onDelete={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DELETE,
                  itemId: resourceId,
                  deleteFn: deleteResourceContent,
                })
              )
            }
          />
        );
      default:
        return null;
    }
  };

  const renderResourceSurveys = () => {
    if (!resourceIdSelector)
      return (
        <div className="flex items-center justify-center mt-8">
          <ErrorIcon className="text-MatesRed mr-2" />
          <p className="text-lg text-MatesRed text-center font-condensed">
            Something went wrong.
          </p>
        </div>
      );

    return (
      <div className="mt-8 bg-MatesLightBlack p-4 font-condensed rounded-[5px]">
        <p className="text-MatesWhite font-medium text-xl mb-2 tracking-wide">
          RESOURCE SURVEY'S
        </p>
        <div className="flex items-center justify-between w-full">
          <p className="text-MatesWhite/60 mb-3  tracking-wide">
            Toggle on / off the survey questions for this resource. You will
            find the results in the survey's tab.
          </p>
        </div>
        <div className="flex items-start justify-between">
          <p className="text-base text-MatesWhite">Apply survey questions</p>
          <MatesToggleButton
            isActive={resourceState.isSurveyEnabled}
            onChangeStatus={() => { 
              updateActiveSurvey({id: resourceState.id, isSurveyEnabled: !resourceState.isSurveyEnabled });
              dispatch(updateIsActiveSurvey(!resourceState.isSurveyEnabled));
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="pt-3 h-[calc(100vh-11em)] overflow-x-hidden overflow-y-auto pb-[15em]">
      <div>
        <p className="text-MatesRed font-semibold text-2xl mb-2">
          EDIT RESOURCE
        </p>
        <p className="text-MatesWhite/60 mb-3 font-condensed tracking-wide">
          Edit the contents of the selected resource below.
        </p>
        <AnimatePresence>
          <motion.div
            key="resource-detail"
            {...detailAnimation}
            className="w-full bg-MatesLightBlack rounded-[5px] px-3 py-4"
          >
            {renderResourceDetail()}
          </motion.div>
        </AnimatePresence>
      </div>

      {renderResourceSurveys()}
    </div>
  );
};

export default EditResource;
