import { useEffect, useState } from "react";
import { ResourceTabList, ResourceType, RFC } from "../../../types/propTypes";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { useLazyGetResourceResultsQuery } from "../../../services/SurveyService";
import { PDFIcon, PodcastIcon } from "../../../assets/icons/icons";
import { cardDateFormatter } from "../../../utils/formatter";
import { UserGroupName } from "../../../types/serviceTypes";
import { CardLoading, MatesLoading } from "../../atoms/atoms";
import { ResourceSurveyResultAccordion } from "../molecules";
import {
  clearResourceSurveyResults,
  initializeResourceSurveyResults,
  resourceResultsSelector,
  setResourcesSurveyResults,
} from "../../../features/surveys/surveySlice";
import InfiniteScroll from "react-infinite-scroller";

type InfiniteResourceResultsListProps = {
  resourceType: ResourceTabList;
  userGroupFilter: UserGroupName | null;
};

const RESOURCE_TYPES = {
  PODCASTS: "PODCAST",
  VIDEOS: "VIDEO",
  PDFS: "PDF",
  LINKS: "LINK",
};

const InfiniteResourceResultsList: RFC<InfiniteResourceResultsListProps> = ({
  resourceType,
  userGroupFilter,
}) => {
  const take = 5;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const resourceResults = useMatesSelector(resourceResultsSelector);
  const dispatch = useMatesDispatch();

  const renderResourceElement = (resourceType: ResourceTabList) => {
    switch (resourceType) {
      case "PODCASTS":
        return <PodcastIcon />;
      case "VIDEOS":
        return null;
      case "PDFS":
        return <PDFIcon />;
      case "LINKS":
        return null;
      default:
        return null;
    }
  };

  const [getResourceResults, { isLoading }] = useLazyGetResourceResultsQuery();

  useEffect(() => {
    setPage(1);
    loadMore(take, 1);
  }, [resourceType, userGroupFilter]);

  const loadMore = async (take: number, page: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const { resources, count } = await getResourceResults({
        page,
        take,
        userGroup: userGroupFilter ?? undefined,
        resourceType: RESOURCE_TYPES[resourceType] as ResourceType,
      }).unwrap();

      if (!resources?.length && page === 1)
        dispatch(clearResourceSurveyResults());
      if (!resources || !resources.length) {
        setHasMore(false);
      } else {
        if (page === 1) {
          dispatch(initializeResourceSurveyResults(resources));
          setHasMore(true);
          setPage(2);
        } else if (resources.length < take) {
          dispatch(setResourcesSurveyResults({ resources, count }));
          setHasMore(false);
        } else {
          dispatch(setResourcesSurveyResults({ resources, count }));
          setHasMore(true);
          setPage((prev) => prev + 1);
        }
      }

      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    } catch (error: any) {
      console.log({ error: error.message });
    }
  };

  if (isLoading)
    return (
      <div className="w-full py-10">
        <MatesLoading shape="rounded" />
      </div>
    );

  if (!resourceResults.length)
    return (
      <div className="empty-list tracking-wider text-2xl h-full">
        No Results to display
      </div>
    );

  return (
    <div className="h-[calc(100vh-16em)] w-full overflow-y-auto">
      <InfiniteScroll
        loadMore={() => (!isLoadMore ? loadMore(take, page) : {})}
        hasMore={hasMore}
        useWindow={false}
        loader={<CardLoading shape="rounded" key={0} />}
        threshold={150}
      >
        <>
          {resourceResults.map((resource, index) => (
            <ResourceSurveyResultAccordion
              key={index}
              resourceResult={resource}
              resourceTitle={resource.title}
              element={renderResourceElement(resourceType)}
              createAt={cardDateFormatter(resource.createdAt)}
              commentsCount={resource.survey.commentCount}
              userGroupName={resource.userGroup}
              categoryName={resource.category}
              isActive={!!resource.isActive}
            />
          ))}
        </>
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteResourceResultsList;
