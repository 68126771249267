const ErrorPage = () => {
    return (
        <div
            className='w-screen h-screen bg-MatesBlack
            overflow-hidden flex items-center justify-center
            text-MatesWhite'
        >
            <p className='text-[3em] mr-5'>
                Error Page
            </p>
            <p className='text-[1.3em]'>
                Style me please
            </p>
        </div>
    )
}

export default ErrorPage