import { useMemo } from "react";
import { RFC } from "../../../types/propTypes";
import { convertDateStringToPretty } from "../../../utils/converter";

type MatesChallengeTextInputProps = {
  type: "title" | "hashtag" | "date";
  permissionType: "readonly" | "write";
  value: string;
  className: string;
  onChange: (text: string) => void;
  onBlur?: (text: string) => void;
};

const MatesChallengeTextInput: RFC<MatesChallengeTextInputProps> = ({
  type,
  permissionType,
  value,
  className,
  onChange,
  onBlur,
}) => {
  const staticText = useMemo(() => {
    if (type === "date") return convertDateStringToPretty(value);
    if (type !== "title" && !value) return "Empty";
    else return value;
  }, [type, value]);

  return (
    <div className="w-full">
      {permissionType === "write" ? (
        <input
          name={type}
          type={"text"}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
          onBlur={(e) => onBlur && onBlur(e.target.value)}
          className={`bg-MatesDarkGrey w-full outline-none border-solid tracking-wide
          border-[1px] border-MatesWhite rounded-[5px] px-2 ${className}`}
        />
      ) : (
        <div className={className}>{staticText}</div>
      )}
    </div>
  );
};

export default MatesChallengeTextInput;
