import * as Yup from 'yup'

export const LoginSchema = Yup.object({
    email: Yup.string().required('Email is required').email('Invalid email address'),
    password: Yup.string().required('Password is required')
})

const URL = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g

export const CreateResourceSchema = Yup.object().shape({
    openedTab: Yup.string(),
    title: Yup.string().required('Title is required'),
    category: Yup.string().required('Please select a category'),
    categoryId: Yup.string().required('Please select a category'),
    link: Yup.object().when('openedTab', {
        is: 'LINKS',
        then: Yup.object().shape({
            url: Yup.string().required('URL is required').matches(URL, 'Invalid URL'),
            description: Yup.string().required('Content Creator Link is required').matches(URL, 'Invalid URL')
        })
    }),
    pdf: Yup.object().when('openedTab', {
        is: 'PDFS',
        then: Yup.object().shape({
            description: Yup.string().required('Content Creator Link is required').matches(URL, 'Invalid URL')
        })
    }),
})

export const CreateCategorySchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    userGroupId: Yup.string().required(),
})