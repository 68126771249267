import { useMatesDispatch, useMatesSelector } from "../../app/hooks";
import {
  searchTypesSelector,
  selectSurveyType,
} from "../../features/surveys/surveySlice";

export const SurveyTabOptions = {
  RESOURCE: "RESOURCE RESULTS",
  HUB: "THE HUB RESULTS",
} as const;

export type SurveyTabKeys = keyof typeof SurveyTabOptions;
const tabOptions = Object.keys(SurveyTabOptions) as Array<SurveyTabKeys>;

const SurveySubNavbar = () => {
  const dispatch = useMatesDispatch();
  const { surveyType } = useMatesSelector(searchTypesSelector);

  return (
    <div className="w-full h-max flex justify-center flex-wrap my-3 gap-3">
      {tabOptions.map((value, index) => (
        <div
          key={index}
          className={`title-button text-lg text-MatesWhite
          ${
            surveyType === value
              ? "bg-MatesRed hover:bg-MatesRed"
              : "bg-MatesDarkGrey hover:bg-MatesBorder"
          }`}
          onClick={() => dispatch(selectSurveyType(value))}
        >
          {SurveyTabOptions[value]}
        </div>
      ))}
    </div>
  );
};

export default SurveySubNavbar;
