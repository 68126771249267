import { RFC } from '../../../types/propTypes'
import { ErrorIcon } from '../../../assets/icons/icons'
import { useRef } from 'react'
import useAutosizeTextArea from '../../../utils/useAutosizeTextArea'

type MatesTextAreaProps = {
    label:        string
    theme:        'light' | 'dark'
    value?:       string
    height?:      string
    size?:        string
    isTouched?:   boolean
    error?:       string | null
    onChange:     (text: string) => void
}

const MatesTextArea:RFC<MatesTextAreaProps> = ({
    label,
    theme,
    value,
    height,
    size,
    isTouched,
    error,
    onChange
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useAutosizeTextArea(textAreaRef.current, value ?? '')

    return (
        <>
            <div
                className={`relative w-full mt-6 mb-2 border-solid border-b-[2px]
                ${(isTouched && error)
                        ? 'border-MatesRed'
                        : (theme === 'light')
                            ? 'border-MatesBlack/60'
                            : 'border-MatesWhite'
                    }`}
            >
                <textarea
                    ref={textAreaRef}
                    rows={1}
                    value={value ?? ''}
                    required={true}
                    onChange={(e) => onChange(e.target.value)}
                    className={`w-full outline-none font-condensed
                    peer translate-y-0 max-h-[4em] leading-6
                    ${size ?? 'text-lg'}
                    ${height ?? 'h-[40px]'}
                    ${theme === 'light'
                        ? 'text-MatesBlack/80 bg-MatesWhite'
                        : 'text-MatesWhite bg-MatesLightBlack'
                    }`}
                />
                <span
                    className={`absolute text-[1.4em] left-0 font-medium
                    transition-all duration-300 pointer-events-none ease-in-out
                    peer-focus:translate-x-0 peer-focus:-translate-y-7 peer-focus:text-[0.9em]
                    peer-valid:translate-x-0 peer-valid:-translate-y-7 peer-valid:text-[0.9em]
                    peer-focus:py-3 peer-focus:tracking-wider 
                    peer-valid:py-3 peer-valid:tracking-wider
                    peer-focus:text-MatesRed peer-valid:text-MatesRed
                    ${theme === 'light'
                        ? 'text-MatesBlack'
                        : 'text-MatesWhite'
                    }`}
                >
                    {label}
                </span>
            </div>
            {(isTouched && error) && (
                <div
                    className='flex items-center text-MatesRed font-medium'
                    role='alert'
                >
                    <ErrorIcon className='mr-2' />
                    {error}
                </div>
            )}
        </>
    )
}

export default MatesTextArea