const logoutIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "21.482"} 
                height={height ?? "21.482" }
                viewBox="0 0 21.482 21.482"
            >
                <path 
                    id="logout_FILL0_wght400_GRAD0_opsz48_2_" 
                    data-name="logout_FILL0_wght400_GRAD0_opsz48 (2)" 
                    d="M7.79,27.482A1.836,1.836,0,0,1,6,25.692V7.79A1.836,1.836,0,0,1,7.79,6h8.682V7.79H7.79v17.9h8.682v1.79Zm14.5-5.52L21.008,20.68l3.043-3.043H13.608v-1.79H23.992L20.948,12.8l1.283-1.283,5.251,5.251Z" 
                    transform="translate(-6 -6)"
                />
            </svg>
        </div>
    )
}

export default logoutIcon