import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialAdminState } from '../../assets/data/InitialState'
import { Admin } from '../../types/stateTypes'
import { MATES_ADMIN_TOKEN } from '../../assets/data/constants';

export type AdminSliceType = {
    admin: Admin
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState: InitialAdminState,
    reducers: {
        clearAdmin: (state) => {
            localStorage.removeItem(MATES_ADMIN_TOKEN)
            state.admin = {
                name: '',
                email:  ''
            }
        },
        storeAdmin: (state, action:PayloadAction<AdminSliceType>) => {
            state.admin = action.payload.admin
        },
    }
})

export const adminSelector = (state: RootState) => state.admin.admin
export const { clearAdmin, storeAdmin } = adminSlice.actions
export default adminSlice.reducer