import { RFC } from "../../../types/propTypes";
import ReactPlayer from "react-player";

type VideoPlayerProps = {
  videoURL: string;
  isPlaying: boolean;
  endedVideo: () => void;
};

const VideoPlayer: RFC<VideoPlayerProps> = ({
  videoURL,
  isPlaying,
  endedVideo,
}) => {
  return (
    <ReactPlayer
      url={videoURL}
      width="100%"
      height="100%"
      controls={true}
      playing={isPlaying}
      onEnded={endedVideo}
    />
  );
};

export default VideoPlayer;
