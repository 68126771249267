export enum SNACKBAR_TYPES {
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
	SUCCESS = 'success',
}

export enum SNACKBAR_BUTTON_TYPES {
	CLOSE = 'CLOSE',
	RETRY = 'RETRY',
	FIX = 'FIX',
	DISMISS = 'DISMISS',
	UNDO = 'UNDO',
	RESEND = 'RESEND',
}

export enum ERROR_MESSAGES {
	LOGIN = 'Failed to Login. Please check your details and try again.',
	MESSAGE = 'Failed to send a message. Please check your network and try again.',
	DISMISS = 'Failed to dismiss this support ticket. Please try again later.',
	DELETE_POST = 'Failed to delete this post. Please try again later.',
	KEEP_POST = 'Failed to keep this post. Please try again later.',
	CONTACT = 'Failed to send a message. You cannot contact a banned user.',
	DUPLICATE_RESOURCE = 'There was a problem duplicating some resources.',
}

export enum SUCCESS_MESSAGES {
	NEW_CATEGORY = 'Category created successfully.',
	DUPLICATE_RESOURCE = 'Resources duplicated successfully.',
}

export enum MODAL_TYPES {
	CREATE_RESOURCE = 'CREATE_RESOURCE',
	CREATE_SURVEY = 'CREATE_SURVEY',
	CREATE_CATEGORY = 'CREATE_CATEGORY',
	EDIT_USER_GROUP = 'EDIT_USER_GROUP',
	EDIT_CATEGORY = 'EDIT_CATEGORY',
	TIKTOK_VIDEO = 'TIKTOK_VIDEO',
	SUCCESS = 'SUCCESS',
	FAIL = 'FAIL',
	DELETE = 'DELETE',
	ALL_FEED_BACK = 'ALL_FEED_BACK',
	DISPLAY_CONNECTORS = 'DISPLAY_CONNECTORS',
	DISPLAY_FEEDBACK_SURVEY_COMMENTS_RESULTS = 'DISPLAY__FEEDBACK_SURVEY_COMMENTS_RESULTS',
	DISPLAY_RESOURCE_RESULTS_COMMENTS = 'DISPLAY_RESOURCE_RESULTS_COMMENTS',
	DOWNLOAD_SURVEY_RESULTS = 'DOWNLOAD_SURVEY_RESULTS',
	DOWNLOAD_WELCOME_SURVEY_RESULTS = 'DOWNLOAD_WELCOME_SURVEY_RESULTS',
	DOWNLOAD_HUB_FEEDBACK_RESULTS = 'DOWNLOAD_HUB_FEEDBACK_RESULTS',
	DOWNLOAD_RESOURCE_RESULTS = 'DOWNLOAD_RESOURCE_RESULTS',
	DOWNLOAD_USER_RESULTS = 'DOWNLOAD_USER_RESULTS',
	DUPLICATE_RESOURCE = 'DUPLICATE_RESOURCE',
	CLOSE_SUBMISSION = 'CLOSE_SUBMISSION',
	ANNOUNCE_WINNER = 'ANNOUNCE_WINNER',
	PUBLISH_CHALLENGE = 'PUBLISH_CHALLENGE',
}

export enum FOLDER_TYPE {
	AVATAR = 'avatar',
	EXERCISE_VIDEO = 'exercise_video',
	PROGRAM_IMAGE = 'program_image',
	COURSE_IMAGE = 'course_image',
	LESSON_MEDIA = 'lesson_media',
}

export enum CREATE_RESOURCE_PAGES {
	SELECT_USER = 'SELECT_USER',
	SELECT_CATEGORY = 'SELECT_CATEGORY',
	SELECT_RESOURCE = 'SELECT_RESOURCE',
}

export enum SURVEY_SUBNAV_TYPES {
	RESOURCE_RESULTS = 'RESOURCE RESULTS',
	SURVEY_RESULTS = 'SURVEY RESULTS',
}

export enum WORK_ROLE {
	FIFO_DIDO_WORKERS = 'FIFO DIDO WORKERS',
	MANAGERS_AND_SUPERVISORS = 'MANAGERS AND SUPERVISORS',
	CONNECTOR_ASIST_VOLUNTEERS = 'CONNECTOR / ASIST VOLUNTEERS',
	FAMILY_AND_FRIENDS = 'FAMILY AND FRIENDS',
	MENTAL_HEALTH_SERVICE_PROVIDERS = 'MENTAL HEALTH SERVICE PROVIDERS',
	OTHER = 'OTHER',
}

export enum SIGNUP_REASON {
	WHAT_IT_IS_ALL_ABOUT = 'WHAT_IT_IS_ALL_ABOUT',
	MATE_RECOMMENDED = 'MATE_RECOMMENDED',
	PERSONAL_REASONS = 'PERSONAL_REASONS',
	WORK_REASONS = 'WORK_REASONS',
	LEARN_SUICIDE_PREVENTION = 'LEARN_SUICIDE_PREVENTION',
	UNDERSTAND_FIFO = 'UNDERSTAND_FIFO',
	STAFF_RECOMMENDED = 'STAFF_RECOMMENDED',
	CONNECTOR_ASIST_VOLUNTEER = 'CONNECTOR_ASIST_VOLUNTEER',
	PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}
