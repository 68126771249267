import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { InitialModalState } from '../../assets/data/InitialState'
import { Modal } from '../../types/propTypes'

type ModalSliceType = Omit<Modal, 'isDisplayed'>

export const modalSlice = createSlice({
	name: 'modal',
	initialState: InitialModalState,
	reducers: {
		openModal: (state, action: PayloadAction<ModalSliceType>) => {
			const { modalType, title, body, itemId, deleteFn, callbackFn } = action.payload
			state.isDisplayed = true
			state.modalType = modalType
			state.title = title
			state.body = body
			state.itemId = itemId
			state.deleteFn = deleteFn
			state.callbackFn = callbackFn
		},
		closeModal: (state) => {
			state.isDisplayed = false
		},
	},
})

export const modalSelector = (state: RootState) => state.modal
export const { openModal, closeModal } = modalSlice.actions
export default modalSlice.reducer
