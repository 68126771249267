import { useMatesDispatch } from "../../../app/hooks";
import { CloseIcon } from "../../../assets/icons/icons";
import { closeModal } from "../../../features/cores/modalSlice";
import { useGetTotalWeeklyFeedbackQuery } from "../../../services/UserService";
import { CardError, CardLogo, FeedbackCard } from "../../atoms/atoms";

const AllFeedbackDataModal = () => {
  const dispatch = useMatesDispatch();
  const {
    data: feedbackData,
    isLoading,
    isError,
  } = useGetTotalWeeklyFeedbackQuery();

  if (isLoading) return <CardLogo />;
  if (!feedbackData || isError) return <CardError />;

  const {
    positiveTalksLocationCountList,
    safePlanCreatedCountList,
    keyConcernCountList,
    peopleSupportedSum,
    callbackRequestSum,
  } = feedbackData.summary;

  const feedbackSummary = [
    {
      data: positiveTalksLocationCountList,
      title: "DID YOU HAVE POSITIVE TALKS? IF SO, WHERE?",
      color: 'text-MatesRed'
    },
    {
      data: peopleSupportedSum,
      title: "HOW MANY PEOPLE HAVE YOU HELPED OR SUPPORTED?",
      color: 'text-MatesRed'
    },
    {
      data: callbackRequestSum,
      title: "MATES TEAM MEMBER CALL BACK REQUEST",
      color: 'text-MatesRed'
    },
    {
      data: keyConcernCountList,
      title: "WHAT WERE THE KEY CONCERNS INDIVIDUALS RAISED?",
      color: 'text-MatesRed'
    },
    {
      data: safePlanCreatedCountList,
      title: "ANY SAFE PLANS CREATED?",
      color: 'text-MatesBlue'
    },
  ];

  return (
    <div className="w-[80vw] h-[80vh] overflow-hidden bg-MatesLightBlack px-16 py-7">
      <div className="flex items-center justify-between">
        <p className="text-5xl text-MatesWhite font-compressed font-bold tracking-wide">
          CONNECTOR / ASIST VOLUNTEERS
        </p>
        <CloseIcon
          className="mt-2 cursor-pointer"
          onClick={() => dispatch(closeModal())}
        />
      </div>
      <div className="text-MatesWhite/60 font-condensed text-lg">
        {feedbackData.userCount ?? 0} Users
      </div>
      <div className="h-[1px] bg-[#4A4A4A] my-3"></div>
      <div className="h-[calc(100%-5em)] w-full overflow-x-auto flex flex-col flex-wrap gap-x-4">
        {feedbackSummary.map((feedback, index) => (
          <FeedbackCard 
            key={index}
            data={feedback.data}
            title={feedback.title}
            color={feedback.color}
          />
        ))}
      </div>
    </div>
  );
};

export default AllFeedbackDataModal;
