const dotsIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "5"} 
                height={height ?? "23"} 
                viewBox="0 0 5 23"
                onClick={onClick}
            >
                <g id="Group_2123" data-name="Group 2123" transform="translate(-218 -305)">
                    <circle id="Ellipse_13" data-name="Ellipse 13" cx="2.5" cy="2.5" r="2.5" transform="translate(218 305)" fill="#fff" />
                    <circle id="Ellipse_14" data-name="Ellipse 14" cx="2.5" cy="2.5" r="2.5" transform="translate(218 314)" fill="#fff" />
                    <circle id="Ellipse_15" data-name="Ellipse 15" cx="2.5" cy="2.5" r="2.5" transform="translate(218 323)" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}

export default dotsIcon