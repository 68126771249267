import { useState } from 'react'
import { Resource } from '../../../types/stateTypes'
import { RFC } from '../../../types/propTypes'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { PlayVideoIcon } from '../../../assets/icons/icons'
import { resourceIdSelector, storeResource } from '../../../features/resources/Resource/resourceSlice'
import { MatesAsyncImage, VideoPlayer } from '../atoms'
import { TitleCard } from '../atoms'

type VideoCardProps = {
  resource: Resource
}

const VideoCard:RFC<VideoCardProps> = ({ resource }) => {
  const [ isPlaying, setIsPlaying ] = useState(false)
  const { id, title, video, isPinned } = resource
  const resourceId = useMatesSelector(resourceIdSelector)
  const dispatch = useMatesDispatch()

  return (
    <div className='relative w-full h-full'>
      <TitleCard
        isSelected={resourceId === id}
        isAnimationOn={true}
        onClick={() => dispatch(storeResource(resource))}
      >
        <div className='flex flex-col justify-between'>
          <div>
            <div className='relative w-full aspect-video'>
              {isPlaying ? (
                <VideoPlayer
                  videoURL={video?.url ?? ''}
                  isPlaying={isPlaying}
                  endedVideo={() => setIsPlaying(false)}
                />
              ) : (
              <>
                <PlayVideoIcon 
                  className='absolute z-10 top-1/2 left-1/2
                  -translate-x-1/2 -translate-y-1/2 hover:scale-105
                  transition-all duration-200'
                  onClick={() => setIsPlaying(true)}
                />
                <MatesAsyncImage fileName={video?.thumbnail}/>
              </>
              )}
            </div>
            <p 
              className='flex items-center justify-center w-full
              uppercase text-2xl line-clamp-2 pt-3 font-compressed tracking-wide'
            >
              {title}
            </p>
          </div>
          {isPinned && (
            <div
              className={`absolute right-0 top-2 bottom-2 w-[8px] rounded-r-[3px]
              ${resourceId === id ? 'bg-MatesWhite' : 'bg-MatesRed'}`}
            ></div>
          )}
        </div>
      </TitleCard>
    </div>
  )
}

export default VideoCard