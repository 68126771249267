const userIcon = ({
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "15.838"}
        height={height ?? "15.368"}
        viewBox="0 0 15.838 15.368"
      >
        <path
          id="person_FILL0_wght400_GRAD0_opsz48_2_"
          data-name="person_FILL0_wght400_GRAD0_opsz48 (2)"
          d="M15.919,16.374a3.56,3.56,0,0,1-3.712-3.712A3.56,3.56,0,0,1,15.919,8.95a3.56,3.56,0,0,1,3.712,3.712,3.56,3.56,0,0,1-3.712,3.712ZM8,24.318V21.992a2.723,2.723,0,0,1,.47-1.609,3.019,3.019,0,0,1,1.213-1.015,18.6,18.6,0,0,1,3.18-1.114,12.855,12.855,0,0,1,3.056-.371,12.314,12.314,0,0,1,3.044.384,21.08,21.08,0,0,1,3.168,1.1,2.984,2.984,0,0,1,1.237,1.015,2.723,2.723,0,0,1,.47,1.609v2.326Zm1.485-1.485H22.353v-.841a1.356,1.356,0,0,0-.235-.755,1.49,1.49,0,0,0-.582-.532,13.141,13.141,0,0,0-2.9-1.052,12.8,12.8,0,0,0-2.722-.285,13.152,13.152,0,0,0-2.747.285,12.569,12.569,0,0,0-2.9,1.052,1.489,1.489,0,0,0-.792,1.287Zm6.434-7.944a2.158,2.158,0,0,0,2.227-2.227,2.228,2.228,0,1,0-4.454,0,2.158,2.158,0,0,0,2.227,2.227ZM15.919,12.662ZM15.919,22.833Z"
          transform="translate(-8 -8.95)"
          fill="#00000"
        />
      </svg>
    </div>
  );
};
export default userIcon;