import { FormikType, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { MatesButton, MatesHorizontalLine, MatesTextInput, MatesUploadInput } from '../../atoms/atoms'

const PDFField: RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
	const { title, slug } = formik.values
	const { description, file } = formik.values.pdf
	let isValid = title && description && file

	return (
		<div>
			<MatesTextInput name='pdfTitle' label='TITLE' theme='light' value={title} onChange={(text) => formik.setFieldValue('title', text)} />
			<MatesTextInput name='slug' label='SLUG' theme='light' value={slug} onChange={(text) => formik.setFieldValue('slug', text)} />
			<MatesTextInput
				name='pdf-to-content'
				label='LINK TO CONTENT CREATOR'
				theme='light'
				value={description}
				onChange={(text) => formik.setFieldValue('pdf.description', text)}
				isTouched={formik.touched.pdf?.description}
				error={formik.errors.pdf?.description}
			/>
			<MatesHorizontalLine />
			<MatesUploadInput
				title='UPLOAD PDF'
				theme='light'
				type='file'
				source={file}
				onUpload={(file) => formik.setFieldValue('pdf.file', file)}
				folder={'pdfs'}
				rootFolder={'other'}
			/>
			<MatesButton
				text='PUBLISH RESOURCE'
				className={`base-button mt-7
        ${isValid ? 'active-button' : 'disabled-light-button'}`}
				disabled={!isValid}
				onSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
			/>
		</div>
	)
}

export default PDFField
