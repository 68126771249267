import { RFC } from '../../../types/propTypes'

type MatesTitleBlockProps = {
    title:       string
    description?: string
    className?:  string
}

const MatesTitleBlock:RFC<MatesTitleBlockProps> = ({
    title,
    description,
    className,
}) => {
  return (
    <div className={`w-full ${className}`}>
        <p className={`text-[3em] h-fit leading-none font-bold text-MatesDarkGrey` + className}>
            {title}
        </p>
          <p className={`text-[1.3em] leading-6 text-MatesDarkGrey/80` + className}>
            {description}
        </p>
    </div>
  )
}

export default MatesTitleBlock