import { useMatesDispatch } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { openModal } from "../../../features/cores/modalSlice";
import { ResourceSurveyResults } from "../../../types/serviceTypes";
import { MatesAccordion, MatesTable } from "../../atoms/atoms";
import {
  emojiResourceResults,
  ratingResourceResults,
} from "../../../assets/data/arrays";
import { updateResourceCommentCount } from "../../../features/surveys/surveySlice";

type ResourceSurveyResultAccordionProps = {
  resourceResult: ResourceSurveyResults;
  createAt?: string;
  resourceTitle: string;
  element?: React.ReactNode;
  commentsCount?: number;
  userGroupName: string;
  categoryName: string;
  isActive: boolean;
};

const renderAccordionHeader = ({
  element,
  resourceTitle,
  categoryName,
  isActive,
}: Partial<ResourceSurveyResultAccordionProps>) => {
  return (
    <>
      <span className="ml-2">{element}</span>
      <div className="flex items-center text-MatesWhite font-condensed ml-2 pt-[2px] group-hover:text-MatesWhite">
        <span className="text-2xl font-medium">{resourceTitle}</span>
        <span className="ml-2 text-base text-MatesWhite/60">
          {categoryName}
        </span>
        {isActive && (
          <span className="ml-2 text-base text-MatesGreen">Active</span>
        )}
      </div>
    </>
  );
};

const getResourceEvaluationData = (resource: ResourceSurveyResults) => {
  const { user, guest } = resource.survey;

  const userGroup: Record<string, any> = {
    question: "User",
    isHelpfulCount: user["isHelpfulCount"] ?? 0,
    isUnhelpfulCount: user["isUnhelpfulCount"] ?? 0,
  };

  const guestGroup: Record<string, any> = {
    question: "Guest",
    isHelpfulCount: guest["isHelpfulCount"] ?? 0,
    isUnhelpfulCount: guest["isUnhelpfulCount"] ?? 0,
  };

  const totalGroup = {
    question: "Total",
    isHelpfulCount: userGroup.isHelpfulCount + guestGroup.isHelpfulCount,
    isUnhelpfulCount: guestGroup.isUnhelpfulCount + guestGroup.isUnhelpfulCount,
  };

  return [guestGroup, userGroup, totalGroup];
};

const getResourceRatingData = (resource: ResourceSurveyResults) => {
  const { user, guest } = resource.survey;

  const userGroup: Record<string, any> = {
    question: "User",
    oneRatingCount: user["oneRating"] ?? 0,
    twoRatingCount: user["twoRating"] ?? 0,
    threeRatingCount: user["threeRating"] ?? 0,
    fourRatingCount: user["fourRating"] ?? 0,
    fiveRatingCount: user["fiveRating"] ?? 0,
  };

  const guestGroup: Record<string, any> = {
    question: "Guest",
    oneRatingCount: guest["oneRating"] ?? 0,
    twoRatingCount: guest["twoRating"] ?? 0,
    threeRatingCount: guest["threeRating"] ?? 0,
    fourRatingCount: guest["fourRating"] ?? 0,
    fiveRatingCount: guest["fiveRating"] ?? 0,
  };

  const totalGroup = {
    question: "Total",
    oneRatingCount: userGroup.oneRatingCount + guestGroup.oneRatingCount,
    twoRatingCount: guestGroup.twoRatingCount + guestGroup.twoRatingCount,
    threeRatingCount: guestGroup.threeRatingCount + guestGroup.threeRatingCount,
    fourRatingCount: guestGroup.fourRatingCount + guestGroup.fourRatingCount,
    fiveRatingCount: guestGroup.fiveRatingCount + guestGroup.fiveRatingCount,
  };

  return [guestGroup, userGroup, totalGroup];
};

function ResourceSurveyResultAccordion({
  resourceResult,
  createAt,
  resourceTitle,
  element,
  commentsCount,
  userGroupName,
  isActive,
  categoryName,
}: ResourceSurveyResultAccordionProps) {
  const dispatch = useMatesDispatch();

  const openCommentModal = () => {
    const { userCommentCount, guestCommentCount } = resourceResult.survey;
    dispatch(
      updateResourceCommentCount({
        userCount: userCommentCount,
        guestCount: guestCommentCount,
      })
    );
    dispatch(
      openModal({
        modalType: MODAL_TYPES.DISPLAY_RESOURCE_RESULTS_COMMENTS,
        itemId: resourceResult.id,
      })
    );
  };

  return (
    <MatesAccordion
      header={renderAccordionHeader({
        element,
        resourceTitle,
        categoryName,
        isActive,
      })}
      suffixLabel={userGroupName}
    >
      <div className="grid grid-cols-2cols gap-x-2">
        <MatesTable
          headers={emojiResourceResults}
          data={getResourceEvaluationData(resourceResult)}
        />
        <MatesTable
          headers={ratingResourceResults}
          data={getResourceRatingData(resourceResult)}
        />
      </div>

      <div className="flex items-center justify-between mt-4">
        <p className="text-MatesWhite/40 font-condensed text-base">{`Active since ${createAt}`}</p>
        <button
          onClick={() =>
            !commentsCount || (commentsCount ?? 0) <= 0
              ? {}
              : openCommentModal()
          }
        >
          <div className="underline underline-offset-4 text-base text-MatesWhite font-condensed">
            {(commentsCount ?? 0) <= 1 || !commentsCount
              ? `Read ${commentsCount ?? 0} Comment`
              : `Read All ${commentsCount} Comments`}
          </div>
        </button>
      </div>
    </MatesAccordion>
  );
}

export default ResourceSurveyResultAccordion;
