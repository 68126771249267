import { MatesApi } from '../app/api'
import { PaginationPayload, UserPaginationPayload, WeeklyFeedback } from '../types/serviceTypes'
import { User, UserData } from '../types/stateTypes'

export const userApi = MatesApi.injectEndpoints({
	endpoints: (builder) => ({
		getUsersByUserGroup: builder.query<UserData, UserPaginationPayload>({
			query: ({ page, take, filter, search }) => ({
				url: `/admin/users`,
				params: { page, take, filter, search },
			}),
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName
			},
			merge: (currentCache, newItems, { arg }) => {
				currentCache.data.splice(arg.page === 1 ? 0 : arg.page * arg.take, currentCache.data.length)
				currentCache.count = newItems.count
				currentCache.data.push(...newItems.data)
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg
			},
			providesTags: (result, error, arg) =>
				result?.data.length ? [...result.data.map(({ id }) => ({ type: 'User' as const, id })), 'User'] : ['User'],
		}),

		getUser: builder.query<User, string>({
			query: (userId) => ({
				url: `/admin/users/${userId}`,
			}),
			providesTags: ['User'],
		}),
		invalidateUserGroup: builder.mutation<null, void>({
			queryFn: () => ({ data: null }),
			invalidatesTags: ['User'],
		}),

		getTotalWeeklyFeedback: builder.query<WeeklyFeedback, void>({
			query: () => ({
				url: '/admin/connector-asist-weekly-feedbacks/summary',
			}),
			providesTags: ['User'],
		}),
		getAllUserResults: builder.query<UserData, PaginationPayload>({
			query: ({ page, take }) => ({
				url: '/admin/users',
				params: { page, take },
			}),
			providesTags: ['UserResult'],
		}),
	}),
})

export const {
	useLazyGetUsersByUserGroupQuery,
	useGetUserQuery,
	useInvalidateUserGroupMutation,
	useGetUsersByUserGroupQuery,
	useGetTotalWeeklyFeedbackQuery,
	useLazyGetAllUserResultsQuery,
} = userApi
