import { closeModal } from '../../../features/cores/modalSlice'
import { Dialog } from '@headlessui/react'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { MatesButton } from '../../atoms/atoms'
import ArrowBackIcon from '../../../assets/icons/svg/arrowBackIcon'
import DuplicateResourcesList from '../../organisms/lists/DuplicateResourcesList'
import {
	clearSelectedCategories,
	clearSelectedResources,
	selectedCategoriesSelector,
	selectedResourcesSelector,
} from '../../../features/resources/Resource/duplicateResourceSlice'
import { useState } from 'react'
import DuplicateResourceSelectCategories from '../../organisms/details/DuplicateResourceSelectCategories'
import { useDuplicateResourcesMutation } from '../../../services/ResourceService'
import asyncTimeout from '../../../utils/asyncTimeout'
import { openSnackbar } from '../../../features/cores/snackbarSlice'
import { ERROR_MESSAGES, SNACKBAR_TYPES, SUCCESS_MESSAGES } from '../../../assets/data/enums'

type DuplicateResourcePages = 'Select Resources' | 'Select Categories'

const DuplicateResourceModal = () => {
	const dispatch = useMatesDispatch()

	const selectedResources = useMatesSelector(selectedResourcesSelector)
	const selectedCategories = useMatesSelector(selectedCategoriesSelector)

	const [DuplicateResources] = useDuplicateResourcesMutation()

	const [page, setPage] = useState<DuplicateResourcePages>('Select Resources')
	const [isLoading, setIsLoading] = useState(false)

	const closeDuplicateModal = () => {
		dispatch(closeModal())
		dispatch(clearSelectedResources())
		dispatch(clearSelectedCategories())
	}

	const duplicateResources = async () => {
		const formattedResources = []
		setIsLoading(true)

		for (const resource of selectedResources) {
			for (const category of selectedCategories) {
				formattedResources.push({ resourceId: resource.id, categoryId: category.id })
			}
		}

		try {
			const [data] = await Promise.all([DuplicateResources({ resources: formattedResources }).unwrap(), asyncTimeout(2000)])

			if (!data) throw Error
			dispatch(
				openSnackbar({ snackbarTimeout: 2000, snackbarType: SNACKBAR_TYPES.SUCCESS, snackbarMessage: SUCCESS_MESSAGES.DUPLICATE_RESOURCE })
			)
			closeDuplicateModal()
		} catch (error) {
			dispatch(openSnackbar({ snackbarTimeout: 2000, snackbarType: SNACKBAR_TYPES.ERROR, snackbarMessage: ERROR_MESSAGES.DUPLICATE_RESOURCE }))
		} finally {
			setIsLoading(false)
		}
	}

	const renderSelectResources = () => {
		return (
			<>
				<ArrowBackIcon className='my-4 cursor-pointer w-fit' onClick={() => closeDuplicateModal()} />
				<Dialog.Title className='flex items-center justify-between pb-4'>
					<span className='font-Title font-extrabold text-4xl'>DUPLICATE RESOURCES</span>
					<MatesButton
						text='NEXT'
						className={`active-button rounded-md text-xl ${selectedResources.length < 1 && 'disabled-light-button'}`}
						disabled={selectedResources.length < 1}
						onSubmit={() => setPage('Select Categories')}
					/>
				</Dialog.Title>
				<p className='text-lg'>Select the resources you want to duplicate to another user group.</p>

				<h2 className='text-[22px] text-MatesRed font-bold mb-6'>{selectedResources.length} Resources Selected</h2>
				<DuplicateResourcesList />
			</>
		)
	}

	const renderSelectedCategories = () => {
		return selectedCategories.map((category) => category.title + ', ')
	}

	const renderSelectCategories = () => {
		return (
			<>
				<div className='flex gap-6 items-center'>
					<ArrowBackIcon className='my-4 cursor-pointer w-fit' onClick={() => setPage('Select Resources')} />
					<h2 className='text-[22px] text-MatesRed font-bold'>{selectedResources.length} Resources Selected</h2>
				</div>
				<Dialog.Title className='flex items-center justify-between pb-4'>
					<span className='font-Title font-extrabold text-4xl'>DUPLICATE RESOURCES</span>
					<MatesButton
						text='SAVE'
						className={`active-button rounded-md text-xl ${selectedCategories.length < 1 && 'disabled-light-button'}`}
						disabled={selectedCategories.length < 1}
						isLoading={isLoading}
						onSubmit={duplicateResources}
					/>
				</Dialog.Title>
				<p className='text-lg mb-4'>Select the user group you want to add the resource to.</p>
				<div>
					<span className='font-bold'>Selected Categories:</span> {renderSelectedCategories()}
				</div>
				<DuplicateResourceSelectCategories />
			</>
		)
	}

	return (
		<div className='relative w-[600px] h-[700px] overflow-hidden py-3 px-6 bg-white select-none rounded-lg flex flex-col'>
			{page === 'Select Resources' ? renderSelectResources() : renderSelectCategories()}
		</div>
	)
}

export default DuplicateResourceModal
