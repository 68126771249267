const EmptyResourceBlock = () => {
    return (
        <div 
            className='w-full h-[50%] flex items-center tracking-wide
        justify-center text-2xl text-MatesBorder'
        >
            Select Resource to see more detail
        </div>
    )
}

export default EmptyResourceBlock