import { twMerge } from "tailwind-merge";
import { openModal } from "../../features/cores/modalSlice";
import { MODAL_TYPES } from "../../assets/data/enums";
import { InstallIcon } from "../../assets/icons/icons";
import { DashboardLayout } from "../../components/templates/templates";
import { searchTypesSelector } from "../../features/surveys/surveySlice";
import { useMatesDispatch, useMatesSelector } from "../../app/hooks";
import {
  HubResultDetail,
  ResourceResultsDetail,
} from "../../components/organisms/organisms";

const Surveys = () => {
  const { surveyType } = useMatesSelector(searchTypesSelector);
  const dispatch = useMatesDispatch();

  return (
    <DashboardLayout page={"SURVEYS"}>
      <>
        <div className="w-4/5 mx-auto flex items-center justify-between">
          <p className={twMerge("title-page", "mx-0")}>
            {surveyType === "RESOURCE"
              ? "RESOURCE SURVEY RESULTS"
              : "THE HUB RESULTS"}
          </p>
          {surveyType === "RESOURCE" && (
            <div
              className="flex items-center gap-x-2 border-solid 
              border-[1px] border-MatesWhite/20 px-2 rounded-sm
              transition-all duration-300 hover:bg-MatesWhite/30
              cursor-pointer"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: MODAL_TYPES.DOWNLOAD_SURVEY_RESULTS,
                  })
                )
              }
            >
              <p className="text-MatesWhite font-condensed text-lg pt-1">
                Download Survey Results
              </p>
              <InstallIcon />
            </div>
          )}
        </div>
        <div className="h-[calc(100vh-11em)] overflow-y-auto">
          <div className="w-4/5 mx-auto">
            {surveyType === "RESOURCE" ? (
              <ResourceResultsDetail />
            ) : (
              <HubResultDetail />
            )}
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default Surveys;
