import { RFC } from '../../../types/propTypes'
import { RadioGroup } from '@headlessui/react'
import { MatesCheckbox } from '../atoms'

type RadioGroupButtonsProps = {
    list:         string[]
    selectedItem: string | undefined
    onSelectItem: (item: string, index: number) => void
}

const RadioGroupButtons:RFC<RadioGroupButtonsProps> = ({
    list,
    selectedItem,
    onSelectItem,
}) => {
    
    return (
        <div className='mt-4 pb-6 max-h-[20em] overflow-y-auto overflow-x-hidden'>
            <RadioGroup
                value={selectedItem ?? ''}
                onChange={(item:string) => {
                    //Find selected category index
                    const index = list.findIndex(i => i === item)
                    if(index === undefined) return
                    onSelectItem(item, index)
                }}
            >
                <div>
                {list.map((item, index) => (
                    <RadioGroup.Option
                        key={index}
                        value={item}
                        className='my-2 cursor-pointer focus:outline-none'
                    >
                    {({ active, checked }) => (
                        <div className='flex'>
                            <MatesCheckbox checked={checked}/>
                            <RadioGroup.Label
                                as='p'
                                className='font-medium text-2xl'
                            >
                                {item}
                            </RadioGroup.Label>
                        </div>
                    )}
                    </RadioGroup.Option>
                ))}
                </div>
            </RadioGroup>
        </div>
    )
}

export default RadioGroupButtons