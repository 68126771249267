import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { MatesApi } from "../app/api";
import { updateHubCommentCount } from "../features/surveys/surveySlice";
import {
  AllReourceSurveysData,
  AllWelcomeSurveyFeedbacksData,
  PaginationPayload,
  ResourcesSurveyResultsData,
  ResourceSurveyCommentsPaginationPayload,
  ResourceSurveyFeedbackData,
  ResourceSurveyPaginationPayload,
  SignUpReasonsResults,
  TheHubExperienceCommentsResults,
  TheHubFeedbackResults,
  WeeklyFeedback,
  WeeklyFeedbackPayload,
  WelcomeSurveyResult,
} from "../types/serviceTypes";

export const userGroupApi = MatesApi.injectEndpoints({
  endpoints: (builder) => ({
    getWeeklyFeedback: builder.query<WeeklyFeedback, WeeklyFeedbackPayload>({
      query: ({ userId }) => ({
        url: `/admin/connector-asist-weekly-feedbacks/summary?userId=${userId}`,
      }),
      providesTags: ["User"],
    }),

    getResourceResults: builder.query<ResourcesSurveyResultsData, ResourceSurveyPaginationPayload>({
      query: ({ page, take, resourceType, userGroup }) => ({
        url: `/admin/resources/surveys/summary`,
        params: { page, take, resourceType, userGroup: userGroup ?? "" },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.resources.map(({ id }) => ({
                type: "ResourceSurveyResult" as const,
                id,
              })),
              "ResourceSurveyResult",
            ]
          : ["ResourceSurveyResult"],
    }),

    getResourceComments: builder.query<ResourceSurveyFeedbackData, ResourceSurveyCommentsPaginationPayload>({
      query: ({ page, take, resourceId }) => ({
        url: `/admin/resources/${resourceId}/surveys`,
        params: { page, take },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        currentCache.data.splice(arg.page === 1 ? 0 : arg.page * arg.take, currentCache.data.length);
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["ResourceSurveyResult"],
    }),

    getWelcomeSurveyResult: builder.query<WelcomeSurveyResult, void>({
      query: () => ({
        url: `/admin/welcome-surveys/summary`,
      }),
      providesTags: ["ResourceSurveyResult"],
    }),

    getSignUpReasonsSurveyResults: builder.query<SignUpReasonsResults, void>({
      query: () => ({
        url: `/admin/signup-reasons`,
      }),
      providesTags: ["ResourceSurveyResult"],
    }),

    getTheHubExperienceCommentsFeedback: builder.query<TheHubExperienceCommentsResults, PaginationPayload>({
      query: ({ page, take }) => ({
        url: `/admin/user-feedbacks`,
        params: { page, take },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        currentCache.data.splice(arg.page === 1 ? 0 : arg.page * arg.take, currentCache.data.length);
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["ResourceSurveyResult"],
    }),

    getTheHubExperienceFeedback: builder.query<TheHubFeedbackResults, void>({
      async queryFn(arg, api, extraOptions, baseQuery) {
        const { data, error } = await baseQuery({
          url: "/admin/user-feedbacks/count",
        });
        if (error) return { error: error as FetchBaseQueryError };
        const feedbackResult = data as TheHubFeedbackResults;
        api.dispatch(
          updateHubCommentCount({
            userCount: feedbackResult.commentUserCount,
            guestCount: feedbackResult.commentGuestCount,
          })
        );
        return { data: feedbackResult };
      },
      providesTags: ["ResourceSurveyResult"],
    }),

    getAllResourceSurveys: builder.query<AllReourceSurveysData, PaginationPayload>({
      query: ({ page, take }) => ({
        url: "/admin/resource-surveys",
        params: { page, take },
      }),
      providesTags: ["ResourceSurveyResult"],
    }),

    getAllWelcomeSurveyFeedback: builder.query<AllWelcomeSurveyFeedbacksData, PaginationPayload>({
      query: ({ page, take }) => ({
        url: "/admin/welcome-surveys",
        params: { page, take },
      }),
      providesTags: ["ResourceSurveyResult"],
    }),
  }),
});

export const {
  useGetWeeklyFeedbackQuery,
  useLazyGetResourceResultsQuery,
  useLazyGetResourceCommentsQuery,
  useGetWelcomeSurveyResultQuery,
  useGetSignUpReasonsSurveyResultsQuery,
  useLazyGetTheHubExperienceCommentsFeedbackQuery,
  useGetTheHubExperienceFeedbackQuery,
  useLazyGetAllResourceSurveysQuery,
  useLazyGetAllWelcomeSurveyFeedbackQuery,
} = userGroupApi;
