import { useMutation, useQuery, useQueryClient } from "react-query";
import { DEFAULT_PAGE_SIZE, TOP_PICK_KEY } from "../../../assets/data/constants";
import ChallengeService from "../../../services/react-query/ChallengeService";
import MatesTikTokVoteInput from "../../atoms/fields/MatesTikTokVoteInput";
import { AnimatePresence, motion } from "framer-motion";
import { moveAnimation } from "../../../assets/data/animations";

type Props = {
  challengeId: string;
};

const ChallengeTopPicksVotingField = ({ challengeId }: Props) => {
  const { data: topPickData } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      ChallengeService.getTopPicks({
        page: pageParam,
        take: DEFAULT_PAGE_SIZE,
        challengeId: challengeId,
      }),
    queryKey: [TOP_PICK_KEY, challengeId],
    enabled: !!challengeId,
  });
  const topPicks = topPickData ? topPickData.data.data : [];

  return (
    <>
      <AnimatePresence initial={false}>
        {topPicks.map((topPick) => {
          return (
            <motion.div key={topPick.id} layoutId={topPick.id} {...moveAnimation}>
              <MatesTikTokVoteInput topPick={topPick} />;
            </motion.div>
          );
        })}
      </AnimatePresence>
    </>
  );
};

export default ChallengeTopPicksVotingField;
