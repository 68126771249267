import { RFC } from "../../../types/propTypes";
import { MatesLoading } from "../../atoms/atoms";
import { useGetUserQuery } from "../../../services/UserService";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ErrorIcon } from "../../../assets/icons/icons";
import { UserFeedbackDetail, UserProfileDetail } from "../organisms";

interface UserDetailsProps {
  userId: string;
}
const UserDetails: RFC<UserDetailsProps> = ({ userId }) => {
  const {
    data: userData,
    isLoading,
    isError,
  } = useGetUserQuery(userId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  if (isLoading)
    return (
      <div className="h-full flex items-center justify-center">
        <MatesLoading shape="rounded" />
      </div>
    );
  if (!userData || isError)
    return (
      <div className="h-full flex items-center justify-center">
        <ErrorIcon className="text-MatesRed mr-2" width={38} height={38} />
        <p className="text-2xl text-MatesRed text-center tracking-wide">
          Something went wrong.
        </p>
      </div>
    );

  return (
    <div className="w-[80%] h-full px-4">
      <div>
        <UserProfileDetail user={userData} />
        {userData.weeklyFeedback ? (
          <UserFeedbackDetail weeklyFeedback={userData.weeklyFeedback} />
        ) : (
          <div className="h-full flex justify-center pt-20">
            <p className="text-2xl text-MatesWhite/60 text-center tracking-wide">
              There is no weekly feedback
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserDetails;
