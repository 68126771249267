const playVideoIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "46.888"} 
                height={height ?? "46.888"} 
                viewBox="0 0 46.888 46.888"
                onClick={onClick}
            >
                <path 
                    d="M21.759,37.408l15.649-9.964L21.759,17.48Zm5.685,13.48a22.684,22.684,0,0,1-9.085-1.846A23.62,23.62,0,0,1,5.846,36.529a23.418,23.418,0,0,1,0-18.228,23.43,23.43,0,0,1,5.04-7.443A24.032,24.032,0,0,1,18.36,5.846a23.418,23.418,0,0,1,18.228,0A23.407,23.407,0,0,1,49.042,18.3a23.418,23.418,0,0,1,0,18.228A24.032,24.032,0,0,1,44.031,44a23.43,23.43,0,0,1-7.444,5.04A22.829,22.829,0,0,1,27.444,50.888Zm0-3.517A19.176,19.176,0,0,0,41.569,41.54a19.253,19.253,0,0,0,5.8-14.1,19.224,19.224,0,0,0-5.8-14.125,19.224,19.224,0,0,0-14.125-5.8,19.253,19.253,0,0,0-14.1,5.8A19.176,19.176,0,0,0,7.517,27.444a19.2,19.2,0,0,0,5.832,14.1A19.2,19.2,0,0,0,27.444,47.372ZM27.444,27.444Z" 
                    transform="translate(-4 -4)" 
                    fill="#fff" 
                />
            </svg>
        </div>
    )
}

export default playVideoIcon