import { MatesLoading } from '../atoms'

const MatesSplashScreen = () => {
  return (
    <div 
      className='h-screen w-screen flex 
      items-center justify-center bg-MatesBlack'>
      <MatesLoading shape='cradle'/>
    </div>
  )
}

export default MatesSplashScreen