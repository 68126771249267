import { useState } from 'react'
import { useGetCategoriesQuery, useGetDuplicateResourcesCategoriesQuery, useGetUserGroupsQuery } from '../../../services/UserGroupService'
import { CardLoading, TitleCard } from '../../atoms/atoms'
import { RightArrowIcon } from '../../../assets/icons/icons'
import { Category, UserGroup } from '../../../types/stateTypes'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { addOrRemoveCategory, selectedCategoriesSelector } from '../../../features/resources/Resource/duplicateResourceSlice'
import { categoryIdSelector } from '../../../features/resources/categoriesSlice'

type Props = {}

const DuplicateResourceSelectCategories = ({}: Props) => {
	const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>()

	const selectedCategories = useMatesSelector(selectedCategoriesSelector)
	const selectedCategoryId = useMatesSelector(categoryIdSelector)

	const dispatch = useMatesDispatch()

	const { data: userGroups, isLoading: isUserGroupsLoading } = useGetUserGroupsQuery()
	const { data: categories, isLoading: isCategoriesLoading } = useGetDuplicateResourcesCategoriesQuery(selectedUserGroup?.id ?? skipToken)

	const selectOrUnselectCategory = (category: Category) => {
		dispatch(addOrRemoveCategory(category))
	}

	const renderUserGroups = () => {
		if (isUserGroupsLoading) return <CardLoading />

		return userGroups?.map((userGroup, index) => {
			return (
				<TitleCard
					isSelected={userGroup === selectedUserGroup}
					isAnimationOn={false}
					onClick={() => setSelectedUserGroup(userGroup)}
					key={index}
				>
					<div className='flex items-center justify-between w-full tracking-wider'>
						<p className='text-[20px] font-compressed font-bold pt-[2px]'>{userGroup.title}</p>
						{userGroup === selectedUserGroup && <RightArrowIcon className='ml-2' />}
					</div>
				</TitleCard>
			)
		})
	}

	const renderCategories = () => {
		if (!selectedUserGroup) return <div />
		if (isCategoriesLoading) return <CardLoading />
		// remove selected category

		const filteredCategories = categories?.filter((cat) => cat.id !== selectedCategoryId)
		return filteredCategories?.map((category, index) => {
			return (
				<TitleCard
					isSelected={selectedCategories.some((cat) => cat.id === category.id)}
					isAnimationOn={false}
					onClick={() => selectOrUnselectCategory(category)}
					key={index}
				>
					<div className='flex items-center justify-between w-full tracking-wider'>
						<p className='text-[20px] font-compressed font-bold pt-[2px]'>{category.title}</p>
					</div>
				</TitleCard>
			)
		})
	}

	return (
		<div className='flex w-full'>
			<div className='border-MatesGrey border-r pr-4 w-1/2'>
				<h2 className='text-[22px] text-MatesRed font-bold '>User Group</h2>
				{renderUserGroups()}
			</div>

			<div className=' pl-4 w-1/2'>
				<h2 className='text-[22px] text-MatesRed font-bold '>Category</h2>
				{renderCategories()}
			</div>
		</div>
	)
}

export default DuplicateResourceSelectCategories
