import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { MATES_ADMIN_TOKEN } from '../../../assets/data/constants';

const useAuth = () => {
    const token = localStorage.getItem(MATES_ADMIN_TOKEN)
    if (token) return true
    return false
}

export const AuthRequiredRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to='/login' />
}

export const NotAuthRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Navigate to='/users' /> : <Outlet/>
}