import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialUserState } from "../../assets/data/InitialState";
import { UserFilter } from "../../types/serviceTypes";
import { User } from "../../types/stateTypes";

export type UserSliceType = {
  userGroup: UserFilter;
  user: User;
};

export const userSlice = createSlice({
  name: "user",
  initialState: InitialUserState,
  reducers: {
    selectUserGroup: (state, action: PayloadAction<string>) => {
      const inputUserGroup = action.payload as UserFilter;
      state.userGroup = inputUserGroup;
      state.user = InitialUserState.user;
    },
    selectUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export const userIdSelector = (state: RootState) => state.user.user.id;
export const userGroupSelector = (state: RootState) => state.user.userGroup;
export const userSelector = (state: RootState) => state.user.user;
export const { selectUserGroup, selectUser } = userSlice.actions;
export default userSlice.reducer;
