import { useState } from "react";
import { ArrowDownFilterIcon } from "../../../assets/icons/icons";
import { UserGroupName } from "../../../types/serviceTypes";
import { WORK_ROLE } from "../../../assets/data/enums";

interface ResourceResultsFilterDropdownProps {
  onSelectGroup: (userGroupName: UserGroupName) => void;
  value: UserGroupName | null;
}

const userGroupName: UserGroupName[] = [
  WORK_ROLE.FIFO_DIDO_WORKERS,
  WORK_ROLE.MANAGERS_AND_SUPERVISORS,
  WORK_ROLE.CONNECTOR_ASIST_VOLUNTEERS,
  WORK_ROLE.FAMILY_AND_FRIENDS,
  WORK_ROLE.MENTAL_HEALTH_SERVICE_PROVIDERS,
];

function ResourceResultsFilterDropdown({
  onSelectGroup,
  value,
}: ResourceResultsFilterDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="relative">
        <div
          className={`flex items-center justify-between border-solid border-[1px] rounded-[3px] 
          py-1 px-4 transition-all duration-200 gap-x-4 cursor-pointer
          ${value ? "border-MatesRed" : "border-MatesBorder"}
          `}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="text-MatesWhite uppercase font-condensed mt-[2px]">
            {value ?? "FILTERS"}
          </div>
          <ArrowDownFilterIcon onClick={() => {}} />
        </div>

        <div
          className={`absolute right-0 z-10 mt-2 min-w-[8em] origin-top-right  
          bg-MatesBlack text-3xl tracking-wide`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          hidden={!isOpen}
        >
          <div className="py-0" role="none">
            <ul className="p-2 text-MatesWhite text-base font-condensed border border-MatesBorder rounded-[3px]">
              {userGroupName.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      onSelectGroup(item);
                      setIsOpen(false);
                    }}
                    className={`cursor-pointer py-1 px-2 ${
                      value === item
                        ? `bg-MatesRed hover:bg-MatesRed`
                        : ` bg-MatesBlack hover:bg-MatesDarkGrey`
                    } ${
                      userGroupName.length === index + 1
                        ? "border-none"
                        : "border-b-[1px] border-MatesBorder"
                    }
                    `}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceResultsFilterDropdown;
