const arrowDownIcon = ({
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "20.829"}
        height={height ?? "11.829"}
        viewBox="0 0 20.829 11.829"
      >
        <path
          id="Path_1353"
          data-name="Path 1353"
          d="M-4170-4173.078l9.709,9.708,9.706-9.708"
          transform="translate(-4149.875 -4161.956) rotate(180)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export default arrowDownIcon;
