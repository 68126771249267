type MatesTableProps = {
  headers: Record<string, any>;
  data: Record<string, any>[];
  totals?: Record<string, any>;
  className?: string;
};

function MatesTable({ headers, data, className }: MatesTableProps) {
  return (
    <div className="bg-MatesLightBlack rounded-[5px] px-3 py-2 flex items-center justify-center mx-auto my-auto h-full w-full">
      <table
        className={`font-condensed ${className}`}
      >
        <thead className="w-fit text-MatesWhite font-medium text-base tracking-wide">
          <tr className="divide-x divide-MatesBorder">
            {Object.values(headers).map((header, index) => {
              return (
                <th
                  scope="col"
                  key={index}
                  className={`p-3 ${
                    index === 0 ? "text-start" : "text-center"
                  }`}
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody className="text-base text-MatesWhite">
          {data.map((item, index) => {
            return (
              <tr
                key={`table-row-${index}`}
                className={`
                ${data.length === index + 1 ? '' :  'border-y border-MatesBorder'}
                text-MatesWhite font-condensed text-base tracking-wider`}
              >
                <>
                  {Object.keys(headers).map((header, index) => (
                    <td
                      key={`table-col-${index}`}
                      className={`p-3 border-r border-t border-MatesBorder ${
                        index === 0 ? "text-start" : "text-center"
                      }`}
                    >
                      <p>{item[header]}</p>
                    </td>
                  ))}
                </>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default MatesTable;
