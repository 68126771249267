import { AiOutlineArrowLeft as BackArrow } from "react-icons/ai";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { closeModal, openModal } from "../../../features/cores/modalSlice";
import {
  MatesButton,
  MatesTextArea,
  MatesTextInput,
  MatesTitleBlock,
  MatesUploadInput,
} from "../../atoms/atoms";
import { useFormik } from "formik";
import { CreateCategorySchema } from "../../../utils/validationSchema";
import { IconTextSwitchRow } from "../molecules";
import {
  categorySelector,
  categoryIdSelector,
  clearCategory,
  updateCategoryInfo,
} from "../../../features/resources/categoriesSlice";
import {
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from "../../../services/CategoryService";
import asyncTimeout from "../../../utils/asyncTimeout";
import { MODAL_TYPES } from "../../../assets/data/enums";
import {
  closeOverlay,
  openOverlay,
} from "../../../features/cores/overlaySlice";
import { BinIcon } from "../../../assets/icons/icons";
import { formatSlug, formatUpdateCategory } from "../../../utils/formatter";

const EditCategoryModal = () => {
  const categoryId = useMatesSelector(categoryIdSelector);
  const categoryState = useMatesSelector(categorySelector);
  const dispatch = useMatesDispatch();
  const initialAvailableToEveryone = categoryState.isAvailableToEveryone;

  const [updateCategory] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: categoryState,
    validationSchema: CreateCategorySchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        dispatch(closeModal());
        dispatch(openOverlay({ text: "Updating Category" }));
        const [data] = await Promise.all([
          updateCategory(
            formatUpdateCategory(initialAvailableToEveryone, values)
          ).unwrap(),
          asyncTimeout(2000),
        ]);

        if (!data) throw Error;
        dispatch(updateCategoryInfo(data));
        dispatch(
          openModal({
            modalType: MODAL_TYPES.SUCCESS,
            title: "CATEGORY UPDATED",
            body: "You have successfully updated the category!",
          })
        );
      } catch (error) {
        dispatch(
          openModal({
            modalType: MODAL_TYPES.FAIL,
            title: "Failed to update the category",
            body: "Please check your input details and try again.",
          })
        );
      } finally {
        setSubmitting(false);
        dispatch(closeOverlay());
      }
    },
  });

  const removeCategory = async (id: string) => {
    try {
      dispatch(closeModal());
      dispatch(openOverlay({ text: "Deleting this category " }));
      const [data] = await Promise.all([
        deleteCategory(id).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!data) throw Error;
      dispatch(clearCategory());
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: "CATEGORY DELETED",
          body: "You have successfully deleted the category!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to delete category",
          body: "Something went wrong. Please try again later.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const isValid = values.title && values.thumbnail;

  return (
    <div className="h-fit w-[500px] px-6 py-3">
      <BackArrow
        className="text-[2em] my-3 cursor-pointer"
        onClick={() => dispatch(closeModal())}
      />
      <MatesTitleBlock
        title="EDIT CATEGORY"
        description="This category can be edited and adjusted based on your preference."
        className="pb-3"
      />
      <IconTextSwitchRow
        icon={"hidden"}
        isActive={values.isHidden}
        text={"Category hidden"}
        onChange={() => setFieldValue("isHidden", !values.isHidden)}
      />
      <IconTextSwitchRow
        icon={"available"}
        isActive={values.isAvailableToEveryone}
        text={"Available to everyone"}
        onChange={() =>
          setFieldValue("isAvailableToEveryone", !values.isAvailableToEveryone)
        }
      />
      <IconTextSwitchRow
        icon={"training"}
        isActive={values.isTrainingModule}
        text={"Training module"}
        onChange={() =>
          setFieldValue("isTrainingModule", !values.isTrainingModule)
        }
      />
      <MatesTextInput
        name="slug"
        label="URL SLUG"
        theme="light"
        value={values.slug}
        onChange={(text) => setFieldValue('slug', formatSlug(text))}
        isTouched={touched.slug}
        error={errors.slug}
      />
      <MatesTextInput
        name="categoryTitle"
        label="TITLE"
        theme="light"
        value={values.title}
        onChange={(text) => setFieldValue('title', text.toUpperCase())}
        isTouched={touched.title}
        error={errors.title}
      />
      <MatesUploadInput
        title="PICTURE THUMBNAIL"
        theme="light"
        type="image"
        source={values.thumbnail}
        onUpload={(image) => setFieldValue("thumbnail", image ?? "")}
        folder={"video_thumbnails"}
        rootFolder={"images"}
      />
      <div className="pt-4">
        <MatesTextArea
          label="DESCRIPTION"
          theme="light"
          value={values.description}
          height="h-[80px]"
          size="text-lg"
          onChange={(text) => setFieldValue("description", text)}
        />
      </div>
      <div className="flex items-center mt-7">
        <MatesButton
          text="Save"
          className={`base-button
            ${isValid ? "active-button" : "disabled-light-button"}`}
          disabled={!isValid}
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
        />
        <div
          className="bg-MatesRed rounded-[3px]
          h-full p-4 ml-5 cursor-pointer"
          onClick={() =>
            dispatch(
              openModal({
                modalType: MODAL_TYPES.DELETE,
                itemId: categoryId,
                deleteFn: removeCategory,
              })
            )
          }
        >
          <BinIcon />
        </div>
      </div>
    </div>
  );
};

export default EditCategoryModal;
