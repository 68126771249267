import { RFC } from '../../../types/propTypes'
import { motion } from 'framer-motion'

type TitleCardProps = {
    isSelected?:    boolean
    children?:      JSX.Element
    isAnimationOn: boolean
    onClick?:       () => void
    className?:    string
}

const TitleCard:RFC<TitleCardProps> = ({
    isSelected,
    children,
    isAnimationOn,
    onClick,
    className,
}) => {

    const cardAnimations = () => {
        if (!isAnimationOn) return null
        return cardAnimations
    }
    
    return (
        <motion.div
            key='title-card'
            {...cardAnimations}
            className={`title-card ${className}
            ${isSelected
            ? 'bg-MatesRed hover:bg-MatesRed'
            : 'bg-MatesDarkGrey hover:bg-MatesBorder'}`}
            onClick={onClick}
        >
            { children }
        </motion.div>
    )
}

export default TitleCard