import { RFC } from "../../../types/propTypes";

type MatesChallengeTitleBlockProps = {
  title: string;
  description: string;
  className?: string;
};

const MatesChallengeTitleBlock: RFC<MatesChallengeTitleBlockProps> = ({
  title,
  description,
  className,
}) => {
  return (
    <div className={className}>
      <p className="text-MatesWhite font-compressed font-bold text-3xl leading-7 uppercase">
        {title}
      </p>
      <p className="text-MatesWhite/80 font-condensed text-base leading-5">
        {description}
      </p>
    </div>
  );
};

export default MatesChallengeTitleBlock;
