import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthRequiredRoutes, NotAuthRoutes } from "./components/atoms/cores/MatesProtectedRoutes";
import { MatesModal, MatesOverlay, MatesSnackbar } from "./components/atoms/atoms";
import { Challenges, Login, NotFound, Resources, Surveys, Users } from "./pages/pages";
import { QueryClient, QueryClientProvider } from "react-query";

const App = () => {
  const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <MatesSnackbar />
        <MatesModal />
        <MatesOverlay />

        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="*" element={<NotFound />} />

          <Route element={<NotAuthRoutes />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route element={<AuthRequiredRoutes />}>
            <Route path="/users" element={<Users />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/surveys" element={<Surveys />} />
            <Route path="/challenges" element={<Challenges />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
