import { ErrorIcon } from '../../../assets/icons/icons'
import { RFC } from '../../../types/propTypes'

type MatesLinkInputProps = {
    title:       string
    theme:       'light' | 'dark'
    source?:     string
    placeholder: string
    isTouched?:  boolean
    error?:      string | null
    onChange:    (link: string) => void
}

const MatesLinkInput:RFC<MatesLinkInputProps> = ({
    title,
    theme,
    source,
    placeholder,
    isTouched,
    error,
    onChange,
}) => {

    return (
        <div className='mt-6 mb-2'>
            <p
                className={`font-medium text-lg
                ${theme === 'light' ? 'text-MatesBlack' : 'text-MatesRed'}`}>
                {title}
            </p>
            <input 
                type='text'
                value={source}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                className={`w-full outline-none rounded-[3px] px-3 py-2 font-condensed
                ${theme === 'light' 
                ? 'bg-[#F4F4F4] text-MatesBlack' 
                : 'bg-[#2F2F2F] text-MatesWhite'}`}
            />
            {(isTouched && error) && (
                <div
                    className='flex items-center text-MatesRed font-medium'
                    role='alert'
                >
                    <ErrorIcon className='mr-2'/>
                    {error}
                </div>
            )}
        </div>
    )
}

export default MatesLinkInput