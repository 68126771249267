const binIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "16.258"} 
                height={height ?? "17.429"} 
                viewBox="0 0 16.258 17.429"
            >
                <path 
                    id="delete_FILL0_wght600_GRAD0_opsz48" 
                    d="M9.285,22.179a1.8,1.8,0,0,1-1.814-1.814V7.506H6.3V5.669h4.868V4.75h6.5v.919h4.891V7.506H21.387v12.86a1.843,1.843,0,0,1-1.814,1.814ZM19.573,7.506H9.285v12.86H19.573Zm-7.9,10.977h1.584V9.32H11.673Zm3.927,0h1.607V9.32H15.6ZM9.285,7.506v0Z" 
                    transform="translate(-6.3 -4.75)" 
                    fill="#fff" 
                />
            </svg>
        </div>
    )
}

export default binIcon