import { useEffect } from 'react'
import classes from '../../../assets/styles/snackbar.module.css'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { closeSnackbar, snackbarSelector } from '../../../features/cores/snackbarSlice'
import { IoClose as CloseIcon } from 'react-icons/io5'
import { BiErrorCircle as SnackbarError } from 'react-icons/bi'
import { 
    AiOutlineWarning as SnackbarWarning,
    AiOutlineInfoCircle as SnackbarInfo, 
    AiOutlineCheckCircle as SnackbarSuccess,
} from 'react-icons/ai'
import { SNACKBAR_TYPES } from '../../../assets/data/enums'

const MatesSnackbar = () => {
  const snackbarState = useMatesSelector(snackbarSelector)
  const dispatch = useMatesDispatch()
  const {
    isDisplayed, snackbarType, snackbarMessage,
    snackbarTimeout, snackbarBtnText, snackbarBtnFunc
  } = snackbarState
  const TIME = `${(snackbarTimeout - 500) / 1000}s`

  useEffect(() => {
    if (isDisplayed) {
      const TIMER = setTimeout(() => {
        dispatch(closeSnackbar())
      }, snackbarTimeout)
      return () => clearTimeout(TIMER) 
    }
  },[isDisplayed])

  const getSnackbarIcons = () => {
    switch (snackbarType) {
      case SNACKBAR_TYPES.ERROR:
        return <SnackbarError className='min-w-[1.6em] h-full mr-4 text-white' />
      case SNACKBAR_TYPES.WARNING:
        return <SnackbarWarning className='min-w-[1.6em] h-full mr-4 text-white' />
      case SNACKBAR_TYPES.INFO:
        return <SnackbarInfo className='min-w-[1.6em] h-full mr-4 text-white' />
      case SNACKBAR_TYPES.SUCCESS:
        return <SnackbarSuccess className='min-w-[1.6em] h-full mr-4 text-white' />
      default:
        return
    }
  }

  return (
    <div
      className={`${classes.position}
      ${classes.snackbar_container} 
      ${isDisplayed && classes.snackbar_show} 
      ${classes[snackbarType]} 
      sm: w-[400px]`}
      style={{ animationDelay: `0s, ${TIME}` }}
    >
      {getSnackbarIcons()}
      <p className='pr-3 text-base text-white'>{snackbarMessage}</p>
      <button
        data-mdb-ripple='true'
        data-mdb-ripple-color='light'
        className={`px-4 py-2.5 text-sm flex justify-center items-center  
        text-MatesWhite rounded-[6px] font-bold hover:bg-MatesWhite/10
        transition-all duration-200`}
        onClick={snackbarBtnFunc}
      >
        {snackbarBtnText}
      </button>
      <CloseIcon
        className='p-2 rounded-[50%] min-w-[40px] min-h-[40px] 
        ml-4 fill-white cursor-pointer hover:bg-MatesWhite/10'
        onClick={() => dispatch(closeSnackbar())}
      />
    </div>
  )
}

export default MatesSnackbar