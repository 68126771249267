import { ErrorIcon } from '../../../assets/icons/icons'

const CardError = () => {
    return (
        <div
            className='flex justify-center flex-col items-center mt-8'
        >
            <ErrorIcon 
                className='text-MatesRed'
                width={'35px'}
                height={'35px'}
            />
            <p className='text-xl mt-4 text-MatesRed text-center font-condensed tracking-wide'>
                Something went wrong.<br />
                Please try again later.
            </p>
        </div>
    )
}

export default CardError