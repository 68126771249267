import moment from "moment";
import { useMutation } from "react-query";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { closeModal, modalSelector } from "../../../features/cores/modalSlice";
import ChallengeService from "../../../services/react-query/ChallengeService";
import { MatesChallengeTextInput } from "../../atoms/atoms";
import MatesFlexiButton from "../../atoms/buttons/MatesFlexiButton";
import { useState } from "react";
import { announceDateChecker } from "../../../utils/validations";
import { closeDetail } from "../../../features/challenges/challengeSlice";
import { convertDateStringToISO } from "../../../utils/converter";

const CloseSubmissionModal = () => {
  const [isError, setIsError] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [winnerAnnouncementDate, setWinnerAnnouncementDate] = useState<string>("");
  const { itemId = "" } = useMatesSelector(modalSelector);
  const dispatch = useMatesDispatch();

  const { mutate: closeChallengeSubmissions } = useMutation({
    mutationFn: ChallengeService.patchChallenges,
    onSuccess() {
      dispatch(closeDetail());
    },
  });

  const onCloseSubmissions = () => {
    closeChallengeSubmissions({
      id: itemId,
      data: {
        winnerAnnouncementDate: moment(convertDateStringToISO(winnerAnnouncementDate)).toJSON(),
      },
    });
    dispatch(closeModal());
  };

  return (
    <div className="w-[350px] px-8 py-3 flex flex-col items-center gap-3">
      <div className="text-MatesBlack">
        <h1 className="font-compressed text-3xl font-bold">CLOSE SUBMISSIONS</h1>
        <p className="text-md font-normal leading-5">
          You are about to close submissions to this challenge and move it to In For Voting. Before you continue, you must select what date the Voting
          submissions will end on.
        </p>
      </div>
      <div className="w-full">
        <MatesChallengeTextInput
          type="date"
          value={winnerAnnouncementDate}
          permissionType={"write"}
          className={`px-6 py-2 bg-opacity-10 w-full `}
          onChange={(text) => {
            setWinnerAnnouncementDate(text ?? "");
            isTouched && setIsError(announceDateChecker(text));
          }}
          onBlur={(text) => {
            setIsError(announceDateChecker(text));
            setIsTouched(true);
          }}
        />
        {isError && isTouched && (
          <div className="text-MatesRed font-condensed">
            {winnerAnnouncementDate === ""
              ? "Voting submissions close date field can not be empty."
              : `${winnerAnnouncementDate} is not in the format dd-mm-yyyy.`}
          </div>
        )}
      </div>
      <MatesFlexiButton text="CLOSE SUBMISSIONS" className="red-button w-full" disabled={isError} onSubmit={() => onCloseSubmissions()} />
      <MatesFlexiButton text="CANCEL" className="cancel-button w-full" onSubmit={() => dispatch(closeModal())} />
    </div>
  );
};

export default CloseSubmissionModal;
