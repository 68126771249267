import MagnifyingGlassIcon from "../../../assets/icons/svg/magnifyingGlassIcon";

interface MatesSearchInputProps {
  onChange: (text: string) => void;
}

function MatesSearchInput({
  onChange,
}: MatesSearchInputProps): JSX.Element {
  return (
    <div
      className="w-full flex items-center border-solid border-MatesRed border-[1px] 
	  rounded bg-MatesDarkGrey py-2 px-2"
    >
      <MagnifyingGlassIcon className="mr-2" />
      <input
        type="text"
        placeholder="SEARCH BY NAME OR EMAIL"
        autoComplete="off"
        onChange={(e) => onChange(e.target.value)}
        className={`w-full outline-none bg-MatesDarkGrey mb-0 text-MatesWhite
		    cursor-pointer font-condensed text-base`}
      />
    </div>
  );
}

export default MatesSearchInput;
