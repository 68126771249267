import RatingTag from "../../components/atoms/tags/RatingTag";
import { ResourceTabList } from "../../types/propTypes";
import {
  InventoryIcon,
  ResourcesIcon,
  TiktokIcon,
  UserIcon,
} from "../icons/icons";

// --- Nav Tabs ---
export const navbarTabs = [
  {
    icon: UserIcon,
    page: "USERS",
    link: "/users",
  },
  {
    icon: ResourcesIcon,
    page: "RESOURCES",
    link: "/resources",
  },
  {
    icon: InventoryIcon,
    page: "SURVEYS",
    link: "/surveys",
  },
  {
    icon: TiktokIcon,
    page: "CHALLENGES",
    link: "/challenges",
  },
];

// --- Resource Tabs ---
export const matesResourceTabs: ResourceTabList[] = [
  "PODCASTS",
  "VIDEOS",
  "PDFS",
  "LINKS",
];

// ----Surveys Labels ----
export const emojiResourceResults = {
  question: "Is this resource helpful?",
  isHelpfulCount: "😁",
  isUnhelpfulCount: "😒",
};

export const ratingResourceResults = {
  question: "Would you recommend this resource?",
  oneRatingCount: RatingTag({ rate: 1 }),
  twoRatingCount: RatingTag({ rate: 2 }),
  threeRatingCount: RatingTag({ rate: 3 }),
  fourRatingCount: RatingTag({ rate: 4 }),
  fiveRatingCount: RatingTag({ rate: 5 }),
};

export const signUpSurveyHeaders = {
  signUpReason: "What best describes your reason for visiting the Hub today?",
  "FIFO DIDO WORKERS": "FIFO DIDO Workers",
  "MANAGERS AND SUPERVISORS": "Managers & Supervisors",
  "FAMILY AND FRIENDS": "Family/Friends",
  "MENTAL HEALTH SERVICE PROVIDERS": "Mental Health Service Providers",
  OTHER: "Other",
  total: "Total",
};

export const welcomeSurveyHeaders = {
  question: "Are you one of our amazing FIFO DIDO workers?",
  trueCount: "Yes",
  falseCount: "No",
};

export const theHubFeedbackHeaders = {
  question: "What has your experience with Mates Hub been like?",
  isPositiveExperienceTrue: "😁",
  isPositiveExperienceFalse: "😒",
};
