const arrowDownFilterIcon = ({
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "9.511"}
        height={height ?? "5.462"}
        viewBox="0 0 9.511 5.462"
      >
        <path
          id="Path_1353"
          data-name="Path 1353"
          d="M-4170-4173.078l4.4,4.4,4.4-4.4"
          transform="translate(4170.351 4173.431)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
      </svg>
    </div>
  );
};

export default arrowDownFilterIcon;
