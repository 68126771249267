import { UpdateResourceArgs } from '../../../types/serviceTypes'
import { RFC } from '../../../types/propTypes'
import { useMatesDispatch } from '../../../app/hooks'
import {
	updatePDFContentLink,
	updatePDFURL,
	updateResourceIsActive,
	updateResourcePin,
	updateSlug,
	updateTitle,
} from '../../../features/resources/Resource/resourceSlice'
import { PinResourceField } from '../../molecules/molecules'
import { MatesButton, MatesTextInput, MatesUploadInput } from '../../atoms/atoms'
import { BinIcon } from '../../../assets/icons/icons'
import IsActiveField from '../../molecules/fields/IsActiveField'
import { formatSlug } from '../../../utils/formatter'

type PDFDetailProps = {
	resource: UpdateResourceArgs
	onSubmit: () => void
	onDelete: () => void
}

const PDFDetail: RFC<PDFDetailProps> = ({ resource, onSubmit, onDelete }) => {
	const { isPinned, title, pdf, isActive, slug } = resource
	const isValid = title && pdf?.description && pdf.file
	const dispatch = useMatesDispatch()

	return (
		<>
			<PinResourceField isPinned={isPinned} onChange={() => dispatch(updateResourcePin(!isPinned))} />
			<IsActiveField isActive={isActive} onChange={() => dispatch(updateResourceIsActive(!isActive))} />
			<MatesTextInput
				name='pdfTitle'
				label='TITLE'
				theme='dark'
				value={title}
				className={'pt-3'}
				onChange={(text) => dispatch(updateTitle(text))}
			/>
			<MatesTextInput
				name='slug'
				label='URL SLUG'
				theme='dark'
				value={slug}
				className={'pt-3'}
				onChange={(text) => dispatch(updateSlug(formatSlug(text)))}
			/>
			<MatesTextInput
				name='pdf-to-content'
				label='LINK TO CONTENT CREATOR'
				theme='dark'
				value={pdf?.description}
				onChange={(text) => dispatch(updatePDFContentLink(text))}
			/>
			<MatesUploadInput
				title='RESOURCE'
				theme='dark'
				type='file'
				source={pdf?.file}
				onUpload={(file) => dispatch(updatePDFURL(file ?? ''))}
				folder={'pdfs'}
				rootFolder={'other'}
			/>
			<div className='flex items-center mt-7'>
				<MatesButton
					text='SAVE'
					className={`base-button
          ${isValid ? 'active-button' : 'disabled-dark-button'} px-10 tracking-wider text-xl`}
					disabled={!isValid}
					onSubmit={onSubmit}
				/>
				<div
					className='bg-MatesRed rounded-[3px]
          h-full p-4 ml-5 cursor-pointer'
					onClick={onDelete}
				>
					<BinIcon />
				</div>
			</div>
		</>
	)
}

export default PDFDetail
