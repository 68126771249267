import { useInfiniteQuery, useQuery } from "react-query";
import ChallengeStep from "../challenge/ChallengeStep";
import ChallengeService from "../../../services/react-query/ChallengeService";
import { Challenge } from "../../../types/stateTypes";
import PastChallengeCard from "../../atoms/cards/PastChallengeCard";
import { useMatesDispatch } from "../../../app/hooks";
import { selectChallenge, selectNewChallenge } from "../../../features/challenges/challengeSlice";
import { CHALLENGE_KEY, DEFAULT_PAGE_SIZE } from "../../../assets/data/constants";
import { getItemsFromPagination, getNextPageParam } from "../../../utils/query/query";
import InfiniteScroll from "react-infinite-scroller";
import { CardLoading } from "../../atoms/atoms";

const TikTokList = () => {
  const dispatch = useMatesDispatch();

  const {
    data: pastData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = 1 }) =>
      ChallengeService.getChallenges({
        page: pageParam,
        take: DEFAULT_PAGE_SIZE,
        filter: "PAST",
      }),
    getNextPageParam: getNextPageParam(DEFAULT_PAGE_SIZE),
    queryKey: [CHALLENGE_KEY, "PAST"],
  });

  const { data: activeData } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      ChallengeService.getChallenges({
        page: pageParam,
        take: DEFAULT_PAGE_SIZE,
        filter: "ACTIVE",
      }),
    queryKey: [CHALLENGE_KEY, "ACTIVE"],
  });

  const pastChallenges = getItemsFromPagination(pastData);
  const activeChallenges = activeData ? activeData.data.data : [];
  const currentChallenge: Challenge | undefined = activeChallenges.find((challenge) => !challenge.winnerAnnouncementDate);
  const votingChallenge: Challenge | undefined = activeChallenges.find((challenge) => !!challenge.winnerAnnouncementDate);

  const onNewChallenge = () => dispatch(selectNewChallenge());
  const onViewChallenge = (challenge: Challenge) => dispatch(selectChallenge(challenge));

  const renderStepOne = () => {
    const subtitle = currentChallenge ? "Choose 5 videos before closing the submissions." : "Start a new challenge!";
    return (
      <ChallengeStep
        step="Step 1"
        stepType="CURRENT"
        title="Current Challenge"
        subtitle={subtitle}
        className=""
        challenge={currentChallenge}
        onNewChallenge={onNewChallenge}
        onViewChallenge={onViewChallenge}
      />
    );
  };

  const renderStepTwo = () => {
    const subtitle = votingChallenge ? "Announce a winner by ending the voting." : "You can now end your current challenge and move to this step.";
    return (
      <ChallengeStep
        step="Step 2"
        stepType="VOTING"
        title="In For Voting"
        subtitle={subtitle}
        className=""
        challenge={votingChallenge}
        onViewChallenge={onViewChallenge}
      />
    );
  };

  const renderPastChallenges = () => {
    if (!pastChallenges.length) return <div className="text-lg font-condensed italic">No past challenges found.</div>;
    return pastChallenges.map((challenge, index) => {
      return <PastChallengeCard challenge={challenge} key={index} />;
    });
  };

  return (
    <div className={"xl:w-[70%] w-[80%] mx-auto flex flex-col gap-10 overflow-y-auto h-full pb-[200px]"}>
      <div className="text-MatesWhite">
        <p className="font-compressed font-bold text-5xl tracking-wide">MATES COMMUNITY CHALLENGE</p>
        <p className="font-condensed text-lg text-MatesWhite/80">View current and past community challenges.</p>
      </div>

      <div className="grid grid-cols-2 text-MatesWhite ">
        {renderStepOne()}
        {renderStepTwo()}
      </div>

      <div className="flex flex-col gap-10 text-MatesWhite">
        <hr className="opacity-20" />
        <h2 className="text-[40px] font-bold uppercase leading-none">Past Challenges</h2>
        <InfiniteScroll
          hasMore={hasNextPage}
          loadMore={() => fetchNextPage()}
          loader={<CardLoading key={0} />}
          useWindow={false}
          threshold={50}
          className="grid grid-cols-4 gap-2"
        >
          {renderPastChallenges()}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default TikTokList;
