import { useState } from "react";
import { IntRange, RFC } from "../../../types/propTypes";
import { MatesAsyncVideo } from "../atoms";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import {
  challengeErrorsSelector,
  updateChallengeLinkError,
} from "../../../features/challenges/challengeSlice";

type ChallengeWinnerCardProps = {
  index: IntRange<0, 5>;
  source: string;
};

const ChallengeWinnerCard: RFC<ChallengeWinnerCardProps> = ({
  index,
  source,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const errors = useMatesSelector(challengeErrorsSelector);
  const dispatch = useMatesDispatch();

  return (
    <div
      className="base-tiktok-container aspect-tiktok mt-3 overflow-hidden"
      onClick={() => setIsPlaying(!isPlaying)}
    >
      <MatesAsyncVideo
        fileName={source}
        isVideoError={errors[index]}
        setIsVideoError={(error) =>
          dispatch(updateChallengeLinkError({ index: index, error }))
        }
      />
    </div>
  );
};

export default ChallengeWinnerCard;
