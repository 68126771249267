const pinIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "11.397"} 
                height={height ?? "18.995"} 
                viewBox="0 0 11.397 18.995"
            >
                <path 
                    id="push_pin_FILL0_wght400_GRAD0_opsz48" 
                    d="M21.355,15.307,23.4,17.136V18.56H18.411v5.722l-.712.712-.712-.712V18.56H12V17.136l1.9-1.828V7.425H12.712V6h9.83V7.425H21.355Zm-7.432,1.828h7.408l-1.4-1.306V7.425H15.324V15.83ZM17.627,17.136Z" 
                    transform="translate(-12 -6)" 
                    fill="#ed1941" 
                />
            </svg>
        </div>
    )
}

export default pinIcon