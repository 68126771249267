import { useEffect, useRef, useState } from "react";
import { RFC } from "../../../types/propTypes";
import { Helmet } from "react-helmet";
import { MatesLoading } from "../atoms";
import { useMatesDispatch } from "../../../app/hooks";
import { playTikTokVideo } from "../../../features/challenges/challengeSlice";
import withRetry from "fetch-retry";
import { openModal } from "../../../features/cores/modalSlice";
import { MODAL_TYPES } from "../../../assets/data/enums";

type TikTokPlayerProps = {
  type: "image" | "video";
  videoUrl: string;
  error: TypeError | null;
  setError: (error: TypeError | null) => void;
};

const TIKTOK_OEMBED_BASE_URL = `https://www.tiktok.com/oembed`;
const fetchRetry = withRetry(window.fetch);

const TikTokPlayer: RFC<TikTokPlayerProps> = ({
  type,
  videoUrl,
  error,
  setError,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scriptSrc, setScriptSrc] = useState<string | undefined>(undefined);
  const [html, setHTML] = useState<string | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);

  const ref = useRef(null);

  const dispatch = useMatesDispatch();

  const tiktokStyle = {
    cursor: "pointer",
    transform: isHovered ? "scale(1.03)" : "scale(1.0)",
    transition: "all 0.3s ease-in-out",
  };

  const getTikTokVideoData = async () => {
    try {
      const tiktokData = await fetchRetry(
        `${TIKTOK_OEMBED_BASE_URL}?url=${videoUrl}`,
        {
          retries: 3,
          method: "GET",
        }
      );
      const convertedTikTokData = await tiktokData.json();
      setError(null);
      const htmlString = convertedTikTokData.html;
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;
      const scriptTag = tempElement.getElementsByTagName("script")[0];

      setScriptSrc(scriptTag && scriptTag.src);
      setHTML(htmlString.substr(0, htmlString.indexOf("<script")));
      setThumbnail(convertedTikTokData.thumbnail_url);
    } catch (error) {
      setError(error as TypeError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!("MutationObserver" in window)) return setLoaded(true);
    const elem = ref.current;
    const observer = new MutationObserver((mutationList) => {
      const iframeAdded = mutationList.reduce<Node | undefined>((acc, curr) => {
        const iframe = Array.from(curr.addedNodes).find(
          (node) => node.nodeName === "IFRAME"
        );
        if (iframe) {
          acc = iframe;
        }
        return acc;
      }, undefined);

      if (iframeAdded) {
        iframeAdded.addEventListener("load", () => setLoaded(true));
      }
    });

    if (elem) {
      observer.observe(elem, {
        childList: true,
        attributes: true,
        subtree: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    getTikTokVideoData();
  }, [videoUrl]);

  if (isLoading)
    return (
      <div className="base-tiktok-container aspect-tiktok text-3xl tracking-wide">
        <MatesLoading shape="rounded" />
      </div>
    );

  if (error)
    return (
      <div className="base-tiktok-container aspect-tiktok text-3xl tracking-wide">
        Please enter a valid URL.
      </div>
    );

  return (
    <>
      {type === "video" ? (
        <>
          <Helmet>
            <script id="ttEmbedder" async src={scriptSrc} />
          </Helmet>
          <div
            ref={ref}
            style={{
              display: html ? "flex" : "none",
              width: "320px",
            }}
            dangerouslySetInnerHTML={{ __html: html || "" }}
          />
        </>
      ) : (
        <div
          style={{
            transform: "transform 0.3s ease-in-out",
            overflow: "hidden",
            borderRadius: "15px",
          }}
        >
          <img
            src={thumbnail}
            alt={"tiktok-thumbnail"}
            style={tiktokStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.TIKTOK_VIDEO,
                })
              );
              dispatch(playTikTokVideo(videoUrl));
            }}
          />
        </div>
      )}
    </>
  );
};

export default TikTokPlayer;
