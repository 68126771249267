import { MatesTikTokUploadInput } from "../../atoms/atoms";
import { convertChallengeWinnerIndex } from "../../../utils/converter";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DEFAULT_PAGE_SIZE, TOP_PICK_KEY } from "../../../assets/data/constants";
import ChallengeService from "../../../services/react-query/ChallengeService";

type Props = {
  challengeId: string;
};

const ChallengeTopPicksField = ({ challengeId }: Props) => {
  const queryClient = useQueryClient();

  const { data: topPickData } = useQuery({
    queryFn: ({ pageParam = 1 }) =>
      ChallengeService.getTopPicks({
        page: pageParam,
        take: DEFAULT_PAGE_SIZE,
        challengeId: challengeId,
      }),
    enabled: !!challengeId,
    queryKey: [TOP_PICK_KEY, challengeId],
  });
  const { mutate: uploadTopPick } = useMutation({
    mutationFn: ChallengeService.uploadTopPick,
    onSuccess() {
      queryClient.invalidateQueries([TOP_PICK_KEY, challengeId]);
    },
  });
  const { mutate: updateTopPick } = useMutation({
    mutationFn: ChallengeService.updateTopPick,
    onSuccess() {
      queryClient.invalidateQueries([TOP_PICK_KEY, challengeId]);
    },
  });
  const topPicks = topPickData ? topPickData.data.data : [];
  const fieldCount = Math.min(5, topPicks.length + 1);

  return (
    <>
      {Array(fieldCount)
        .fill("")
        .map((_, index) => (
          <MatesTikTokUploadInput
            key={topPicks[index]?.id ?? index}
            source={topPicks[index]?.link ?? ""}
            cardType={"winner"}
            permissionType={"write"}
            index={convertChallengeWinnerIndex(index)}
            onUpload={(link) => {
              if (!link) return;
              topPicks[index]
                ? updateTopPick({ id: topPicks[index]?.id ?? "", data: { link: link } })
                : uploadTopPick({
                    data: {
                      challengeId,
                      link: link,
                    },
                  });
            }}
          />
        ))}
    </>
  );
};

export default ChallengeTopPicksField;
