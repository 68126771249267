import React from "react";
import { ArrowDownIcon } from "../../../assets/icons/icons";
import { Disclosure } from "@headlessui/react";

interface MatesAccordionProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  suffixLabel: string;
}

function MatesAccordion({
  children,
  header,
  suffixLabel,
}: MatesAccordionProps) {
  return (
    <div className="mx-auto w-full bg-bg-MatesBlack py-2">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="flex w-full items-center justify-between pl-0 pr-4 py-2 text-left text-sm 
              font-medium hover:bg-MatesBorder rounded-md transition-all duration-400"
            >
              <div className="flex items-center">
                <div className="flex items-center">{header}</div>
              </div>
              <div className="flex items-center gap-x-3 text-MatesWhite/60 uppercase font-condensed text-lg">
                {suffixLabel}
                {open ? (
                  <ArrowDownIcon className="w-5 h-5 group-hover:text-white" />
                ) : (
                  <ArrowDownIcon className="w-5 h-5 group-hover:text-white rotate-180 shrink-0" />
                )}
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              {children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="h-[1px] w-full bg-MatesDarkGrey mt-2"></div>
    </div>
  );
}
export default MatesAccordion;
