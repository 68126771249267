import { useState } from 'react'
import { useLazyGetPresignedUploadUrlQuery } from '../../../services/FileService'
import { InputChangeEventHandler, RFC } from '../../../types/propTypes'
import { MatesAsyncImage, MatesButton } from '../atoms'
import axios from 'axios'
import { formatFileName } from '../../../utils/formatter'

type MatesUploadInputProps = {
    title:      string
    theme:      'light' | 'dark'
    type:       'image' | 'file' | 'video' | 'audio'
    source?:    string
    onUpload:   (file: string | undefined) => void,
    folder:     'pdfs' | 'video_thumbnails',
    rootFolder: 'images' | 'videos' | 'other'
}

const MatesUploadInput:RFC<MatesUploadInputProps> = ({
    title,
    theme,
    type,
    source,
    onUpload,
    folder,
    rootFolder,
}) => {
    const [ isUploading, setIsUploading ] = useState(false)
    const [ loaded, setLoaded ] = useState(0)
    const [ isUploadError, setIsUploadError ] = useState(false)

    const [ getPresignedUpload ] = useLazyGetPresignedUploadUrlQuery()

    const switchAcceptType = () => {
        switch (type) {
            case 'image': return 'image/*, .heic'
            case 'file':  return 'application/pdf,application/vnd.ms-excel'
            case 'video': return 'video/*' 
            case 'audio': return 'audio/mp3,audio/*;capture=microphone'
            default:      return null
        }
    }

    const handleUpload:InputChangeEventHandler = async ({ target }) => {
        try {
            onUpload('')
            setIsUploadError(false)
            setIsUploading(true)
            const files = target.files
            if (!files) return
            const file = files[0]
            if (!file) return

            const data = await getPresignedUpload({
                fileName: file.name,
                folder: folder,
                rootFolder: rootFolder,
              }).unwrap()
            if (!data) return
              
            const uploading = await axios.put(data.url, file, {
                headers: {
                  'Content-Type': file.type,
                },
                onUploadProgress: e => {
                  setLoaded(parseInt(((e.loaded * 100) / (e.total ?? 0)).toFixed()))
                  if (e.loaded === e.total) setIsUploading(false)
                }
            })
            if (uploading.status !== 200) throw Error
            onUpload(data.fileName)
        } catch (error) {
            setIsUploadError(true)
        } finally {
            setIsUploading(false)
        }
    }

    const renderThumbnail = () => {
        if (!source) {
            return (
                (loaded > 0 && loaded < 100) && (
                <div 
                    className={`rounded-lg shadow-md 
                    w-[10em] aspect-video mr-3`}
                >
                        <div
                            className='flex justify-center items-center h-full font-bold
                            rounded-lg transition-all duration-200 cursor-no-drop text-xl'
                        >
                            {loaded}%
                        </div>
                </div>
                )
            )
        } else {
            return (
                <div 
                    className={`rounded-lg shadow-md 
                    w-[10em] aspect-video mr-3`}>
                    <MatesAsyncImage fileName={source}/>
                </div>
            )
        }
    }

    const renderSourceName = () => {
        if (!source) {
            return (
                (loaded > 0 && loaded < 100) && (
                    <div
                        className={`rounded-lg shadow-md flex justify-center overflow-y-hidden
                    items-center bg-[#F4F4F4] min-w-[10em] max-w-[20em] px-3 mr-3 font-semibold text-lg`}
                    >
                        {loaded}%
                    </div>
                )
            )
        } else {
            return (
                <div
                    className='rounded-lg shadow-md flex justify-center overflow-y-hidden font-condensed
                    items-center bg-[#F4F4F4] min-w-[10em] max-w-[20em] px-3 mr-3 truncate'
                >
                    {formatFileName(source)}
                </div>
            )
        }
    }

    return (
        <div className='mt-6 mb-2'>
            <p 
                className={`font-medium text-lg
                ${theme === 'light' ? 'text-MatesBlack' : 'text-MatesRed'}`}>
                {title}
            </p>
            <div className='flex'>
                {type === 'image' 
                    ? renderThumbnail() 
                    : renderSourceName()
                }
                <div className='w-[105px] flex items-center flex-wrap relative'>
                    <input 
                        type='file'
                        accept={`${switchAcceptType()}`}
                        className='absolute inset-0 opacity-0 z-50 cursor-pointer file:cursor-pointer'
                        onChange={handleUpload}
                        disabled={isUploading}
                    />
                    <MatesButton 
                        text={`${source ? 'CHANGE' : 'UPDATE'}`}
                        className={`base-button 
                        ${theme === 'light' 
                            ? 'upload-light-button' 
                            : 'upload-dark-button'
                        }`}
                        onSubmit={() => {}}
                    />
                </div>
            </div>
            {isUploadError && (
                <div className='ml-2 text-MatesRed'>
                    Failed to upload the file
                </div>
            )}
        </div>
    )
}

export default MatesUploadInput