import { RFC } from '../../../types/propTypes'
import { ReactElement } from 'react'
import { MatesLoading } from '../atoms'

type MatesButtonProps = {
    text:       string
    icon?:      ReactElement
    disabled?:  boolean
    isLoading?: boolean
    className:  string
    onSubmit:   () => void
}

const MatesButton:RFC<MatesButtonProps> = ({
    text,
    icon,
    disabled,
    isLoading,
    className,
    onSubmit,
}) => {
    return (
        <>
            <button
                type='button'
                data-mdb-ripple='true'
                data-mdb-ripple-color='light'
                data-mdb-ripple-duration='1s'
                data-mdb-ripple-radius='30'
                className={className}
                disabled={disabled}
                onClick={onSubmit}
            >
                {isLoading ? (
                    <div
                        className='flex justify-center items-center
                        w-full text-xl px-4 py-2'
                    >
                        <MatesLoading shape='dots' />
                    </div>
                ) : (
                    <>
                        {icon && (
                            <div className='mr-1'>
                                {icon}
                            </div>
                        )}
                        {text}
                    </>
                )}
            </button>
        </>
    )
}

export default MatesButton