const ThumbUpIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        width={width ?? "21"}
        height={height ?? "21"}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5144 17.9376H6.31008V7.43762L12.1154 1.66602L12.8557 2.40637C12.9466 2.49724 13.022 2.61756 13.082 2.76731C13.142 2.91707 13.1721 3.05813 13.1721 3.19051V3.41262L12.2432 7.43762H18.1057C18.5219 7.43762 18.8896 7.5972 19.2087 7.91636C19.5279 8.2355 19.6875 8.60317 19.6875 9.01936V10.4328C19.6875 10.5237 19.6757 10.6218 19.6521 10.7273C19.6286 10.8327 19.6022 10.9309 19.573 11.0217L17.0658 16.938C16.9402 17.2185 16.7298 17.4552 16.4348 17.6482C16.1398 17.8411 15.833 17.9376 15.5144 17.9376ZM7.62256 16.6251H15.5144C15.5761 16.6251 15.6392 16.6083 15.7037 16.5746C15.7682 16.541 15.8173 16.4849 15.851 16.4063L18.375 10.5001V9.01936C18.375 8.94083 18.3497 8.87632 18.2992 8.82583C18.2488 8.77535 18.1843 8.7501 18.1057 8.7501H10.5841L11.6812 3.95442L7.62256 7.99627V16.6251ZM6.31008 7.43762V8.7501H3.49998V16.6251H6.31008V17.9376H2.1875V7.43762H6.31008Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ThumbUpIcon;
