const uploadIcon = ({
  height,
  width,
  className,
  color = "#ed1941",
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "12.345"}
        height={height ?? "12.558"}
        viewBox="0 0 12.345 12.558"
      >
        <path
          id="upload_FILL0_wght600_GRAD0_opsz48"
          d="M8.021,18.558A1.425,1.425,0,0,1,6.6,17.137V14.153H8.021v2.984h9.521V14.153h1.4v2.984a1.368,1.368,0,0,1-.417,1,1.348,1.348,0,0,1-.986.417Zm4.05-3.41V8.718L10.01,10.8,9,9.783,12.781,6l3.783,3.783L15.552,10.8l-2.06-2.078v6.43Z"
          transform="translate(-6.6 -6)"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default uploadIcon;
