import { RFC } from "../../../types/propTypes";
import { ReactElement } from "react";
import { MatesLoading } from "../atoms";

type MatesFlexiButtonProps = {
  text: string;
  icon?: ReactElement;
  disabled?: boolean;
  isLoading?: boolean;
  className: string;
  onSubmit: () => void;
};

const MatesFlexiButton: RFC<MatesFlexiButtonProps> = ({ text, icon, disabled, isLoading, className, onSubmit }) => {
  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          className="flex justify-center items-center
                  w-full text-xl px-4 py-2"
        >
          <MatesLoading shape="dots" />
        </div>
      );
    }

    return (
      <>
        {icon && { icon }}
        {text}
      </>
    );
  };

  const disabledStyle = disabled ? "cursor-not-allowed brightness-50 contrast-75" : "";

  return (
    <>
      <button
        type="button"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        data-mdb-ripple-duration="1s"
        data-mdb-ripple-radius="30"
        className={`flexi-base-button ${disabledStyle} ${className}`}
        disabled={disabled}
        onClick={onSubmit}
      >
        {renderContent()}
      </button>
    </>
  );
};

export default MatesFlexiButton;
