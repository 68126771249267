import { FormikType, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { CREATE_RESOURCE_PAGES } from '../../../assets/data/enums'
import { MatesButton, MatesTitleBlock, RadioGroupButtons } from '../../atoms/atoms'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { userGroupsSelector } from '../../../features/resources/userGroupsSlice'
import { AiOutlineArrowLeft as BackArrow } from 'react-icons/ai'
import { closeModal } from '../../../features/cores/modalSlice'

const SelectUser:RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
    const userGroupsState = useMatesSelector(userGroupsSelector)
    const userGroupTitleList = userGroupsState.map(userGroup => userGroup.title)
    const { userType } = formik.values
    const dispatch = useMatesDispatch()

    const selectUserGroup = (userType: string) => {
        formik.setFieldValue('userType', userType)
        const selectedUserType = userGroupsState.find(userGroup => userGroup.title === userType)
        formik.setFieldValue('userTypeId', selectedUserType?.id)
    }
    
    return (
        <div className='h-fit transition-all duration-200'>
            <BackArrow 
                className='text-[2em] my-3 cursor-pointer'
                onClick={() => dispatch(closeModal())}
            />
            <MatesTitleBlock 
                title='SELECT USER'
                description='Select from the list below which user this resource will belong too.'
                className='pb-3'
            />
            <RadioGroupButtons 
                list={userGroupTitleList}
                selectedItem={userType}
                onSelectItem={(type) => selectUserGroup(type)}
            />
            <MatesButton 
                text='CONTINUE'
                className={`base-button
                ${userType 
                    ? 'active-button' 
                    : 'disabled-light-button'
                }`}
                disabled={!userType}
                onSubmit={() => formik.setFieldValue('currentPage', CREATE_RESOURCE_PAGES.SELECT_CATEGORY)}
            />
        </div>
    )
}

export default SelectUser