import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { InitialUserGroupState } from "../../assets/data/InitialState";
import { UserGroup } from "../../types/stateTypes";

export type UserGroupSliceType = {
  userGroup: UserGroup;
  userGroups: UserGroup[];
};

export type UpdateUserGroupArgs = {
  key: keyof Omit<UserGroup, "isActive" | "sequence">;
  value: string;
};

export const userGroupsSlice = createSlice({
  name: "userGroups",
  initialState: InitialUserGroupState,
  reducers: {
    clearUserGroup: (state) => {
      state.userGroup = InitialUserGroupState.userGroup;
    },
    selectUserGroup: (state, action: PayloadAction<UserGroup>) => {
      state.userGroup = action.payload;
    },
    storeUserGroups: (state, action: PayloadAction<UserGroup[]>) => {
      state.userGroups = action.payload;
      if (action.payload.length > 0) {
        const firstUserGroup = action.payload[0] as UserGroup;
        state.userGroup = firstUserGroup;
      }
    },
    updateUserGroup: (state, action: PayloadAction<UpdateUserGroupArgs>) => {
      state.userGroup[action.payload.key] = action.payload.value;
    },
    storeUserGroup: (state, action: PayloadAction<UserGroup>) => {
      state.userGroup = action.payload;
    },
  },
});

export const userGroupIdSelector = (state: RootState) =>
  state.userGroups.userGroup.id;
export const userGroupSelector = (state: RootState) =>
  state.userGroups.userGroup;
export const userGroupsSelector = (state: RootState) =>
  state.userGroups.userGroups;
export const {
  clearUserGroup,
  selectUserGroup,
  storeUserGroups,
  updateUserGroup,
  storeUserGroup,
} = userGroupsSlice.actions;
export default userGroupsSlice.reducer;
