import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { SuccessIcon } from '../../../assets/icons/icons'
import { closeModal, modalSelector } from '../../../features/cores/modalSlice'
import { MatesButton } from '../../atoms/atoms'


const SuccessModal = () => {
  const { title, body } = useMatesSelector(modalSelector)
  const dispatch = useMatesDispatch()

  return (
    <div className='w-[25em] flex flex-col justify-center items-center py-3 px-6'>
      <SuccessIcon width={'65'} height={'65'} />
      <p className='text-3xl font-Title font-bold text-MatesBlack my-3 text-center'>
        {title}
      </p>
      <p className='w-full text-center text-xl mb-7'>
        {body}
      </p>
      <MatesButton
        text='CLOSE'
        className='modal-close-button'
        onSubmit={() => dispatch(closeModal())}
      />
    </div>
  )
}

export default SuccessModal