import { Dialog } from '@headlessui/react'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { closeModal, modalSelector } from '../../../features/cores/modalSlice'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import {
	AllFeedbackDataModal,
	CommentsRatingResults,
	CommentsSurveyResultsModal,
	ConnectorAsistDataModal,
	CreateCategoryModal,
	CreateResourceModal,
	DeleteModal,
	DownloadResourceResultsModal,
	DownloadSurveyResultsModal,
	EditCategoryModal,
	EditUserGroupModal,
	FailModal,
	SuccessModal,
	TikTokVideoModal,
} from '../../molecules/molecules'
import DownloadUserResultsModal from '../../molecules/modals/DownloadUserResultsModal'
import DuplicateResourceModal from '../../molecules/modals/DuplicateResourceModal'
import { clearSelectedCategories, clearSelectedResources } from '../../../features/resources/Resource/duplicateResourceSlice'
import DownloadWelcomeSurveyResultsModal from '../../molecules/modals/DownloadWelcomeSurveyResultsModal'
import DownloadHubFeedbackModal from '../../molecules/modals/DownloadHubFeedbackModal'
import CloseSubmissionModal from '../../molecules/modals/CloseSubmissionModal'
import AnnounceWinnerModal from '../../molecules/modals/AnnounceWinnerModal'
import PublishChallengeModal from '../../molecules/modals/PublishChallengeModal'

const MatesModal = () => {
	const modalState = useMatesSelector(modalSelector)
	const dispatch = useMatesDispatch()

	const renderModal = () => {
		switch (modalState.modalType) {
			case MODAL_TYPES.ALL_FEED_BACK:
				return <AllFeedbackDataModal />
			case MODAL_TYPES.CREATE_RESOURCE:
				return <CreateResourceModal />
			case MODAL_TYPES.DISPLAY_CONNECTORS:
				return <ConnectorAsistDataModal />
			case MODAL_TYPES.DISPLAY_FEEDBACK_SURVEY_COMMENTS_RESULTS:
				return <CommentsSurveyResultsModal />
			case MODAL_TYPES.DISPLAY_RESOURCE_RESULTS_COMMENTS:
				return <CommentsRatingResults />
			case MODAL_TYPES.CREATE_CATEGORY:
				return <CreateCategoryModal />
			case MODAL_TYPES.EDIT_USER_GROUP:
				return <EditUserGroupModal />
			case MODAL_TYPES.EDIT_CATEGORY:
				return <EditCategoryModal />
			case MODAL_TYPES.TIKTOK_VIDEO:
				return <TikTokVideoModal />
			case MODAL_TYPES.DOWNLOAD_RESOURCE_RESULTS:
				return <DownloadResourceResultsModal />
			case MODAL_TYPES.DOWNLOAD_SURVEY_RESULTS:
				return <DownloadSurveyResultsModal />
			case MODAL_TYPES.DOWNLOAD_WELCOME_SURVEY_RESULTS:
				return <DownloadWelcomeSurveyResultsModal />
			case MODAL_TYPES.DOWNLOAD_HUB_FEEDBACK_RESULTS:
				return <DownloadHubFeedbackModal />
			case MODAL_TYPES.DOWNLOAD_USER_RESULTS:
				return <DownloadUserResultsModal />
			case MODAL_TYPES.DUPLICATE_RESOURCE:
				return <DuplicateResourceModal />
			case MODAL_TYPES.SUCCESS:
				return <SuccessModal />
			case MODAL_TYPES.FAIL:
				return <FailModal />
			case MODAL_TYPES.DELETE:
				return <DeleteModal />
			case MODAL_TYPES.CLOSE_SUBMISSION:
				return <CloseSubmissionModal />
			case MODAL_TYPES.ANNOUNCE_WINNER:
				return <AnnounceWinnerModal />
			case MODAL_TYPES.PUBLISH_CHALLENGE:
				return <PublishChallengeModal />
			default:
				return null
		}
	}

	const closeMatesModal = () => {
		dispatch(closeModal())
		dispatch(clearSelectedResources())
		dispatch(clearSelectedCategories())
	}

	return (
		<Dialog open={modalState.isDisplayed} onClose={() => closeMatesModal()} className="fixed inset-0 z-50 overflow-y-auto flex justify-center items-center">
			<Dialog.Overlay className="fixed inset-0 bg-MatesBlack/40" />
			<Dialog.Panel className="relative bg-white rounded-[3px]">{renderModal()}</Dialog.Panel>
		</Dialog>
	)
}

export default MatesModal
