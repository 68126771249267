import { useState } from "react";
import { useMatesSelector } from "../../../app/hooks";
import { tikTokURLSelector } from "../../../features/challenges/challengeSlice";
import { VideoPlayer } from "../../atoms/atoms";

const TikTokVideoModal = () => {
  const [error, setError] = useState<boolean | null>(null);
  const tiktokURL = useMatesSelector(tikTokURLSelector);
  return (
    <div className="aspect-tiktok w-fit p-5">
      <VideoPlayer
        videoURL={tiktokURL}
        isPlaying={false}
        endedVideo={() => {}}
      />
    </div>
  );
};

export default TikTokVideoModal;
