type EmptyDetailBlockProps = {
  detailText: string;
}
const EmptyDetailBlock = ({detailText}: EmptyDetailBlockProps) => {
  return (
    <div 
        className='w-full h-full flex items-center tracking-wide
        justify-center text-3xl text-MatesBorder'>
        {detailText}
    </div>
  )
}

export default EmptyDetailBlock