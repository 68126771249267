import { RFC } from '../../../types/propTypes'
import { Resource } from '../../../types/stateTypes'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { resourceIdSelector, storeResource } from '../../../features/resources/Resource/resourceSlice'
import { TitleCard } from '../atoms'
import { PDFIcon } from '../../../assets/icons/icons'
import { cardDateFormatter } from '../../../utils/formatter'
import { useLazyGetPresignedDownloadUrlQuery } from '../../../services/FileService'

type PDFCardProps = {
  resource: Resource
}

const PDFCard:RFC<PDFCardProps> = ({ resource }) => {
  const { id, title, pdf, updatedAt, isPinned } = resource
  const resourceId = useMatesSelector(resourceIdSelector)
  const dispatch = useMatesDispatch()

  const [ downloadFile ] = useLazyGetPresignedDownloadUrlQuery()

  const openPDF = async () => {
    try {
      const data = await downloadFile({ fileName: pdf?.file }).unwrap()
      if (!data) throw Error
      const pdfWindow = window.open()
      if (!pdfWindow) throw Error
      pdfWindow.location.href = data.url
    } catch (error) {
      console.error({ error })
    }
  }

  return (
    <div className='relative w-full h-full'>
      <TitleCard
        isSelected={resourceId === id}
        isAnimationOn={true}
        onClick={() => dispatch(storeResource(resource))}
      >
        <div className='flex flex-col justify-between'>
          <div>
            <div className='flex items-center'>
              <PDFIcon 
                className='mr-3 hover:scale-110 transition-all duration-200'
                onClick={openPDF}
              />
              <p className='uppercase text-3xl line-clamp-2 font-condensed'>
                {title}
              </p>
            </div>
            <p className='text-MatesWhite/80 font-condensed'>
              {pdf?.description}
            </p>
            <div className='flex items-center text-MatesWhite/80 text-base font-condensed'>
              {cardDateFormatter(updatedAt)}
            </div>
          </div>
          {isPinned && (
            <div
              className={`absolute right-0 top-2 bottom-2 w-[8px] rounded-r-[3px]
              ${resourceId === id ? 'bg-MatesWhite' : 'bg-MatesRed'}`}
            ></div>
          )}
        </div>
      </TitleCard>
    </div>
  )
}

export default PDFCard