import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { InitialCreateCategoryState } from '../../../assets/data/InitialState'
import { closeModal, openModal } from '../../../features/cores/modalSlice'
import { CreateCategorySchema } from '../../../utils/validationSchema'
import { MatesButton, MatesTextArea, MatesTextInput, MatesTitleBlock, MatesUploadInput } from '../../atoms/atoms'
import { AiOutlineArrowLeft as BackArrow } from 'react-icons/ai'
import { useCreateCategoryMutation } from '../../../services/CategoryService'
import asyncTimeout from '../../../utils/asyncTimeout'
import { MODAL_TYPES } from '../../../assets/data/enums'
import { userGroupIdSelector } from '../../../features/resources/userGroupsSlice'
import { IconTextSwitchRow } from '../molecules'
import { closeOverlay, openOverlay } from '../../../features/cores/overlaySlice'
import { categoryLengthSelector } from '../../../features/resources/categoriesSlice'

const CreateCategoryModal = () => {
  const userGroupIdState = useMatesSelector(userGroupIdSelector)
  const categoryLengthState = useMatesSelector(categoryLengthSelector)
  const dispatch = useMatesDispatch()

  const [ createCategory ] = useCreateCategoryMutation()

  const { 
    values, 
    errors, 
    touched, 
    handleSubmit, 
    setFieldValue, 
    isSubmitting, 
    setSubmitting 
  } = useFormik({
    initialValues: InitialCreateCategoryState,
    validationSchema: CreateCategorySchema,
    onSubmit: async (values) => {
      setSubmitting(true)
      try {
        dispatch(closeModal())
        dispatch(openOverlay({ text: 'Creating Category' }))
        const [ data ] = await Promise.all([
          createCategory({
            ...values,
            sequence: categoryLengthState + 1
          }).unwrap(),
          asyncTimeout(2000)
        ])

        if(!data) throw Error

        dispatch(openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: 'CATEGORY CREATED',
          body: 'You have successfully created a resource!'
        }))
      } catch (error) {
        dispatch(openModal({
            modalType: MODAL_TYPES.FAIL,
            title: 'Failed to create the category',
            body: 'Please check your input details and try again.'
        }))
      } finally {
        setSubmitting(false)
        dispatch(closeOverlay())
      }
    }
  })

  useEffect(()=> {
    setFieldValue('userGroupId', userGroupIdState)
  },[userGroupIdState])

  const isValid = values.title && values.thumbnail

  return (
    <div className='h-fit w-[500px] px-6 py-3'>
        <BackArrow
          className='text-[2em] my-3 cursor-pointer'
          onClick={() => dispatch(closeModal())}
        />
        <MatesTitleBlock
          title='CREATE NEW CATEGORY'
          description='Create a new category by completing the information below.'
          className='pb-3'
        />
        <IconTextSwitchRow 
          icon={'hidden'}
          isActive={values.isHidden}
          text={'Category hidden'}
          onChange={() => setFieldValue('isHidden', !values.isHidden)}
        />
        <IconTextSwitchRow 
          icon={'available'}
          isActive={values.isAvailableToEveryone}
          text={'Available to everyone'}
          onChange={() => setFieldValue('isAvailableToEveryone', !values.isAvailableToEveryone)}
        />
        <IconTextSwitchRow 
          icon={'training'}
          isActive={values.isTrainingModule}
          text={'Training module'}
          onChange={() => setFieldValue('isTrainingModule', !values.isTrainingModule)}
        />
        <MatesTextInput
          name='categoryTitle'
          label='TITLE'
          theme='light'
          value={values.title}
          onChange={(text) => setFieldValue('title', text)}
          isTouched={touched.title}
          error={errors.title}
        />
        <MatesUploadInput 
          title='PICTURE THUMBNAIL'
          theme='light'
          type='image'
          source={values.thumbnail} 
          onUpload={(image) => setFieldValue('thumbnail', image ?? '')}
          folder={'video_thumbnails'}
          rootFolder={'images'}
        />
        <div className="pt-4">
          <MatesTextArea
            label="DESCRIPTION"
            theme="light"
            value={values.description}
            height="h-[80px]"
            size="text-lg"
            onChange={(text) => setFieldValue('description', text)}
          />
        </div>
        <MatesButton
          text='PUBLISH CATEGORY'
          className={`base-button mt-7
          ${isValid
              ? 'active-button'
              : 'disabled-light-button'
            }`}
          disabled={!isValid}
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
        />
    </div>
  )
}

export default CreateCategoryModal