import InfiniteScroll from 'react-infinite-scroller'
import { CardLoading } from '../../atoms/atoms'
import { ResourceTitles } from '../../../features/resources/Resource/ResourceTitles'
import { useMatesDispatch } from '../../../app/hooks'
import { ResourceType, RFC } from '../../../types/propTypes'
import { useEffect, useState } from 'react'
import { useLazyGetResourcesQuery } from '../../../services/ResourceService'
import { clearResources, setInitialResources, setResources } from '../../../features/resources/Resource/resourcesSlice'

type InfiniteResourceListProps = {
	categoryId: string
	resourceType?: ResourceType
}

const InfiniteResourceList: RFC<InfiniteResourceListProps> = ({ categoryId, resourceType }) => {
	const take = 5
	const [page, setPage] = useState(1)
	const [isLoadMore, setIsLoadMore] = useState(false)
	const [hasMore, setHasMore] = useState(true)
	const dispatch = useMatesDispatch()

	const [getResources, { isLoading, isError }] = useLazyGetResourcesQuery()

	useEffect(() => {
		setPage(1)
		loadMoreItems(5, 1)
	}, [resourceType, categoryId])

	const fetchMoreData = () => {
		if (!isLoadMore) loadMoreItems(take, page)
	}

	const loadMoreItems = async (take: number, page: number) => {
		if (!resourceType || !categoryId) return
		if (!isLoadMore) setIsLoadMore(true)
		try {
			const { count, resources } = await getResources({
				take,
				page,
				categoryId,
				resourceType,
			}).unwrap()

			if (!resources.length && page === 1) dispatch(clearResources())
			if (!resources || !resources.length) {
				setHasMore(false)
			} else {
				if (page === 1) {
					dispatch(setInitialResources({ resources, count }))
					setHasMore(true)
					setPage(2)
				} else if (resources.length < take) {
					dispatch(setResources({ resources, count }))
					setHasMore(false)
				} else {
					dispatch(setResources({ resources, count }))
					setHasMore(true)
					setPage(page + 1)
				}
			}

			setTimeout(() => {
				setIsLoadMore(false)
			}, 1000)
		} catch (error) {
			console.log({ error })
		}
	}

	if (!resourceType) return <div className='empty-list'>Select Resource to see more details</div>

	return (
		<div className='w-full h-[calc(100vh-20em)] overflow-y-auto overflow-x-hidden'>
			<InfiniteScroll loadMore={fetchMoreData} hasMore={hasMore} useWindow={false} loader={<CardLoading key={0} />} threshold={50}>
				<ResourceTitles isLoading={isLoading} isError={isError} resourceType={resourceType} />
			</InfiniteScroll>
		</div>
	)
}

export default InfiniteResourceList
