import { FormikType, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { MatesButton, MatesHorizontalLine, MatesLinkInput, MatesTextInput } from '../../atoms/atoms'

const LinkField: RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
	const { title, slug } = formik.values
	const { description, url } = formik.values.link

	let isValid = title && description && url

	return (
		<div>
			<MatesTextInput name='linkTitle' label='TITLE' theme='light' value={title} onChange={(text) => formik.setFieldValue('title', text)} />
			<MatesTextInput name='slug' label='SLUG' theme='light' value={slug} onChange={(text) => formik.setFieldValue('slug', text)} />
			<MatesTextInput
				name='link-to-content'
				label='LINK TO CONTENT CREATOR'
				theme='light'
				value={description}
				onChange={(text) => formik.setFieldValue('link.description', text)}
				isTouched={formik.touched.link?.description}
				error={formik.errors.link?.description}
			/>
			<MatesHorizontalLine />
			<MatesLinkInput
				title='PASTE LINK'
				theme='light'
				source={url}
				placeholder='Paste link here'
				onChange={(link) => formik.setFieldValue('link.url', link)}
				isTouched={formik.touched.link?.url}
				error={formik.errors.link?.url}
			/>
			<MatesButton
				text='PUBLISH RESOURCE'
				className={`base-button mt-7
        ${isValid ? 'active-button' : 'disabled-light-button'}`}
				disabled={!isValid || formik.isSubmitting}
				onSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
			/>
		</div>
	)
}

export default LinkField
