import { RFC } from "../../../types/propTypes";
import { ChallengeSampleCard, MatesButton, MatesLoading } from "../atoms";
import ThumbUpIcon from "../../../assets/icons/svg/thumbUpIcon";
import { useMutation, useQueryClient } from "react-query";
import { TOP_PICK_KEY } from "../../../assets/data/constants";
import ChallengeService, { TopPick } from "../../../services/react-query/ChallengeService";

type Props = {
  topPick: TopPick;
};

const MatesTikTokVoteInput: RFC<Props> = ({ topPick }) => {
  const queryClient = useQueryClient();

  const { mutate: likeTopPick, isLoading } = useMutation({
    mutationFn: ChallengeService.likeTopPick,
    onSuccess() {
      queryClient.invalidateQueries([TOP_PICK_KEY, topPick.challengeId]);
    },
  });

  const onClickVote = () => {
    likeTopPick({ id: topPick.id });
  };

  const renderLoading = () => {
    if (!isLoading) return;
    return (
      <div className="absolute inset-0 bg-white/50 rounded-md">
        <div className="flex justify-center items-center h-full">
          <MatesLoading shape={"bars"} />
        </div>
      </div>
    );
  };

  return (
    <div className="w-[180px] ">
      <div className="flex items-center flex-wrap relative">
        <MatesButton
          icon={<ThumbUpIcon color="#FFF" />}
          text={`${topPick._count.likes}`}
          className={`base-button w-full text-base font-normal text-MatesWhite font-condensed h-10 upload-light-button`}
          onSubmit={() => {
            onClickVote();
          }}
        />
        {renderLoading()}
      </div>
      <ChallengeSampleCard source={topPick.link} />;
    </div>
  );
};

export default MatesTikTokVoteInput;
