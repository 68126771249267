const closeIcon = ({
  height,
  width,
  className,
  onClick
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "20.204"}
        height={height ?? "20.205"}
        viewBox="0 0 20.204 20.205"
        onClick={onClick}
      >
        <g
          id="Group_2221"
          data-name="Group 2221"
          transform="translate(-973.602 -71.11)"
        >
          <path
            id="Path_1344"
            data-name="Path 1344"
            d="M-3524.045-4220.537l19.5,19.5"
            transform="translate(4498 4292)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <path
            id="Path_1345"
            data-name="Path 1345"
            d="M-3524.045-4220.537l19.5,19.5"
            transform="translate(-3227.084 3595.508) rotate(90)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </g>
      </svg>
    </div>
  );
};

export default closeIcon;
