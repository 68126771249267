import { NavbarType, RFC } from '../../types/propTypes'
import { useNavigate } from 'react-router-dom'
import { navbarTabs } from '../../assets/data/arrays'
import { LogoutIcon } from '../../assets/icons/icons'
import { MatesLogo } from '../../assets/images/images'
import { MATES_ADMIN_TOKEN } from '../../assets/data/constants'

const Navbar:RFC<NavbarType> = ({ page }) => {
    const navigate = useNavigate()

    const renderNavbarIcon = () => {
        return (
            <>
                {navbarTabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`title-button text-xl 
                            mx-2 my-3 text-MatesWhite
                            ${page === tab.page 
                            ? 'bg-MatesRed hover:bg-MatesRed' 
                            : 'bg-MatesDarkGrey hover:bg-MatesBorder'}`
                        }
                        onClick={() => navigate(tab.link)}
                    >
                        <tab.icon className='mr-2 pb-1'/>
                        {tab.page}
                    </div>
                ))}
            </>
        )
    }

    const handleLogout = () => {
        localStorage.removeItem(MATES_ADMIN_TOKEN)
        navigate('/login')
    }

    return (
        <div 
            className='w-screen flex justify-between items-center 
            border-b-[2px] border-solid border-MatesBorder'
        >
            <div className=''>
                <img 
                    src={MatesLogo} 
                    alt='mates-logo' 
                    className='w-auto h-auto max-h-[100px] max-w-[70px] pl-6'
                />
            </div>
            <div className='w-full h-full flex flex-row justify-center items-center'>
                { renderNavbarIcon() }
            </div>
            <div 
                className='flex items-center font-bold px-5 h-full 
                cursor-pointer transition-all duration-300 pr-6'
                onClick={handleLogout}
            >
                <LogoutIcon 
                    className='fill-MatesWhite hover:fill-MatesRed 
                    transition-all duration-200'
                />
            </div>
        </div>
    )
}

export default Navbar