import { PinIcon } from "../../../assets/icons/icons";
import { RFC } from "../../../types/propTypes";
import { MatesToggleButton } from "../../atoms/atoms";

type PinResourceFieldProps = {
  isPinned: boolean;
  title?: string;
  onChange: () => void;
};

const PinResourceField: RFC<PinResourceFieldProps> = ({
  isPinned,
  title,
  onChange,
}) => {
  return (
    <div className="flex items-center justify-between pb-2">
      <div className="flex items-center">
        <PinIcon className="mr-3" />
        <p className="text-MatesWhite text-lg font-condensed">
          {title ?? "Pin as primary resource"}
        </p>
      </div>
      <MatesToggleButton isActive={isPinned} onChangeStatus={onChange} />
    </div>
  );
};

export default PinResourceField;
