import { MatesApi } from '../app/api'
import { Resource } from '../types/stateTypes'
import {
	CreateResourceAPIArgs,
	DuplicateResourcesAPIArgs,
	EnableSurvey,
	PaginationPayload,
	ResourceData,
	ResourcePaginationPayload,
	ResourcesData,
	UpdateResourceArgs,
} from '../types/serviceTypes'

export const resourceApi = MatesApi.injectEndpoints({
	endpoints: (builder) => ({
		getResources: builder.query<ResourceData, ResourcePaginationPayload>({
			query: ({ page, take, categoryId, resourceType }) => ({
				url: `/admin/categories/${categoryId}/resources`,
				params: { page, take, resourceType },
			}),
			providesTags: (result, error, arg) =>
				result
					? [
							...result.resources.map(({ id }) => ({
								type: 'Resource' as const,
								id,
							})),
							'Resource',
					  ]
					: ['Resource'],
		}),
		updateResource: builder.mutation<Resource, UpdateResourceArgs>({
			query: ({ id, ...UpdateResourceArg }) => ({
				url: `/admin/resources/${id}`,
				method: 'PATCH',
				body: {
					...UpdateResourceArg,
				},
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Resource', id: arg.id }],
		}),
		deleteResource: builder.mutation<Resource, string>({
			query: (resourceId) => ({
				url: `/admin/resources/${resourceId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Resource'],
		}),
		createResource: builder.mutation<Resource, CreateResourceAPIArgs>({
			query: (args) => ({
				url: `/admin/resources`,
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['Resource'],
		}),
		duplicateResources: builder.mutation<Resource, DuplicateResourcesAPIArgs>({
			query: (args) => ({
				url: `/admin/resources/duplicate`,
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['Resource'],
		}),
		updateActiveSurvey: builder.mutation<Resource, EnableSurvey>({
			query: ({ id, ...UpdateResourceArg }) => ({
				url: `/admin/resources/${id}`,
				method: 'PATCH',
				body: {
					...UpdateResourceArg,
				},
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Resource', id: arg.id }],
		}),
		getAllResourceResults: builder.query<ResourcesData, PaginationPayload>({
			query: ({ page, take }) => ({
				url: '/admin/resources',
				params: { page, take },
			}),
			providesTags: ['ResourceResult'],
		}),
	}),
})

export const {
	useLazyGetResourcesQuery,
	useUpdateResourceMutation,
	useDeleteResourceMutation,
	useCreateResourceMutation,
	useUpdateActiveSurveyMutation,
	useLazyGetAllResourceResultsQuery,
	useDuplicateResourcesMutation,
} = resourceApi
