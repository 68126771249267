import { useState } from 'react'
import { RFC } from '../../../types/propTypes'
import { useGetPresignedDownloadUrlQuery } from '../../../services/FileService'
import { MatesLoading } from '../atoms'

type AudioPlayerProps = {
    audioURL: string
    showPlayerControls?: boolean;
    onLoadedData: (duration: number) => void
}

const AudioPlayer: RFC<AudioPlayerProps> = ({ audioURL, showPlayerControls = true, onLoadedData }) => {
    const [isAudioError, setIsAudioError] = useState(false)
    const { data, isLoading, isError } = useGetPresignedDownloadUrlQuery({ fileName: audioURL }, { skip: !audioURL })

    if (isLoading) return (
        <div className='h-full w-full flex justify-center items-center rounded-lg'>
            <MatesLoading shape='circle' />
        </div>
    )

    if (!data) return (
        <div className='w-full h-full flex justify-center items-center'>
            There is no audio
        </div>
    )

    if (isError || isAudioError) return (
        <div className='w-full h-full flex justify-center items-center'>
            Failed to download audio
        </div>
    )

    return (
        <audio 
            src={data.url} 
            controls
            onLoadedData={e => {
                onLoadedData(e.currentTarget.duration)
              }}
            className={showPlayerControls ? 'w-full h-[30px] mt-2' : 'hidden'}
            onError={() => setIsAudioError(true)}
        />
    )
}

export default AudioPlayer