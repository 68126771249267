import { FormikType, ResourceTabList, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { matesResourceTabs } from '../../../assets/data/arrays'
import { MatesButton, MatesTitleBlock } from '../../atoms/atoms'
import { PodcastField, VideoField, PDFField, LinkField } from '../molecules'
import { AiOutlineArrowLeft as BackArrow } from 'react-icons/ai'
import { CREATE_RESOURCE_PAGES } from '../../../assets/data/enums'

const SelectResource:RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
  const { openedTab } = formik.values

  const renderCreateResource = () => {
    switch (openedTab) {
      case 'PODCASTS': 
        return <PodcastField formik={formik} />
      case 'VIDEOS':   
        return <VideoField formik={formik}/>
      case 'PDFS':     
        return <PDFField formik={formik} />
      case 'LINKS':    
        return <LinkField formik={formik}/>
      default:         
        return null
    }
  }

  const clickResourceTab = (tab: ResourceTabList) => {
    formik.setFieldValue('title', '')
    formik.setFieldValue('openedTab', tab)
  }

  return (
    <div className='min-h-[30em]'>
      <div className='flex items-center mb-3'>
        <BackArrow
          className='text-[2em] my-3 cursor-pointer'
          onClick={() => formik.setFieldValue('currentPage', CREATE_RESOURCE_PAGES.SELECT_CATEGORY)}
        />
        <div className='ml-5'>
          <div className='w-full flex leading-3 truncate'>
            <p className='text-MatesRed font-semibold mr-2 text-lg'>USER:</p>
            <p className='max-w-[20em] text-lg truncate'>
              {formik.values.userType}
            </p>
          </div>
          <div className='w-full flex leading-3 truncate'>
            <p className='text-MatesRed font-semibold mr-2 text-lg'>CATEGORY:</p>
            <p className='max-w-[20em] text-lg truncate'>
              {formik.values.category}
            </p>
          </div>
        </div>
      </div>
      <MatesTitleBlock
        title='SELECT RESOURCE'
        description='Select which resource you wish you upload the content too.'
      />
      <div className='flex items-center gap-x-4 mt-7 mb-3'>
      {matesResourceTabs.map((tab, index) => (
        <MatesButton
          key={index} 
          text={tab}
          className={`base-button hover:bg-MatesRed
          ${tab === openedTab
          ? 'active-button'
          : 'black-button'}`}
          onSubmit={() => clickResourceTab(tab)}
        />
      ))}
      </div>
      { renderCreateResource() }
    </div>
  )
}

export default SelectResource