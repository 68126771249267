import { RFC } from "../../../types/propTypes";
import { MatesLoading } from "../atoms";

type CardLoadingProps = {
  shape?:
    | "overlay"
    | "circle"
    | "bars"
    | "cradle"
    | "dots"
    | "spinner"
    | "wave"
    | "rounded"
    | "half-ring";
};

const CardLoading: RFC<CardLoadingProps> = ({ shape }) => {
  return (
    <div className=" justify-center items-center text-center mx-auto w-full p-5 mt-8">
      <MatesLoading shape={shape ?? "bars"} />
      <p className="mt-4 text-MatesGrey font-bold font-condensed tracking-wide">Loading..</p>
    </div>
  );
};

export default CardLoading;
