const pdfIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "22.5"} 
                height={height ?? "22.5"} 
                viewBox="0 0 22.5 22.5"
                onClick={onClick}
            >
                <path 
                    id="picture_as_pdf_FILL0_wght400_GRAD0_opsz48" d="M11.059,16.628H12.1V14.294h1.35a1.01,1.01,0,0,0,1.041-1.041V11.9a1.01,1.01,0,0,0-1.041-1.041H11.059ZM12.1,13.253V11.9h1.35v1.35Zm3.628,3.375h2.363a1.024,1.024,0,0,0,.731-.3.987.987,0,0,0,.309-.745V11.9a.987.987,0,0,0-.309-.745,1.024,1.024,0,0,0-.731-.3H15.728Zm1.041-1.041V11.9h1.322v3.684Zm3.741,1.041H21.55V14.294h1.406V13.253H21.55V11.9h1.406V10.862H20.509Zm-11.447,6.5a1.73,1.73,0,0,1-1.687-1.688V5.688a1.619,1.619,0,0,1,.506-1.181A1.619,1.619,0,0,1,9.062,4h15.75a1.619,1.619,0,0,1,1.181.506A1.619,1.619,0,0,1,26.5,5.688v15.75a1.73,1.73,0,0,1-1.687,1.688Zm0-1.688h15.75V5.688H9.062ZM5.688,26.5a1.619,1.619,0,0,1-1.181-.506A1.619,1.619,0,0,1,4,24.813V7.375H5.688V24.813H23.125V26.5ZM9.062,5.688v0Z" 
                    transform="translate(-4 -4)" 
                    fill="#fff" 
                />
            </svg>
        </div>
    )
}

export default pdfIcon