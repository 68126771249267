import { RFC } from '../../../types/propTypes'

type MatesCheckboxProps = {
	checked: boolean
	onClick?: () => void
}

const MatesCheckbox: RFC<MatesCheckboxProps> = ({ checked, onClick }) => {
	return (
		<div className=''>
			<div className='flex items-center mr-4 mb-2'>
				<input type='checkbox' value='yes' className='opacity-0 absolute h-7 w-7 cursor-pointer' onClick={() => (onClick ? onClick() : null)} />
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className={`${checked ? 'bg-MatesRed' : 'bg-MatesWhite'}  
                    border-2 rounded-[3px] border-MatesRed w-7 h-7 flex p-1
                    flex-shrink-0 justify-center items-center focus-within:border-MatesRed
                    fill-current  text-MatesWhite pointer-events-none`}
					version='1.1'
					viewBox='0 0 17 12'
				>
					<g fill='#FFFFFF' fillRule='evenodd'>
						<g transform='translate(-9 -11)' fill='#FFFFFF' fillRule='nonzero'>
							<path d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
						</g>
					</g>
				</svg>
			</div>
		</div>
	)
}

export default MatesCheckbox
