import { motion } from 'framer-motion'
import { Resource } from '../../../types/stateTypes'
import { cardAnimations } from '../../../assets/data/InitialState'
import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { resourcesSelector } from './resourcesSlice'
import { ResourceType, RFC } from '../../../types/propTypes'
import { AnimatePresence, domAnimation, LazyMotion } from 'framer-motion'
import { CardError, CardLogo, LinkCard, MatesCheckbox, PDFCard, PodcastCard, VideoCard } from '../../../components/atoms/atoms'
import { addOrRemoveResource, selectedResourcesSelector } from './duplicateResourceSlice'
import MatesCheckboxModal from '../../../components/atoms/fields/MatesCheckboxModal'


type ResourceTitlesProps = {
	isLoading: boolean
	isError: boolean
	resourceType?: ResourceType
}

export const ResourceTitlesSelectable: RFC<ResourceTitlesProps> = ({ isLoading, isError, resourceType }) => {
	const resourcesState = useMatesSelector(resourcesSelector)

	const selectedResources = useMatesSelector(selectedResourcesSelector)
	const dispatch = useMatesDispatch()

	const renderResourceCard = (resource: Resource) => {
		switch (resourceType) {
			case 'PODCAST':
				return <PodcastCard resource={resource} />
			case 'VIDEO':
				return <VideoCard resource={resource} />
			case 'PDF':
				return <PDFCard resource={resource} />
			case 'LINK':
				return <LinkCard resource={resource} />
			default:
				return null
		}
	}

	if (isLoading) return <CardLogo />
	if (isError) return <CardError />
	if (!resourcesState.length) return <div className='empty-list font-condensed'>No {resourceType ?? 'Resource'}</div>

	const selectOrUnselectResource = (resource: Resource) => {
		dispatch(addOrRemoveResource(resource))
	}

	return (
		<AnimatePresence>
			<LazyMotion features={domAnimation} key='resource-card'>
				{resourcesState.map((resource, index) => (
					<motion.div key={index} className='flex w-full pr-2 items-center' {...cardAnimations}>
						<MatesCheckboxModal
							isChecked={selectedResources.some((r) => r.id === resource.id)}
							onClick={() => selectOrUnselectResource(resource)}
							key={index}
						/>
						{renderResourceCard(resource)}
					</motion.div>
				))}
			</LazyMotion>
		</AnimatePresence>
	)
}
