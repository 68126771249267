import { MatesApi } from '../app/api'
import { CreateCategoryArgs, ReorderCategoryArgs, UpdateCategoryArgs } from '../types/serviceTypes'
import { Category } from '../types/stateTypes'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { storeCategories } from '../features/resources/categoriesSlice'

const categoryApi = MatesApi.injectEndpoints({
    endpoints: (builder) => ({
        createCategory: builder.mutation<Category, CreateCategoryArgs>({
            query: args => ({
                url: 'admin/categories',
                method: 'POST',
                body: {
                    ...args,
                    description: 'New Category'
                }
            }),
            invalidatesTags: [ 'Category' ]
        }),
        updateCategory: builder.mutation<Category, UpdateCategoryArgs>({
            query: ({ id, ...UpdateCategoryArgs}) => ({
                url: `/admin/categories/${id}`,
                method: 'PATCH',
                body: { ...UpdateCategoryArgs }
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Category', id: arg.id }],
        }),
        deleteCategory: builder.mutation<Category, string> ({
            query: (id) => ({
                url: `/admin/categories/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [ 'Category' ]
        }),
        reorderCategories: builder.mutation<Category[], ReorderCategoryArgs>({
            async queryFn(arg, api, extraOptions, baseQuery) {
                const { destination, source } = arg.result
                if (!destination || !arg.categories.length) return { 
                    error: { 
                        status: 500, 
                        statusText: 'Internal Server Error', 
                        data: 'Something went wrong!' 
                    }
                }
                const items = Array.from(arg.categories)
                const [ reorderedItem ] = items.splice(source.index, 1)
                if (!reorderedItem) return {
                    error: {
                        status: 500,
                        statusText: 'Internal Server Error',
                        data: 'There is no category!'
                    }
                }
                items.splice(destination.index, 0, reorderedItem)
                const newSequence = items.map((item, index) => {
                    return {
                        id:                     item.id,
                        title:                  item.title,
                        isHidden:               item.isHidden,
                        isAvailableToEveryone:  item.isAvailableToEveryone,
                        userGroupId:            item.userGroupId,
                        sequence:               index + 1,
                        isTrainingModule:       item.isTrainingModule,
                        thumbnail:              item.thumbnail,
                        description:            item.description,
                    }
                })
                
                const { data, error } = await baseQuery(({
                    url: '/admin/categories',
                    params: { userGroupId: arg.userGroupId  },
                    method: 'PATCH',
                    body: { categories: newSequence }
                }))
                if (error) return { error: error as FetchBaseQueryError }
                api.dispatch(storeCategories(data as Category[]))
                return { data: data as Category[] }
            },
            invalidatesTags: [ 'Category' ]
        })
    })  
})

export const {
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useReorderCategoriesMutation,
} = categoryApi