import moment from "moment";
import { Tuple } from "../types/propTypes";
import { Winner } from "../types/stateTypes";

export const urlChecker = (url: string): boolean => {
  if (!url) return false;
  const regex = /^https:\/\/www\.tiktok\.com\/@[\w\d]+\/video\/\d+$/;
  return regex.test(url);
};

export const linkErrorChecker = (
  winners: Tuple<Winner, 5>,
  errors: Tuple<boolean, 5>
): boolean => {
  const isAtLeastOne = !!winners[0]?.link;

  const hasNoSpace = winners.every((winner, index) => {
    if (index === 0) return true;
    if (winner.link && !winners[index - 1]?.link) return false;
    return true;
  });

  const shouldNoErrors = errors.every((error) => error === false);

  return isAtLeastOne && hasNoSpace && shouldNoErrors;
};

export const announceDateChecker = (date: string): boolean => {
  if (date === "") return true;
  const isValidFormat = moment(date, "DD-MM-YYYY", true).isValid();
  if (!isValidFormat) return true;
  else return false;
};
