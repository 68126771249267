import { useMemo } from "react";
import { useMatesDispatch } from "../../../app/hooks";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { openModal } from "../../../features/cores/modalSlice";
import { MatesTable } from "../../atoms/atoms";
import { signUpSurveyHeaders, theHubFeedbackHeaders, welcomeSurveyHeaders } from "../../../assets/data/arrays";
import {
  useGetSignUpReasonsSurveyResultsQuery,
  useGetTheHubExperienceFeedbackQuery,
  useGetWelcomeSurveyResultQuery,
} from "../../../services/SurveyService";
import { SignUpReasonsResults, WelcomeSurveyResult, TheHubFeedbackResults } from "../../../types/serviceTypes";
import { InstallIcon } from "../../../assets/icons/icons";

const HubResultDetail = (data?: WelcomeSurveyResult) => {
  return [
    {
      question: "Total",
      trueCount: data?.trueCount ?? 0,
      falseCount: data?.falseCount ?? 0,
    },
  ];
};

const getSignUpReasonsSurveyTableData = (res?: SignUpReasonsResults) => {
  if (!res) return [];

  const SIGN_UP_REASONS_SURVEY_QUESTIONS: Record<string, string> = {
    WHAT_IT_IS_ALL_ABOUT: "I want to see what it's all about",
    MATE_RECOMMENDED: "A mate recommended it to me",
    PERSONAL_REASONS: "Personal reasons",
    WORK_REASONS: "Work reasons",
    LEARN_SUICIDE_PREVENTION: "To learn more about suicide prevention",
    UNDERSTAND_FIFO: "To understand more about FIFO",
    STAFF_RECOMMENDED: "“Mates” staff recommended",
    CONNECTOR_ASIST_VOLUNTEER: "To find information as a connector/Asist volunteer",
    PREFER_NOT_TO_SAY: "Prefer not to say",
    totalCountByRoles: "Total",
  };

  const rows = res.data.map((row) => ({
    ...row.countByRoles,
    signUpReason: SIGN_UP_REASONS_SURVEY_QUESTIONS[row.signUpReason],
    total: row.totalCount,
  }));

  const total = {
    ...res.totalCountByRoles,
    signUpReason: "Total",
    total: Object.values(res.totalCountByRoles).reduce((a, b) => a + b),
  };

  return [...rows, total];
};

const getTheHubExperienceTableData = (data?: TheHubFeedbackResults) => {
  return [
    {
      question: "User",
      isPositiveExperienceTrue: data?.positiveExperienceUserCount ?? 0,
      isPositiveExperienceFalse: data?.negativeExperienceUserCount ?? 0,
    },
    {
      question: "Guest",
      isPositiveExperienceTrue: data?.positiveExperienceGuestCount ?? 0,
      isPositiveExperienceFalse: data?.negativeExperienceGuestCount ?? 0,
    },
    {
      question: "Total",
      isPositiveExperienceTrue: data?.positiveExperienceCountTotal,
      isPositiveExperienceFalse: data?.negativeExperienceCountTotal,
    },
  ];
};

function TheHubResultsContainer() {
  const { data: welcomeSurveyRes } = useGetWelcomeSurveyResultQuery();
  const { data: theHubFeedbackRes } = useGetTheHubExperienceFeedbackQuery();
  const { data: signUpReasonsRes } = useGetSignUpReasonsSurveyResultsQuery();

  const dispatch = useMatesDispatch();

  const totalCommentCount = useMemo(() => {
    if (!theHubFeedbackRes) return 0;
    const totalCount = parseInt(theHubFeedbackRes.commentUserCount) + parseInt(theHubFeedbackRes.commentGuestCount);
    return totalCount ?? 0;
  }, [theHubFeedbackRes]);

  return (
    <>
      <div className="grid grid-cols-2 gap-x-10">
        <div className="flex flex-col gap-2 mt-4">
          <h3 className="text-MatesWhite font-medium font-condensed text-lg tracking-wider">"WELCOME" SURVEY</h3>
          <div className="w-[100%]">
            <MatesTable headers={welcomeSurveyHeaders} data={HubResultDetail(welcomeSurveyRes)} />
          </div>
          <div
            className="flex items-center gap-x-2 border-solid 
              border-[1px] border-MatesWhite/20 px-2 rounded-sm
              transition-all duration-300 hover:bg-MatesWhite/30
              cursor-pointer w-fit"
            onClick={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DOWNLOAD_WELCOME_SURVEY_RESULTS,
                })
              )
            }
          >
            <p className="text-MatesWhite font-condensed text-lg pt-1">Download Survey Results</p>
            <InstallIcon />
          </div>
        </div>

        <div className="mt-4">
          <div className="flex w-[100%] justify-between">
            <h3 className="text-MatesWhite font-medium font-condensed text-lg tracking-wider pb-2">THE HUB EXPERIENCE FEEDBACK</h3>
            <button
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: MODAL_TYPES.DISPLAY_FEEDBACK_SURVEY_COMMENTS_RESULTS,
                  })
                )
              }
            >
              <div className="underline underline-offset-4 text-base text-MatesWhite font-condensed">
                {totalCommentCount <= 1 || !totalCommentCount ? `Read ${totalCommentCount} Comment` : `Read All ${totalCommentCount} Comments`}
              </div>
            </button>
          </div>

          <div className="w-full mb-2">
            <MatesTable headers={theHubFeedbackHeaders} data={getTheHubExperienceTableData(theHubFeedbackRes)} />
          </div>
          <div
            className="flex items-center gap-x-2 border-solid 
              border-[1px] border-MatesWhite/20 px-2 rounded-sm
              transition-all duration-300 hover:bg-MatesWhite/30
              cursor-pointer w-fit"
            onClick={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DOWNLOAD_HUB_FEEDBACK_RESULTS,
                })
              )
            }
          >
            <p className="text-MatesWhite font-condensed text-lg pt-1">Download Feedback Results</p>
            <InstallIcon />
          </div>
        </div>
      </div>
      <div className="my-7">
        <h3 className="text-MatesWhite font-medium font-condensed text-lg">SIGN UP SURVEY</h3>
        <MatesTable headers={signUpSurveyHeaders} data={getSignUpReasonsSurveyTableData(signUpReasonsRes)} />
      </div>
    </>
  );
}

export default TheHubResultsContainer;
