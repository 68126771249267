import { DashboardLayout } from "../../components/templates/templates";
import { TikTokDetail, TikTokList } from "../../components/organisms/organisms";
import { useMatesSelector } from "../../app/hooks";
import { isDetailOpenSelector } from "../../features/challenges/challengeSlice";

const Challenges = () => {
  const isDetailOpen = useMatesSelector(isDetailOpenSelector);
  return (
    <DashboardLayout page="CHALLENGES">
      <>{!isDetailOpen ? <TikTokList /> : <TikTokDetail />}</>
    </DashboardLayout>
  );
};

export default Challenges;
