import { useMatesDispatch, useMatesSelector } from '../../../app/hooks'
import { matesResourceTabs } from '../../../assets/data/arrays'
import { clearResource } from '../../../features/resources/Resource/resourceSlice'
import { categoryIdSelector } from '../../../features/resources/categoriesSlice'
import { resourceTypeSelector, resourcesCountSelector, selectResourceType } from '../../../features/resources/Resource/resourcesSlice'
import { ResourceTabList } from '../../../types/propTypes'
import { convertResourceType } from '../../../utils/converter'
import { MatesButton } from '../../atoms/atoms'
import { InfiniteResourceList } from '../../molecules/molecules'

const ResourcesList = () => {
  const resourcesCount  = useMatesSelector(resourcesCountSelector)
  const categoryIdState = useMatesSelector(categoryIdSelector)
  const resourceType    = useMatesSelector(resourceTypeSelector)
  const dispatch = useMatesDispatch()

  const clickResourceType = (tab:ResourceTabList) => {
    dispatch(selectResourceType(tab))
    dispatch(clearResource())
  }

  return (
    <div className='pt-3 px-6'>
      <p className='text-MatesRed font-semibold text-2xl mb-2'>
        RESOURCES
      </p>
      <div className='flex items-center justify-between gap-1'>
        {matesResourceTabs.map((tab, index) => (
          <MatesButton 
            key={index}
            text={tab}
            className={`small-base-button 
            ${resourceType === tab 
              ? 'small-active-button hover:bg-MatesRed' 
              : 'small-black-button '
            } tracking-wide text-xl`}
            onSubmit={() => clickResourceType(tab)}
          />
        ))}
      </div>
      <div className='w-full text-MatesWhite/60 p-2 font-condensed'>
        {resourcesCount}&nbsp;{resourceType?.toLowerCase() ?? 'resource'}
      </div>
      <InfiniteResourceList 
        categoryId={categoryIdState}
        resourceType={convertResourceType(resourceType)}
      />
    </div>
  )
}

export default ResourcesList