import { configureStore } from '@reduxjs/toolkit'
import { MatesApi } from './api'
import ModalReducer from '../features/cores/modalSlice'
import SnackbarReducer from '../features/cores/snackbarSlice'
import OverlayReducer from '../features/cores/overlaySlice'
import AdminReducer from '../features/cores/adminSlice'
import UserGroupsReducer from '../features/resources/userGroupsSlice'
import CategoriesReducer from '../features/resources/categoriesSlice'
import ResourceReducer from '../features/resources/Resource/resourceSlice'
import ResourcesReducer from '../features/resources/Resource/resourcesSlice'
import UserReducer from '../features/users/userSlice'
import SurveyReducer from '../features/surveys/surveySlice'
import ChallengeReducer from '../features/challenges/challengeSlice'
import DuplicateResourceSlice from '../features/resources/Resource/duplicateResourceSlice'

export const store = configureStore({
	reducer: {
		[MatesApi.reducerPath]: MatesApi.reducer,
		modal: ModalReducer,
		snackbar: SnackbarReducer,
		overlay: OverlayReducer,
		admin: AdminReducer,
		userGroups: UserGroupsReducer,
		categories: CategoriesReducer,
		resource: ResourceReducer,
		resources: ResourcesReducer,
		selectedResources: DuplicateResourceSlice,
		user: UserReducer,
		survey: SurveyReducer,
		challenge: ChallengeReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(MatesApi.middleware),
	devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
