import { FormikType, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { MatesButton, MatesHorizontalLine, MatesTextArea, MatesTextInput, MatesUploadInput } from '../../atoms/atoms'

const PodcastField: RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
	const { title, slug } = formik.values
	const { description, file } = formik.values.podcast
	let isValid = title && description && file && slug

	return (
		<div>
			<MatesTextInput name='podcastTitle' label='TITLE' theme='light' value={title} onChange={(text) => formik.setFieldValue('title', text)} />
			<MatesTextInput name='slug' label='SLUG' theme='light' value={slug} onChange={(text) => formik.setFieldValue('slug', text)} />
			<MatesTextArea
				label='DESCRIPTION'
				theme='light'
				value={description}
				onChange={(text) => formik.setFieldValue('podcast.description', text)}
			/>
			<MatesHorizontalLine />
			<MatesUploadInput
				title='UPLOAD FILE'
				theme='light'
				type='audio'
				source={file}
				onUpload={(file) => formik.setFieldValue('podcast.file', file)}
				folder={'pdfs'}
				rootFolder={'other'}
			/>
			<MatesButton
				text='PUBLISH RESOURCE'
				className={`base-button mt-7
        ${isValid ? 'active-button' : 'disabled-light-button'}`}
				disabled={!isValid}
				isLoading={formik.isSubmitting}
				onSubmit={formik.handleSubmit}
			/>
		</div>
	)
}

export default PodcastField
