const rightArrowIcon = ({ height, width, className }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? "8.651"} 
                height={height ?? "13.767"} 
                viewBox="0 0 8.651 13.767"
            >
                <path 
                    id="Path_2" 
                    data-name="Path 2" 
                    d="M0,0,6,6l6-6" 
                    transform="translate(0.884 12.883) rotate(-90)" 
                    fill="none" 
                    stroke="#fff" 
                    strokeWidth={2.5} 
                />
            </svg>
        </div>
    )
}

export default rightArrowIcon