import { useMemo, useState } from "react";
import { EditIcon } from "../../../assets/icons/icons";
import { openModal } from "../../../features/cores/modalSlice";
import { MODAL_TYPES } from "../../../assets/data/enums";
import { announceDateChecker } from "../../../utils/validations";
import { convertDateStringToISO } from "../../../utils/converter";
import { useUpdateChallengeMutation } from "../../../services/Challenge";
import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { MatesChallengeTextArea, MatesChallengeTextInput } from "../../atoms/atoms";
import { closeOverlay, openOverlay } from "../../../features/cores/overlaySlice";
import { challengeSelector, changePermission, permissionTypeSelector, updateChallengeDetail } from "../../../features/challenges/challengeSlice";
import asyncTimeout from "../../../utils/asyncTimeout";

const ChallengeDetailField = () => {
  const [isError, setIsError] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const { id, title, completedDate, hashtag, submissionCloseDate, winnerAnnouncementDate, description, link, featuredChallengeLink } =
    useMatesSelector(challengeSelector);
  const permissionType = useMatesSelector(permissionTypeSelector);
  const dispatch = useMatesDispatch();

  const [updateChallenge] = useUpdateChallengeMutation();

  const hasPermission = useMemo(() => {
    const permission = !!id ? permissionType : "write";
    return permission;
  }, [id, permissionType]);

  const onEditChallenge = async () => {
    try {
      dispatch(openOverlay({ text: "Updating Challenge" }));
      const [challenge] = await Promise.all([
        updateChallenge({
          challengeId: id,
          title,
          hashtag,
          submissionCloseDate: convertDateStringToISO(submissionCloseDate),
          description,
          link,
          featuredChallengeLink,
        }).unwrap(),
        asyncTimeout(2000),
      ]);
      if (!challenge) throw Error;
      dispatch(changePermission("readonly"));
      dispatch(
        openModal({
          modalType: MODAL_TYPES.SUCCESS,
          title: `CHALLENGE UPDATED`,
          body: "You have successfully updated the challenge!",
        })
      );
    } catch (error) {
      dispatch(
        openModal({
          modalType: MODAL_TYPES.FAIL,
          title: "Failed to update the challenge",
          body: "Please check your input details and try again.",
        })
      );
    } finally {
      dispatch(closeOverlay());
    }
  };

  const renderActiveState = () => {
    return !!id ? "Active" : "Not yet active";
  };

  const renderType = () => {
    return winnerAnnouncementDate ? "In For Voting" : "Current Challenge";
  };

  return (
    <div className="flex">
      <div className="w-[70%]">
        <MatesChallengeTextInput
          type="title"
          value={title}
          permissionType={hasPermission}
          className="input-text-lg"
          onChange={(text) => dispatch(updateChallengeDetail({ key: "title", value: text }))}
        />
        <div className="mt-5 w-full flex flex-col gap-y-1">
          {/* Type */}
          <div className="grid grid-cols-challenge2cols">
            <p className="challenge-title">Type</p>
            <p className={`font-condensed text-lg text-MatesWhite`}>{renderType()}</p>
          </div>
          {/* Status */}
          <div className="grid grid-cols-challenge2cols">
            <p className="challenge-title">Status</p>
            <p className={`font-condensed text-lg ${!!id ? "text-MatesGreen" : "text-MatesWhite/40"}`}>{renderActiveState()}</p>
          </div>
          {/* Hashtag */}
          <div className="grid grid-cols-challenge2cols">
            <p className="challenge-title">Hashtag</p>
            <MatesChallengeTextInput
              type="hashtag"
              value={hashtag}
              permissionType={hasPermission}
              className={`${!hashtag ? "challenge-title" : "input-text-red"} pt-[2px]`}
              onChange={(text) => dispatch(updateChallengeDetail({ key: "hashtag", value: text }))}
            />
          </div>
          {/* Submission close date: */}
          <div className="grid grid-cols-challenge2cols">
            <p className="challenge-title">Submissions close:</p>
            <div>
              <MatesChallengeTextInput
                type="date"
                value={submissionCloseDate}
                permissionType={hasPermission}
                className={`${submissionCloseDate ? "input-text-sm" : "challenge-title"} pt-[2px]`}
                onChange={(text) => {
                  dispatch(
                    updateChallengeDetail({
                      key: "submissionCloseDate",
                      value: text,
                    })
                  );
                  isTouched && setIsError(announceDateChecker(text));
                }}
                onBlur={(text) => {
                  setIsError(announceDateChecker(text));
                  setIsTouched(true);
                }}
              />
              {isError && isTouched && (
                <div className="text-MatesRed font-condensed">
                  {submissionCloseDate === ""
                    ? "Submissions Close Date can not be empty."
                    : `${submissionCloseDate} is not in the format dd-mm-yyyy.`}
                </div>
              )}
            </div>
          </div>
          {/* Description */}
          <div className="grid grid-cols-challenge2cols">
            <p className="challenge-title">Description</p>
            <MatesChallengeTextArea
              value={description}
              permissionType={hasPermission}
              className={"challenge-title leading-5"}
              onChange={(text) =>
                dispatch(
                  updateChallengeDetail({
                    key: "description",
                    value: text,
                  })
                )
              }
            />
          </div>
        </div>
      </div>
      {!!id && (
        <div className="w-[30%] flex items-start justify-end text-right">
          {permissionType === "write" ? (
            <div className="flex flex-col gap-y-2">
              <div
                className={`complete-button text-xl text-MatesWhite
              bg-MatesDarkGrey hover:bg-MatesBorder
              ${isError && "cursor-not-allowed text-MatesBorder hover:bg-MatesDarkGrey"}`}
                onClick={() => (isError ? {} : onEditChallenge())}
              >
                DONE
              </div>
              <div className="complete-button text-xl bg-none text-MatesLightWhite" onClick={() => dispatch(changePermission("readonly"))}>
                cancel
              </div>
            </div>
          ) : (
            <div
              className="p-[10px] rounded-[3px] cursor-pointer
            bg-MatesDarkGrey hover:bg-MatesBorder"
              onClick={() => dispatch(changePermission("write"))}
            >
              <EditIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChallengeDetailField;
