import { UpdateResourceArgs } from '../../../types/serviceTypes'
import { RFC } from '../../../types/propTypes'
import { useMatesDispatch } from '../../../app/hooks'
import {
	updatePodcastDescription,
	updatePodcastFile,
	updateResourceIsActive,
	updateResourcePin,
	updateSlug,
	updateTitle,
} from '../../../features/resources/Resource/resourceSlice'
import { MatesButton, MatesTextArea, MatesTextInput, MatesUploadInput } from '../../atoms/atoms'
import { PinResourceField } from '../../molecules/molecules'
import { BinIcon } from '../../../assets/icons/icons'
import IsActiveField from '../../molecules/fields/IsActiveField'
import { formatSlug } from '../../../utils/formatter'

type PodcastDetailProps = {
	resource: UpdateResourceArgs
	onSubmit: () => void
	onDelete: () => void
}

const PodcastDetail: RFC<PodcastDetailProps> = ({ resource, onSubmit, onDelete }) => {
	const { isPinned, title, podcast, isActive, slug } = resource
	const isValid = title && podcast?.description && podcast.file
	const dispatch = useMatesDispatch()

	return (
		<>
			<PinResourceField isPinned={isPinned} onChange={() => dispatch(updateResourcePin(!isPinned))} />
			<IsActiveField isActive={isActive} onChange={() => dispatch(updateResourceIsActive(!isActive))} />
			<MatesTextInput
				name='podcastTitle'
				label='TITLE'
				theme='dark'
				value={title}
				className={'pt-3'}
				onChange={(text) => dispatch(updateTitle(text))}
			/>
			<MatesTextInput
				name='slug'
				label='URL SLUG'
				theme='dark'
				value={slug}
				className={'pt-3'}
				onChange={(text) => dispatch(updateSlug(formatSlug(text)))}
			/>
			<MatesTextArea
				label='DESCRIPTION'
				theme='dark'
				value={podcast?.description}
				onChange={(text) => dispatch(updatePodcastDescription(text))}
			/>
			<MatesUploadInput
				title='RESOURCE'
				theme='dark'
				type='audio'
				source={podcast?.file}
				onUpload={(file) => dispatch(updatePodcastFile(file ?? ''))}
				folder={'pdfs'}
				rootFolder={'other'}
			/>
			<div className='flex items-center'>
				<MatesButton
					text='SAVE'
					className={`base-button mt-7
          ${isValid ? 'active-button' : 'disabled-dark-button'} tracking-wider text-xl`}
					disabled={!isValid}
					onSubmit={onSubmit}
				/>
				<div
					className='base-button bg-MatesDarkRed 
          mt-7 ml-4 px-3 py-4 cursor-pointer group'
					onClick={onDelete}
				>
					<BinIcon
						className='group-hover:scale-125
            transition-all duration-300'
					/>
				</div>
			</div>
		</>
	)
}

export default PodcastDetail
