import { useMatesDispatch, useMatesSelector } from "../../../app/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyGetUsersByUserGroupQuery } from "../../../services/UserService";
import { UserData } from "../../../types/stateTypes";
import { RightArrowIcon } from "../../../assets/icons/icons";
import { cardAnimations } from "../../../assets/data/InitialState";
import { debounce } from "lodash";
import {
  CardError,
  CardLoading,
  CardLogo,
  TitleCard,
  MatesSearchInput,
} from "../../atoms/atoms";
import {
  AnimatePresence,
  LazyMotion,
  domAnimation,
  motion,
} from "framer-motion";
import {
  selectUser,
  userIdSelector,
  userGroupSelector,
} from "../../../features/users/userSlice";
import InfiniteScroll from "react-infinite-scroller";

const InfiniteUserList = () => {
  const take = 10;
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const onChangeText = useMemo(
    () => debounce(setSearchText, 500),
    [setSearchText]
  );

  const userId = useMatesSelector(userIdSelector);
  const userGroup = useMatesSelector(userGroupSelector);
  const dispatch = useMatesDispatch();

  const [getUsersByUserGroup, { data: usersData, isLoading, isError }] =
    useLazyGetUsersByUserGroupQuery();

  const handleSetPage = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const totalUserCount = useMemo(() => {
    if (!usersData?.count) return 0;
    return usersData.count;
  }, [usersData?.count]);

  useEffect(() => {
    handleSetPage(1);
    loadMoreItems(take, 1);
  }, [userGroup, searchText]);

  const loadMoreItems = async (take: number, page: number) => {
    if (!isLoadMore) setIsLoadMore(true);
    try {
      const { count, data } = await getUsersByUserGroup({
        take,
        page,
        search: searchText,
        filter: userGroup,
      }).unwrap();

      if (!data || !data.length || data.length >= count || data.length < take) {
        setHasMore(false);
        return;
      } else {
        setHasMore(true);
        setPage(++page);
        return;
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setTimeout(() => {
        setIsLoadMore(false);
      }, 1000);
    }
  };

  const renderUserDetails = (users: UserData) => {
    return (
      <>
        {users.data.map((user, index) => (
          <motion.div key={index} {...cardAnimations} className="relative">
            {user.safePlan && (
              <div
                className="absolute left-0 top-0 bottom-0 w-[6px]
                rounded-l-[3px] bg-MatesBlue"
              ></div>
            )}
            <TitleCard
              key={user.id}
              isSelected={userId === user.id}
              isAnimationOn={false}
              onClick={() => dispatch(selectUser(user))}
            >
              <div className="flex items-center justify-between w-full font-condensed font-medium">
                <div className="text-[18px]">
                  <p className="truncate">
                    {user.firstName + " " + user.lastName}
                  </p>
                  <p className="truncate">{user.email}</p>
                </div>
                <RightArrowIcon className="ml-2" />
              </div>
            </TitleCard>
          </motion.div>
        ))}
      </>
    );
  };

  if (isLoading) return <CardLogo />;
  if (isError) return <CardError />;
  if (!usersData?.count)
    return (
      <div className="empty-list tracking-wide text-3xl py-10">
        There is no user
      </div>
    );

  return (
    <div>
      <div className="flex flex-row">
        <p className="text-MatesRed font-semibold text-3xl tracking-wide">
          {userGroup}
        </p>
        <p className="text-MatesGrey text-[15px] ml-3 mt-2 font-condensed">{`${totalUserCount} users`}</p>
      </div>
      <MatesSearchInput onChange={onChangeText} />

      <div className="h-[calc(100vh-16rem)] overflow-y-auto">
        <AnimatePresence>
          <LazyMotion features={domAnimation} key={"user-list"}>
            <InfiniteScroll
              loadMore={() => (!isLoadMore ? loadMoreItems(take, page) : {})}
              hasMore={hasMore}
              useWindow={false}
              loader={<CardLoading shape="rounded" key={0} />}
              threshold={150}
            >
              {renderUserDetails(usersData)}
            </InfiniteScroll>
          </LazyMotion>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default InfiniteUserList;
