const dataTableIcon = ({
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "18.782"}
        height={height ?? "14.608"}
        viewBox="0 0 18.782 14.608"
      >
        <path
          id="view_list_FILL0_wght400_GRAD0_opsz48"
          d="M6,24.608V10H24.782V24.608ZM7.565,14.356H10.33V11.565H7.565Zm4.33,0H23.217V11.565H11.9Zm0,4.33H23.217V15.921H11.9Zm0,4.356H23.217V20.252H11.9Zm-4.33,0H10.33V20.252H7.565Zm0-4.356H10.33V15.921H7.565Z"
          transform="translate(-6 -10)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

export default dataTableIcon;
