const visibilityOffIcon = ({
  color = "#ed1941",
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "22.417"}
        height={height ?? "20.405"}
        viewBox="0 0 22.417 20.405"
      >
        <path
          id="visibility_off_FILL0_wght400_GRAD0_opsz48_5_"
          data-name="visibility_off_FILL0_wght400_GRAD0_opsz48 (5)"
          d="M17,16.111,15.883,14.99a2.527,2.527,0,0,0-.688-3.006,2.721,2.721,0,0,0-2.929-.611l-1.121-1.121a3.079,3.079,0,0,1,.968-.408,4.713,4.713,0,0,1,1.1-.127,4.305,4.305,0,0,1,4.331,4.331,4.454,4.454,0,0,1-.14,1.108A3.364,3.364,0,0,1,17,16.111ZM20.29,19.4l-1.019-1.019a11.947,11.947,0,0,0,2.178-2.051,8.186,8.186,0,0,0,1.363-2.28,10.168,10.168,0,0,0-3.821-4.471,10,10,0,0,0-5.528-1.643,12.234,12.234,0,0,0-2.191.2,8.123,8.123,0,0,0-1.758.484l-1.172-1.2a11.551,11.551,0,0,1,2.28-.713,12.6,12.6,0,0,1,2.713-.306A11.5,11.5,0,0,1,20,8.482a12.037,12.037,0,0,1,4.42,5.566,12.585,12.585,0,0,1-1.707,2.98A12.3,12.3,0,0,1,20.29,19.4Zm1.477,5.757-4.28-4.2a9.9,9.9,0,0,1-2.012.548,13.468,13.468,0,0,1-2.267.191,11.679,11.679,0,0,1-6.751-2.076A12.168,12.168,0,0,1,2,14.048a11.559,11.559,0,0,1,1.414-2.586,14.48,14.48,0,0,1,2.2-2.407l-3.21-3.21,1.07-1.1L22.761,24.034ZM6.662,10.125A9.375,9.375,0,0,0,4.84,11.934a8.385,8.385,0,0,0-1.261,2.114,10.2,10.2,0,0,0,3.91,4.471,10.886,10.886,0,0,0,5.923,1.643,13.337,13.337,0,0,0,1.656-.1,3.956,3.956,0,0,0,1.223-.306l-1.63-1.63a2.667,2.667,0,0,1-.688.191,4.965,4.965,0,0,1-.764.064,4.212,4.212,0,0,1-3.057-1.248,4.145,4.145,0,0,1-1.274-3.082,4.641,4.641,0,0,1,.064-.764,3.119,3.119,0,0,1,.191-.688ZM14.431,13.742ZM11.476,15.22Z"
          transform="translate(-2 -4.75)"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default visibilityOffIcon;
