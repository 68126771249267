import { FormikType, RFC } from '../../../types/propTypes'
import { CreateResourceArgs } from '../../../types/serviceTypes'
import { CREATE_RESOURCE_PAGES } from '../../../assets/data/enums'
import { CardError, MatesButton, MatesTitleBlock, RadioGroupButtons } from '../../atoms/atoms'
import { useGetCategoriesQuery } from '../../../services/UserGroupService'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { CardListSkeleton } from '../molecules'
import { AiOutlineArrowLeft as BackArrow } from 'react-icons/ai'

const SelectCategory: RFC<FormikType<CreateResourceArgs>> = ({ formik }) => {
	const { userTypeId, category } = formik.values

	const { data, isLoading, isError } = useGetCategoriesQuery(userTypeId ?? skipToken)

	const renderCategoryList = () => {
		if (isLoading) return <CardListSkeleton type='normal' times={4} />
		if (isError) return <CardError />
		if (!data?.length) return <div className='empty-list'>No Category</div>

		const categoryTitleList = data.map((item) => item.title)

		return (
			<RadioGroupButtons
				list={categoryTitleList}
				selectedItem={category}
				onSelectItem={(resourceCategory, selectedIndex) => {
					formik.setFieldValue('category', resourceCategory)
					if (data[selectedIndex] === undefined) return
					formik.setFieldValue('categoryId', data[selectedIndex]?.id)
				}}
			/>
		)
	}

	return (
		<div className='transition-all duration-200'>
			<BackArrow
				className='text-[2em] my-3 cursor-pointer'
				onClick={() => formik.setFieldValue('currentPage', CREATE_RESOURCE_PAGES.SELECT_USER)}
			/>
			<MatesTitleBlock title='SELECT CATEGORY' description='Select which category this resource will be seen under.' className='pb-3' />
			<div className='max-h-[25em] overflow-y-auto overflow-x-hidden mb-3'>{renderCategoryList()}</div>
			<MatesButton
				text='CONTINUE'
				className={`base-button
        ${category ? 'active-button' : 'disabled-light-button'}`}
				disabled={!category}
				onSubmit={() => formik.setFieldValue('currentPage', CREATE_RESOURCE_PAGES.SELECT_RESOURCE)}
			/>
		</div>
	)
}

export default SelectCategory
