import { AxiosResponse } from "axios";
import { DEFAULT_PAGE_SIZE } from "../../assets/data/constants";
import { InfiniteData } from "react-query";

export type MatesPaginatedResponse<T> = {
  count: number;
  data: T[];
};
export type MatesAxiosResponse<T> = AxiosResponse<MatesPaginatedResponse<T>>;
export type InfiniteMatesAxiosResponse<T> = InfiniteData<AxiosResponse<MatesPaginatedResponse<T>>>;

export function getNextPageParam<T>(pageSize = DEFAULT_PAGE_SIZE) {
  return (res: MatesAxiosResponse<T>, all: MatesAxiosResponse<T>[]) => {
    if (res.data.data.length === pageSize) return all.length + 1;
    return false;
  };
}

export function getItemsFromPagination<T>(data: InfiniteMatesAxiosResponse<T> | null | undefined) {
  return data ? data.pages.flatMap((page) => page.data.data) : [];
}
