import { RFC } from "../../types/propTypes";
import { useMatesDispatch, useMatesSelector } from "../../app/hooks";
import { UserGroup } from "../../types/stateTypes";
import { MatesLoading } from "../atoms/atoms";
import {
  selectUserGroup,
  userGroupSelector,
} from "../../features/users/userSlice";

type UserSubNavbarProps = {
  subTabs?: UserGroup[];
  isLoading?: boolean;
};

const UserSubNavbar: RFC<UserSubNavbarProps> = ({ subTabs, isLoading }) => {
  const selectedUserGroup = useMatesSelector(userGroupSelector);
  const dispatch = useMatesDispatch();

  const renderSubNavbar = () => {
    if (!subTabs || !subTabs.length || isLoading === undefined) return null;
    return subTabs.map((userGroup, index) => (
      <div key={index}>
        {isLoading ? (
          <div key={index} className="title-button">
            <MatesLoading shape="circle" />
          </div>
        ) : (
          <div
            key={index}
            className={`title-button text-lg text-MatesWhite 
            ${
              selectedUserGroup === userGroup.title
                ? "bg-MatesRed hover:bg-MatesRed"
                : "bg-MatesDarkGrey hover:bg-MatesBorder"
            }`}
            onClick={() => dispatch(selectUserGroup(userGroup.title ?? "ALL"))}
          >
            {userGroup.title}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="w-full h-max flex justify-center flex-wrap my-3 gap-3">
      <div
        className={`title-button text-lg text-MatesWhite 
        ${
          selectedUserGroup === "ALL"
            ? "bg-MatesRed hover:bg-MatesRed"
            : "bg-MatesDarkGrey hover:bg-MatesBorder"
        }`}
        onClick={() => dispatch(selectUserGroup("ALL"))}
      >
        ALL
      </div>
      {renderSubNavbar()}
      <div
        className={`title-button text-lg text-MatesWhite 
        ${
          selectedUserGroup === "OTHER"
            ? "bg-MatesRed hover:bg-MatesRed"
            : "bg-MatesDarkGrey hover:bg-MatesBorder"
        }`}
        onClick={() => dispatch(selectUserGroup("OTHER"))}
      >
        OTHER
      </div>
    </div>
  );
};

export default UserSubNavbar;
