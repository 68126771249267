import { RFC } from '../../../types/propTypes'

type CardListSkeletonProps = {
    type:  'small' | 'normal' | 'large'
    times: number
}

const CardListSkeleton:RFC<CardListSkeletonProps> = ({ type, times }) => {
    
    const sizeConverter = () => {
        switch (type) {
            case 'small':  return 'h-[2em]'
            case 'normal': return 'h-[3.5em]'
            case 'large':  return 'h-[4em]'
            default:       return ''
        }
    }

    return (
        <>
            {Array(times).fill(0).map((_, index) => (
                <div
                    key={index}
                    role='status'
                    className='w-full animate-pulse my-2 px-2'
                >
                    <div 
                        className={`${ sizeConverter() } 
                        w-full bg-MatesBorder rounded-[5px] dark:bg-MatesBorder`}
                    ></div>
                </div>
            ))}
        </>
    )
}

export default CardListSkeleton