const visibilityIcon = ({
  color = "#ed1941",
  height,
  width,
  className,
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? "22.417"}
        height={height ?? "15.284"}
        viewBox="0 0 22.417 15.284"
      >
        <path
          id="visibility_FILL0_wght400_GRAD0_opsz48_4_"
          data-name="visibility_FILL0_wght400_GRAD0_opsz48 (4)"
          d="M13.208,19.973a4.305,4.305,0,0,0,4.331-4.331,4.305,4.305,0,0,0-4.331-4.331,4.305,4.305,0,0,0-4.331,4.331,4.305,4.305,0,0,0,4.331,4.331Zm0-1.477a2.864,2.864,0,0,1-2.025-4.878,2.891,2.891,0,0,1,4.05,0A2.853,2.853,0,0,1,13.208,18.5Zm0,4.789A11.417,11.417,0,0,1,6.483,21.17,12.881,12.881,0,0,1,2,15.642a12.881,12.881,0,0,1,4.483-5.528A11.417,11.417,0,0,1,13.208,8a11.417,11.417,0,0,1,6.725,2.114,12.881,12.881,0,0,1,4.483,5.528,12.881,12.881,0,0,1-4.483,5.528A11.417,11.417,0,0,1,13.208,23.284ZM13.208,15.642Zm0,6.114a10.244,10.244,0,0,0,5.668-1.669,10.827,10.827,0,0,0,3.936-4.445A10.827,10.827,0,0,0,18.876,11.2a10.461,10.461,0,0,0-11.336,0,11.009,11.009,0,0,0-3.961,4.445,11.009,11.009,0,0,0,3.961,4.445A10.244,10.244,0,0,0,13.208,21.756Z"
          transform="translate(-2 -8)"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default visibilityIcon;
