import moment from "moment";
import "moment-timezone";
import { CHALLENGE_WINNER_COUNT } from "../assets/data/constants";
import { IntRange, ResourceListType, ResourceType } from "../types/propTypes";
import { ResourceFiles, SurveyResource } from "../types/serviceTypes";
import { Resource } from "../types/stateTypes";

export const convertResourceType = (
  type: ResourceListType | undefined
): ResourceType | undefined => {
  switch (type) {
    case "PODCASTS":
      return "PODCAST";
    case "VIDEOS":
      return "VIDEO";
    case "PDFS":
      return "PDF";
    case "LINKS":
      return "LINK";
    default:
      return undefined;
  }
};

export const revertResourceType = (
  type: ResourceType | undefined
): ResourceListType | undefined => {
  switch (type) {
    case "PODCAST":
      return "PODCASTS";
    case "VIDEO":
      return "VIDEOS";
    case "PDF":
      return "PDFS";
    case "LINK":
      return "LINKS";
    default:
      return undefined;
  }
};

export const convertChallengeWinnerIndex = (index: number) => {
  if (index > CHALLENGE_WINNER_COUNT || index < 0) return 0;
  else return index as IntRange<0, 5>;
};

export const convertDateStringToISO = (dateString: string): string => {
  const timeZone = moment.tz.guess();
  const date = moment.tz(dateString, "DD-MM-YYYY", timeZone);
  const isoString = date.toISOString();
  return isoString;
};

export const convertISOToDateString = (isoString: string): string => {
  const timeZone = moment.tz.guess();
  const dateInOriginalTimeZone = moment
    .tz(isoString, timeZone)
    .format("DD-MM-YYYY");
  return dateInOriginalTimeZone;
};

export const convertDateStringToPretty = (dateString: string): string => {
  const timeZone = moment.tz.guess();
  const date = moment.tz(dateString, "DD-MM-YYYY", timeZone);
  return date.format("D MMMM YYYY");
};

export const convertResourceTypeToFileName = (
  resource: (SurveyResource & ResourceFiles) | Resource
) => {
  const { type, pdf, podcast, video, link } = resource;
  switch (type) {
    case "PDF":
      return pdf?.file ?? " ";
    case "PODCAST":
      return podcast?.file ?? " ";
    case "VIDEO":
      return video?.url ?? " ";
    case "LINK":
      return link?.url ?? " ";
    default:
      return " ";
  }
};
