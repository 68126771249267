import { saveAs } from 'file-saver'
import { ErrorIcon } from '../../../assets/icons/icons'
import { closeModal } from '../../../features/cores/modalSlice'
import { Dialog, Switch } from '@headlessui/react'
import { AllResourceData } from '../../../types/serviceTypes'
import { useMatesDispatch } from '../../../app/hooks'
import { useEffect, useState } from 'react'
import { MatesButton, MatesLoading } from '../../atoms/atoms'
import { convertResourceTypeToFileName } from '../../../utils/converter'
import { useLazyGetAllResourceResultsQuery } from '../../../services/ResourceService'
import { normalizeCsvRow } from '../../../utils/normalizeCsvRow'

const DownloadResourceResultsModal = () => {
	const [isAgreed, setIsAgreed] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')
	const [resourceData, setResourceData] = useState<AllResourceData[]>([])
	const dispatch = useMatesDispatch()

	const [getAllResourceResults] = useLazyGetAllResourceResultsQuery()

	const downloadResourceResults = async () => {
		try {
			setIsLoading(true)
			setError('')
			const results = await getAllResourceResults({
				page: 1,
				take: 1,
			}).unwrap()
			const { count } = results
			const totalPages = Math.ceil(count / 100)
			const requests = []

			for (let page = 1; page <= totalPages; page++) {
				requests.push(
					getAllResourceResults({
						page,
						take: 100,
					}).unwrap()
				)
			}
			const responses = await Promise.all(requests)
			const allData: AllResourceData[] = []

			for (const response of responses) {
				allData.push(...response.data)
			}

			setResourceData(allData)
		} catch (error) {
			setError('Failed to download resource result. Please try again later.')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (!resourceData || !resourceData.length) return
		else {
			const csvContent =
				[
					'Resource Type',
					'Name of Resource',
					'Resource Content',
					'Resource Category',
					'User Group',
					'Hot Topic',
					'Primary Resource',
					'Survey Applied',
				].join(',') + '\n'

			const csvRows = resourceData.map((resource) => {
				const { type, title, category, isPinned, isSurveyEnabled, video } = resource

				const resourceContent = convertResourceTypeToFileName(resource)
				const categoryTitle = category ? category.title : ' '
				const userGroup = category?.userGroup?.title ?? ' '
				const isHotTopic = video?.isHotTopic ? 'Yes' : 'No'
				const isPrimary = isPinned ? 'Yes' : 'No'
				const hasSurvey = isSurveyEnabled ? 'Yes' : 'No'

				return normalizeCsvRow([type, title, resourceContent, categoryTitle, userGroup, isHotTopic, isPrimary, hasSurvey])
			})

			const csvData = csvContent + csvRows.join('\n')
			const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
			saveAs(blob, 'all_resource_data.csv')
		}
	}, [resourceData])

	return (
		<div className='relative w-[500px] py-3 px-6'>
			{isLoading && (
				<div
					className='absolute inset-0 bg-MatesDarkGrey/30 z-10
              flex items-center justify-center'
				>
					<MatesLoading shape='half-ring' />
				</div>
			)}
			<Dialog.Title className='flex items-center justify-between'>
				<span className='font-Title font-extrabold text-3xl'>DOWNLOAD CSV</span>
			</Dialog.Title>
			<hr className='mates-border my-2' />
			<div>
				<p className='text-MatesBlack/80 text-lg leading-5 py-2'>Download a CSV file of the resource data within our MATES database.</p>
				<div className='rounded-[4px] bg-MatesRed/10 px-4 py-2 my-2'>
					<p className='font-medium text-xl'>Download All</p>
					<p className='text-lg mb-2 leading-5 text-MatesBlack/80'>
						Be sure you want to download ALL resource records. This action can have a high amount of processing stress on your machine.
					</p>
					<Switch
						checked={isAgreed ? true : false}
						onChange={() => setIsAgreed((prevState) => !prevState)}
						className={`${isAgreed ? 'bg-MatesGreen' : 'bg-MatesGrey/90'}
                relative inline-flex h-[28px] w-12 items-center rounded-full 
                border-[1px] border-solid border-MatesDarkGrey outline-none`}
					>
						<span className='sr-only'>Use setting</span>
						<span
							aria-hidden='true'
							className={`${isAgreed ? 'translate-x-5' : '-translate-x-[1px]'}
                  inline-block h-[28px] w-[28px] transform rounded-full 
                  bg-MatesWhite border-[1px] border-solid 
                  border-MatesBorder transition-all duration-300`}
						/>
					</Switch>
				</div>
				<div className='w-full flex items-center mt-3'>
					<MatesButton
						text='Download CSV File'
						className={`${isAgreed ? 'main-detail-button' : 'disable-detail-button'} text-lg`}
						disabled={!isAgreed}
						onSubmit={downloadResourceResults}
					/>
					<MatesButton text='Go Back' className='delete-goback-button' onSubmit={() => dispatch(closeModal())} />
				</div>
				{error && (
					<div className='flex items-center text-MatesRed font-medium mt-2' role='alert'>
						<ErrorIcon className='mr-2' />
						{error}
					</div>
				)}
			</div>
		</div>
	)
}

export default DownloadResourceResultsModal
