import { twMerge } from "tailwind-merge";
import { ErrorPage } from "../pages";
import { openModal } from "../../features/cores/modalSlice";
import { MODAL_TYPES } from "../../assets/data/enums";
import { InstallIcon } from "../../assets/icons/icons";
import { DashboardLayout } from "../../components/templates/templates";
import { MatesSplashScreen } from "../../components/atoms/atoms";
import { categoryIdSelector } from "../../features/resources/categoriesSlice";
import { resourceIdSelector } from "../../features/resources/Resource/resourceSlice";
import { userGroupIdSelector } from "../../features/resources/userGroupsSlice";
import { useGetUserGroupsQuery } from "../../services/UserGroupService";
import { useMatesDispatch, useMatesSelector } from "../../app/hooks";
import {
  EmptyDetailBlock,
  EmptyResourceBlock,
} from "../../components/molecules/molecules";
import {
  CategoryList,
  EditResource,
  ResourcesList,
} from "../../components/organisms/organisms";

const Resources = () => {
  const userGroupId = useMatesSelector(userGroupIdSelector);
  const categoryId = useMatesSelector(categoryIdSelector);
  const resourceId = useMatesSelector(resourceIdSelector);
  const dispatch = useMatesDispatch();

  const { data, isLoading, isError } = useGetUserGroupsQuery();

  const renderResourceDetails = () => {
    if (!categoryId)
      return (
        <div className="w-[85%] h-[calc(100vh-11em)] grid grid-cols-2cols mx-auto">
          <CategoryList />
          <div className="h-[calc(100vh-11em)]">
            <EmptyDetailBlock
              detailText={"Select Category to see more details"}
            />
          </div>
        </div>
      );
    else
      return (
        <div className="w-[85%] h-[calc(100vh-11em)] grid grid-cols-3cols mx-auto">
          <CategoryList />
          <ResourcesList />
          {resourceId ? <EditResource /> : <EmptyResourceBlock />}
        </div>
      );
  };

  if (!data || !data.length) return <MatesSplashScreen />;
  if (isError) return <ErrorPage />;

  return (
    <DashboardLayout page="RESOURCES" userGroups={data} isLoading={isLoading}>
      <>
        <div className="w-[85%] mx-auto flex items-center justify-between">
          <p className={twMerge("title-page", "mx-0")}>RESOURCES</p>
          <div
            className="flex items-center gap-x-2 border-solid 
              border-[1px] border-MatesWhite/20 px-2 rounded-sm
              transition-all duration-300 hover:bg-MatesWhite/30
              cursor-pointer"
            onClick={() =>
              dispatch(
                openModal({
                  modalType: MODAL_TYPES.DOWNLOAD_RESOURCE_RESULTS,
                })
              )
            }
          >
            <p className="text-MatesWhite font-condensed text-lg pt-1">
              Download All Resources
            </p>
            <InstallIcon />
          </div>
        </div>
        {!userGroupId ? (
          <div className="w-[85%] h-[70%] mx-auto flex items-center justify-center text-MatesBorder text-[1.8em]">
            Select User Group to see more details
          </div>
        ) : (
          renderResourceDetails()
        )}
      </>
    </DashboardLayout>
  );
};

export default Resources;
