const podcastIcon = ({ height, width, className, onClick }: React.SVGProps<SVGSVGElement>) => {
    return (
        <div className={className}>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width={width ?? '18'} 
                height={height ?? '18'} 
                viewBox="0 0 18 18"
                onClick={onClick}
            >
                <path 
                    d="M12.325,22V14.665a1.808,1.808,0,0,1-.81-.653,1.8,1.8,0,0,1,2.756-2.284,1.789,1.789,0,0,1,.214,2.284,1.808,1.808,0,0,1-.81.653V22ZM6.79,19.525a9.312,9.312,0,0,1-2.036-2.914A9.037,9.037,0,0,1,4.709,9.49,8.986,8.986,0,0,1,9.49,4.709a9.046,9.046,0,0,1,7.02,0A8.986,8.986,0,0,1,21.291,9.49a9.037,9.037,0,0,1-.045,7.121,9.312,9.312,0,0,1-2.036,2.914l-.968-.968a7.743,7.743,0,0,0,1.766-2.475A7.391,7.391,0,0,0,20.65,13a7.38,7.38,0,0,0-2.228-5.423A7.38,7.38,0,0,0,13,5.35,7.38,7.38,0,0,0,7.578,7.578,7.38,7.38,0,0,0,5.35,13a7.391,7.391,0,0,0,.641,3.082,7.743,7.743,0,0,0,1.766,2.475Zm2.543-2.542a5.659,5.659,0,0,1-1.26-1.766A5.224,5.224,0,0,1,7.6,13,5.378,5.378,0,0,1,13,7.6,5.378,5.378,0,0,1,18.4,13a5.224,5.224,0,0,1-.472,2.216,5.659,5.659,0,0,1-1.26,1.766l-.967-.967a4.1,4.1,0,0,0,.99-1.328,4.095,4.095,0,0,0-.821-4.556A3.906,3.906,0,0,0,13,8.95,4.033,4.033,0,0,0,8.95,13a3.913,3.913,0,0,0,.36,1.687,4.1,4.1,0,0,0,.99,1.328Z" 
                    transform="translate(-4 -4)" 
                    fill="#fff" 
                />
            </svg>
        </div>
    )
}

export default podcastIcon