import { KeyboardEvent } from "react";
import {
  ErrorIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../../../assets/icons/icons";

type MatesLoginTextInputProps = {
  name: string;
  label: string;
  value?: string;
  type: string;
  isTouched?: boolean;
  error?: string | null;
  className?: string;
  onChange: (text: string) => void;
  isVisible?: boolean;
  onIconPress?: (visible: boolean) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export default function MatesLoginTextInput({
  name,
  label,
  type,
  value,
  isTouched,
  error,
  className,
  onChange,
  isVisible,
  onIconPress,
  onKeyDown,
}: MatesLoginTextInputProps) {
  const renderPasswordIcons = () => {
    if (isVisible) return <VisibilityOffIcon color="#999999" />;
    else return <VisibilityIcon color="#999999" />;
  };

  return (
    <>
      <div
        className={`relative w-full mt-6 mb-2 border-solid border-b-[2px]
        ${
          isTouched && error ? "border-MatesRed" : "border-MatesWhite"
        } ${className}`}
      >
        <input
          name={name}
          type={type}
          value={value}
          required={true}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          className={`w-full outline-none text-[1.3em] 
            peer h-[43.8px] translate-y-0 text-MatesRed bg-MatesBlack`}
        />
        {onIconPress && (
          <div
            className={`absolute right-2 ${
              isVisible ? "top-[0.4em]" : "top-[0.5em]"
            } w-6 h-6 cursor-pointer`}
            onClick={() => onIconPress(!isVisible)}
          >
            {renderPasswordIcons()}
          </div>
        )}
        <span
          className={`absolute text-[1.4em] left-0 font-medium
            transition-all duration-300 pointer-events-none ease-in-out
            peer-focus:translate-x-0 peer-focus:-translate-y-7 peer-focus:text-[0.9em]
            peer-valid:translate-x-0 peer-valid:-translate-y-7 peer-valid:text-[0.9em]
            peer-focus:py-3 peer-focus:tracking-wider 
            peer-valid:py-3 peer-valid:tracking-wider 
            text-MatesWhite`}
        >
          {label}
        </span>
      </div>
      {isTouched && error && (
        <div
          className="flex items-center text-MatesRed font-medium"
          role="alert"
        >
          <ErrorIcon className="mr-2" />
          {error}
        </div>
      )}
    </>
  );
}
