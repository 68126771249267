import { Modal, Overlay, Snackbar } from '../../types/propTypes'
import { CREATE_RESOURCE_PAGES, ERROR_MESSAGES, SNACKBAR_TYPES } from './enums'
import { AuthData, CreateCategoryArgs, CreateResourceArgs, LoginArgs } from '../../types/serviceTypes'
import { AdminSliceType } from '../../features/cores/adminSlice'
import { UserGroupSliceType } from '../../features/resources/userGroupsSlice'
import { CategoriesSliceType } from '../../features/resources/categoriesSlice'
import { ResourcesSliceType } from '../../features/resources/Resource/resourcesSlice'
import { ResourceSliceType } from '../../features/resources/Resource/resourceSlice'
import { UserSliceType } from '../../features/users/userSlice'
import { ResourceResultsSurveyType } from '../../features/surveys/surveySlice'
import { ChallengeSliceType } from '../../features/challenges/challengeSlice'
import { SelectedResourcesSliceType } from '../../features/resources/Resource/duplicateResourceSlice'

// --- Snackbar ---
export const InitialSnackbarState: Snackbar = {
	isDisplayed: false,
	snackbarType: SNACKBAR_TYPES.ERROR,
	snackbarMessage: ERROR_MESSAGES.LOGIN,
	snackbarTimeout: 10000,
	snackbarBtnText: 'CLOSE',
	snackbarBtnFunc: () => {},
}

// --- Modal ---
export const InitialModalState: Modal = {
	isDisplayed: false,
	modalType: null,
	title: '',
	body: '',
	itemId: '',
	deleteFn: () => {},
}

// --- Overlay ---
export const InitialOverlayState: Overlay = {
	isLoading: false,
	text: '',
}

// --- Auth ---
export const InitialLoginState: LoginArgs = {
	email: '',
	password: '',
}

export const InitialAuthState: AuthData = {
	accessToken: {
		token: null,
		expiresIn: null,
	},
	user: {},
}

// --- Admin ---
export const InitialAdminState: AdminSliceType = {
	admin: {
		name: '',
		email: '',
	},
}

// --- Category ---
export const InitialCreateCategoryState: CreateCategoryArgs = {
	isHidden: false,
	isAvailableToEveryone: false,
	isTrainingModule: false,
	title: '',
	description: '',
	thumbnail: '',
	userGroupId: '',
	sequence: 0,
}

// --- Resource ---
export const InitialUserGroupState: UserGroupSliceType = {
	userGroup: {
		id: '',
		title: 'ALL',
		discoverForYourselfDescription: '',
		discoverResourcesDescription: '',
		videoLink: '',
		thumbnail: '',
		isActive: false,
		sequence: 0,
		createdAt: '',
		updatedAt: '',
	},
	userGroups: [],
}

// --- User ---
export const InitialUserState: UserSliceType = {
	userGroup: 'ALL',
	user: {
		firstName: '',
		lastName: '',
		id: '',
		password: '',
		email: '',
		mobile: '',
		workIndustry: '',
		workState: '',
		updatedAt: '',
		createdAt: '',
		role: '',
		isConnectorAsistVolunteer: false,
		safePlan: {
			userId: '',
			createdAt: '',
			updatedAt: '',
			contacts: [],
			safeSpaces: [],
			favouritePlaces: [],
			favouriteActivities: [],
			otherActivities: [],
			reasonsToLive: [],
			thoughtsToRemember: [],
			warningSigns: [],
		},
		_count: {
			signUpSurveys: 0,
		},
		weeklyFeedback: {
			summary: {
				positiveTalksLocationCountList: [],
				keyConcernCountList: [],
				peopleSupportedSum: '',
				safePlanCreatedCountList: [],
				callbackRequestSum: '',
			},
			userCount: 0,
		},
	},
}

export const InitialCategoriesState: CategoriesSliceType = {
	category: {
		id: '',
		userGroupId: '',
		title: '',
		sequence: 0,
		thumbnail: '',
		description: '',
		isHidden: false,
		isAvailableToEveryone: false,
		isTrainingModule: false,
		createdAt: '',
		updatedAt: '',
	},
	categories: [],
}

export const InitialCreateResourceState: CreateResourceArgs = {
	currentPage: CREATE_RESOURCE_PAGES.SELECT_USER,
	userType: '',
	userTypeId: '',
	category: '',
	categoryId: '',
	openedTab: 'PODCASTS',
	title: '',
	slug: '',
	podcast: {
		description: '',
		file: '',
	},
	video: {
		isHotTopic: false,
		description: '',
		thumbnail: '',
		url: '',
	},
	pdf: {
		description: '',
		file: '',
	},
	link: {
		description: '',
		url: '',
	},
}

export const InitialResourceState: ResourceSliceType = {
	resource: {
		id: '',
		title: '',
		slug: '',
		isActive: false,
		isPinned: false,
		isSurveyEnabled: true,
		link: {
			url: '',
			description: '',
		},
		pdf: {
			description: '',
			file: '',
		},
		podcast: {
			description: '',
			file: '',
		},
		video: {
			isHotTopic: false,
			url: '',
			thumbnail: '',
		},
	},
	searchValue: '',
	isSurveyEnabled: true,
}

export const InitialResourcesState: ResourcesSliceType = {
	resources: [],
	selectedType: 'PODCASTS',
}

export const InitialSelectedResourcesState: SelectedResourcesSliceType = {
	selectedResources: [],
	selectedCategories: [],
}

export const InitialChallenge: ChallengeSliceType = {
	challenge: {
		id: '',
		title: 'NEW CHALLENGE',
		hashtag: '',
		winnerAnnouncementDate: '',
		submissionCloseDate: '',
		description: '',
		completedDate: '',
		link: '',
		featuredChallengeLink: '',
		winners: [
			{ id: '', link: '', ranking: 1 },
			{ id: '', link: '', ranking: 2 },
			{ id: '', link: '', ranking: 3 },
			{ id: '', link: '', ranking: 4 },
			{ id: '', link: '', ranking: 5 },
		],
	},
	isDetailOpen: false,
	winnersLinkErrors: [false, false, false, false, false],
	selectedTikTokURL: '',
	permissionType: 'readonly',
}

// --- Animation ---
export const cardAnimations = {
	initial: { x: -150, opacity: 0, scale: 0 },
	animate: { x: 0, opacity: 1, scale: 1 },
	exit: { x: 150, opacity: 0 },
	transition: { stiffness: 100 },
}

export const detailAnimation = {
	initial: { y: 10, opacity: 0 },
	animate: { y: 0, opacity: 1 },
	exit: { y: -10, opacity: 0 },
	transition: { duration: 0.2 },
}

export const tiktokGridListAnimation = {
	initial: { y: 50, opacity: 0 },
	animate: { y: 0, opacity: 1 },
	exit: { y: -50, opacity: 0 },
	transition: { stiffness: 80 },
}

export const tiktokDetailAnimation = {
	initial: { x: 500, y: 0, opacity: 0 },
	animate: { x: 0, y: 0, opacity: 1 },
	exit: { x: -500, y: 0, opacity: 0 },
	transition: { stiffness: 80 },
}

export const InitialSurveyState: ResourceResultsSurveyType = {
	searchTypes: {
		surveyType: 'RESOURCE',
		userGroup: null,
		resourceType: 'PODCASTS',
	},
	resourceResultsCount: 0,
	resourceResults: [],
	resourceCommentCount: {
		userCount: 0,
		guestCount: 0,
	},
	hubCommentCount: {
		userCount: '0',
		guestCount: '0',
	},
}
