interface RatingTagProps {
  rate: number;
}

function RatingTag({ rate }: RatingTagProps) {
  return (
    <div className="flex w-full flex-wrap justify-center m-2">
      <div
        className={`bg-MatesRed text-MatesWhite rounded-full px-3 py-1 text-base condensed`}
      >
        {rate}
      </div>
    </div>
  );
}
export default RatingTag;
