import { UpdateResourceArgs } from '../../../types/serviceTypes'
import { RFC } from '../../../types/propTypes'
import { useMatesDispatch } from '../../../app/hooks'
import {
	updateLinkContentLink,
	updateLinkURL,
	updateResourceIsActive,
	updateResourcePin,
	updateSlug,
	updateTitle,
} from '../../../features/resources/Resource/resourceSlice'
import { PinResourceField } from '../../molecules/molecules'
import { MatesButton, MatesLinkInput, MatesTextInput } from '../../atoms/atoms'
import { BinIcon } from '../../../assets/icons/icons'
import IsActiveField from '../../molecules/fields/IsActiveField'
import { formatSlug } from '../../../utils/formatter'

type LinkDetailProps = {
	resource: UpdateResourceArgs
	onSubmit: () => void
	onDelete: () => void
}

const LinkDetail: RFC<LinkDetailProps> = ({ resource, onSubmit, onDelete }) => {
	const { isPinned, title, link, isActive, slug } = resource
	const isValid = title && link?.description && link.url
	const dispatch = useMatesDispatch()

	return (
		<>
			<PinResourceField isPinned={isPinned} onChange={() => dispatch(updateResourcePin(!isPinned))} />
			<IsActiveField isActive={isActive} onChange={() => dispatch(updateResourceIsActive(!isActive))} />
			<MatesTextInput
				name='linkTitle'
				label='TITLE'
				theme='dark'
				value={title}
				className={'pt-3'}
				onChange={(text) => dispatch(updateTitle(text))}
			/>
			<MatesTextInput
				name='slug'
				label='URL SLUG'
				theme='dark'
				value={slug}
				className={'pt-3'}
				onChange={(text) => dispatch(updateSlug(formatSlug(text)))}
			/>
			<MatesTextInput
				name='link-to-content'
				label='LINK TO CONTENT CREATOR'
				theme='dark'
				value={link?.description}
				className={'pt-3'}
				onChange={(text) => dispatch(updateLinkContentLink(text))}
			/>
			<MatesLinkInput
				title='RESOURCE'
				theme='dark'
				source={link?.url}
				placeholder='Paste link here'
				onChange={(link) => dispatch(updateLinkURL(link))}
			/>
			<div className='flex items-center mt-7'>
				<MatesButton
					text='SAVE'
					className={`base-button
          ${isValid ? 'active-button' : 'disabled-dark-button'} px-10 tracking-wider text-xl`}
					disabled={!isValid}
					onSubmit={onSubmit}
				/>
				<div
					className='bg-MatesRed rounded-[3px]
          h-full p-4 ml-5 cursor-pointer'
					onClick={onDelete}
				>
					<BinIcon />
				</div>
			</div>
		</>
	)
}

export default LinkDetail
