import { saveAs } from 'file-saver'
import { ErrorIcon } from '../../../assets/icons/icons'
import { closeModal } from '../../../features/cores/modalSlice'
import { Dialog, Switch } from '@headlessui/react'
import { useMatesDispatch } from '../../../app/hooks'
import { useEffect, useState } from 'react'
import { TheHubExperience } from '../../../types/serviceTypes'
import { MatesButton, MatesLoading } from '../../atoms/atoms'
import { useLazyGetTheHubExperienceCommentsFeedbackQuery } from '../../../services/SurveyService'
import { normalizeCsvRow } from '../../../utils/normalizeCsvRow'

const DownloadHubFeedbackModal = () => {
	const [isAgreed, setIsAgreed] = useState(false)
	const [surveyData, setSurveyData] = useState<TheHubExperience[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')
	const dispatch = useMatesDispatch()

	const [getTheHubExperienceCommentsFeedback] = useLazyGetTheHubExperienceCommentsFeedbackQuery()

	const downloadSurveyResults = async () => {
		try {
			setIsLoading(true)
			setError('')
			const results = await getTheHubExperienceCommentsFeedback({
				page: 1,
				take: 1,
			}).unwrap()
			const { count } = results
			const totalPages = Math.ceil(count / 100)
			const requests = []

			for (let page = 1; page <= totalPages; page++) {
				requests.push(
					getTheHubExperienceCommentsFeedback({
						page,
						take: 100,
					}).unwrap()
				)
			}
			const responses = await Promise.all(requests)
			const allData: TheHubExperience[] = []

			for (const response of responses) {
				allData.push(...response.data)
			}

			setSurveyData(allData)
		} catch (error) {
			setError('Failed to download survey result. Please try again later.')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (!surveyData || !surveyData.length) return
		else {
			const csvContent = ['Name', 'Positive/Negative', 'Subject', 'Comment', 'Ip Address', 'Date'].join(',') + '\n'

			const csvRows = surveyData.map((survey) => {
				const { ipAddress, isPositiveExperience, comment, subject, createdAt, user } = survey
				const userName = user ? `${user.firstName} ${user.lastName}` : 'Guest'
				const positiveNegative = isPositiveExperience ? 'Positive' : 'Negative'
				const cleanComment = comment.replaceAll(',', ' ').replaceAll('\n', ' ')
				const subjectTitle = subject?.title ?? ''

				return normalizeCsvRow([userName, positiveNegative, subjectTitle, cleanComment, ipAddress, createdAt])
			})

			const csvData = csvContent + csvRows.join('\n')
			const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
			saveAs(blob, 'hub_feedback_results.csv')
		}
	}, [surveyData])

	return (
		<div className="relative w-[500px] py-3 px-6">
			{isLoading && (
				<div
					className="absolute inset-0 bg-MatesDarkGrey/30 z-10
          flex items-center justify-center"
				>
					<MatesLoading shape="half-ring" />
				</div>
			)}
			<Dialog.Title className="flex items-center justify-between">
				<span className="font-Title font-extrabold text-3xl">DOWNLOAD CSV</span>
			</Dialog.Title>
			<hr className="mates-border my-2" />
			<div>
				<p className="text-MatesBlack/80 text-lg leading-5 py-2">Download a CSV file of the Hub Feedback survey data within our MATES database.</p>
				<div className="rounded-[4px] bg-MatesRed/10 px-4 py-2 my-2">
					<p className="font-medium text-xl">Download All</p>
					<p className="text-lg mb-2 leading-5 text-MatesBlack/80">
						Be sure you want to download ALL survey records. This action can have a high amount of processing stress on your machine.
					</p>
					<Switch
						checked={isAgreed ? true : false}
						onChange={() => setIsAgreed((prevState) => !prevState)}
						className={`${isAgreed ? 'bg-MatesGreen' : 'bg-MatesGrey/90'}
            relative inline-flex h-[28px] w-12 items-center rounded-full 
            border-[1px] border-solid border-MatesDarkGrey outline-none`}
					>
						<span className="sr-only">Use setting</span>
						<span
							aria-hidden="true"
							className={`${isAgreed ? 'translate-x-5' : '-translate-x-[1px]'}
              inline-block h-[28px] w-[28px] transform rounded-full 
              bg-MatesWhite border-[1px] border-solid 
              border-MatesBorder transition-all duration-300`}
						/>
					</Switch>
				</div>
				<div className="w-full flex items-center mt-3">
					<MatesButton
						text="Download CSV File"
						className={`${isAgreed ? 'main-detail-button' : 'disable-detail-button'} text-lg`}
						disabled={!isAgreed}
						onSubmit={downloadSurveyResults}
					/>
					<MatesButton text="Go Back" className="delete-goback-button" onSubmit={() => dispatch(closeModal())} />
				</div>
				{error && (
					<div className="flex items-center text-MatesRed font-medium mt-2" role="alert">
						<ErrorIcon className="mr-2" />
						{error}
					</div>
				)}
			</div>
		</div>
	)
}

export default DownloadHubFeedbackModal
